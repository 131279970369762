html, body {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
}

#app {
  width: 100%;
  height: 100%;
}

.NW91UW_flex-container, .NW91UW_flex {
  display: flex;
}

.NW91UW_flex-gap {
  --gap: 0px;
  --column-gap: var(--gap);
  --row-gap: var(--gap);
  margin: calc(var(--row-gap) / -2) calc(var(--column-gap) / -2);
  width: calc(100% + calc(var(--column-gap)  + 1px));
  height: calc(100% + var(--row-gap));
}

.NW91UW_flex-container .NW91UW_flex-gap > * {
  margin: calc(var(--row-gap) / 2) calc(var(--column-gap) / 2);
}

.Dniwja_i18nFontFamily {
  font-synthesis: weight;
  font-family: adobe-clean, Source Sans Pro, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Ubuntu, Trebuchet MS, Lucida Grande, sans-serif;
}

.Dniwja_i18nFontFamily:lang(ar) {
  font-family: myriad-arabic, adobe-clean, Source Sans Pro, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Ubuntu, Trebuchet MS, Lucida Grande, sans-serif;
}

.Dniwja_i18nFontFamily:lang(he) {
  font-family: myriad-hebrew, adobe-clean, Source Sans Pro, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Ubuntu, Trebuchet MS, Lucida Grande, sans-serif;
}

.Dniwja_i18nFontFamily:lang(zh) {
  font-family: adobe-clean-han-traditional, source-han-traditional, MingLiu, Heiti TC Light, sans-serif;
}

.Dniwja_i18nFontFamily:lang(zh-Hans) {
  font-family: adobe-clean-han-simplified-c, source-han-simplified-c, SimSun, Heiti SC Light, sans-serif;
}

.Dniwja_i18nFontFamily:lang(zh-Hant) {
  font-family: adobe-clean-han-traditional, source-han-traditional, MingLiu, Microsoft JhengHei UI, Microsoft JhengHei, Heiti TC Light, sans-serif;
}

.Dniwja_i18nFontFamily:lang(zh-SG), .Dniwja_i18nFontFamily:lang(zh-CN) {
  font-family: adobe-clean-han-simplified-c, source-han-simplified-c, SimSun, Heiti SC Light, sans-serif;
}

.Dniwja_i18nFontFamily:lang(ko) {
  font-family: adobe-clean-han-korean, source-han-korean, Malgun Gothic, Apple Gothic, sans-serif;
}

.Dniwja_i18nFontFamily:lang(ja) {
  font-family: adobe-clean-han-japanese, Hiragino Kaku Gothic ProN, ヒラギノ角ゴ ProN W3, Osaka, YuGothic, Yu Gothic, メイリオ, Meiryo, ＭＳ Ｐゴシック, MS PGothic, sans-serif;
}

.Dniwja_spectrum-FocusRing-ring {
  --spectrum-focus-ring-border-radius: var(--spectrum-textfield-border-radius, var(--spectrum-alias-border-radius-regular));
  --spectrum-focus-ring-gap: var(--spectrum-alias-input-focusring-gap);
  --spectrum-focus-ring-size: var(--spectrum-alias-input-focusring-size);
  --spectrum-focus-ring-border-size: 0px;
  --spectrum-focus-ring-color: var(--spectrum-high-contrast-focus-ring-color, var(--spectrum-alias-focus-ring-color, var(--spectrum-alias-focus-color)));
}

.Dniwja_spectrum-FocusRing-ring:after {
  border-radius: calc(var(--spectrum-focus-ring-border-radius)  + var(--spectrum-focus-ring-gap));
  content: "";
  margin: calc(-1 * var(--spectrum-focus-ring-border-size));
  pointer-events: none;
  transition: box-shadow var(--spectrum-global-animation-duration-100, .13s) ease-out, margin var(--spectrum-global-animation-duration-100, .13s) ease-out;
  display: block;
  position: absolute;
  inset: 0;
}

.Dniwja_spectrum-FocusRing.Dniwja_focus-ring:after {
  margin: calc(var(--spectrum-focus-ring-gap) * -1 - var(--spectrum-focus-ring-border-size));
  box-shadow: 0 0 0 var(--spectrum-focus-ring-size) var(--spectrum-focus-ring-color);
}

.Dniwja_spectrum-FocusRing--quiet:after {
  border-radius: 0;
}

.Dniwja_spectrum-FocusRing--quiet.Dniwja_focus-ring:after {
  margin: 0 0 calc(var(--spectrum-focus-ring-gap) * -1 - var(--spectrum-focus-ring-border-size)) 0;
  box-shadow: 0 var(--spectrum-focus-ring-size) 0 var(--spectrum-focus-ring-color);
}

.Dniwja_spectrum-BaseButton {
  box-sizing: border-box;
  border-radius: var(--spectrum-button-border-radius);
  border-style: solid;
  border-width: var(--spectrum-button-border-width);
  --spectrum-focus-ring-border-radius: var(--spectrum-button-border-radius);
  --spectrum-focus-ring-border-size: var(--spectrum-button-border-width);
  --spectrum-focus-ring-gap: var(--spectrum-alias-focus-ring-gap, var(--spectrum-global-dimension-static-size-25));
  --spectrum-focus-ring-size: var(--spectrum-button-primary-focus-ring-size-key-focus, var(--spectrum-alias-focus-ring-size));
  text-transform: none;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  vertical-align: top;
  transition: background var(--spectrum-global-animation-duration-100, .13s) ease-out, border-color var(--spectrum-global-animation-duration-100, .13s) ease-out, color var(--spectrum-global-animation-duration-100, .13s) ease-out, box-shadow var(--spectrum-global-animation-duration-100, .13s) ease-out;
  -webkit-user-select: none;
  user-select: none;
  touch-action: none;
  cursor: default;
  isolation: isolate;
  justify-content: center;
  align-items: center;
  margin: 0;
  line-height: 1.3;
  text-decoration: none;
  display: inline-flex;
  position: relative;
  overflow: visible;
}

button.Dniwja_spectrum-BaseButton {
  -webkit-appearance: button;
}

.Dniwja_spectrum-BaseButton:focus {
  outline: none;
}

.Dniwja_spectrum-BaseButton.Dniwja_focus-ring {
  z-index: 3;
}

.Dniwja_spectrum-BaseButton::-moz-focus-inner {
  border: 0;
  margin-top: -2px;
  margin-bottom: -2px;
  padding: 0;
}

.Dniwja_spectrum-BaseButton:disabled, .Dniwja_spectrum-BaseButton.Dniwja_is-disabled {
  cursor: default;
}

.Dniwja_spectrum-BaseButton .Dniwja_spectrum-Icon {
  transition: background var(--spectrum-global-animation-duration-100, .13s) ease-out, fill var(--spectrum-global-animation-duration-100, .13s) ease-out;
  box-sizing: initial;
  flex-shrink: 0;
  order: 0;
  max-block-size: 100%;
}

.Dniwja_spectrum-Button {
  --spectrum-button-border-radius: var(--spectrum-button-primary-border-radius, var(--spectrum-alias-border-radius-large));
  --spectrum-button-border-width: var(--spectrum-button-primary-border-size, var(--spectrum-alias-border-size-thick));
  min-block-size: var(--spectrum-button-primary-height, var(--spectrum-alias-single-line-height));
  min-inline-size: var(--spectrum-button-primary-min-width);
  padding: var(--spectrum-global-dimension-size-50) calc(var(--spectrum-button-primary-padding-x, var(--spectrum-global-dimension-size-200))  - var(--spectrum-button-primary-border-size, var(--spectrum-alias-border-size-thick)));
  font-size: var(--spectrum-button-primary-text-size, var(--spectrum-alias-pill-button-text-size));
  font-weight: var(--spectrum-button-primary-text-font-weight, var(--spectrum-global-font-weight-bold));
  padding-top: calc(var(--spectrum-global-dimension-size-50)  - 1px);
  padding-bottom: calc(var(--spectrum-global-dimension-size-50)  + 1px);
  border-style: solid;
  block-size: 0%;
}

.Dniwja_spectrum-Button.Dniwja_is-hovered, .Dniwja_spectrum-Button:active {
  box-shadow: none;
}

.Dniwja_spectrum-Button .Dniwja_spectrum-Icon + .Dniwja_spectrum-Button-label:not(:-webkit-any(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi))) {
  margin-left: var(--spectrum-button-primary-text-gap, var(--spectrum-global-dimension-size-100));
}

.Dniwja_spectrum-Button .Dniwja_spectrum-Icon + .Dniwja_spectrum-Button-label:not(:is(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi))) {
  margin-left: var(--spectrum-button-primary-text-gap, var(--spectrum-global-dimension-size-100));
}

.Dniwja_spectrum-Button .Dniwja_spectrum-Icon + .Dniwja_spectrum-Button-label:is(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi)) {
  margin-right: var(--spectrum-button-primary-text-gap, var(--spectrum-global-dimension-size-100));
}

.Dniwja_spectrum-Button .Dniwja_spectrum-Button-label + .Dniwja_spectrum-Icon:not(:-webkit-any(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi))) {
  margin-right: var(--spectrum-button-primary-text-gap, var(--spectrum-global-dimension-size-100));
}

.Dniwja_spectrum-Button .Dniwja_spectrum-Button-label + .Dniwja_spectrum-Icon:not(:is(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi))) {
  margin-right: var(--spectrum-button-primary-text-gap, var(--spectrum-global-dimension-size-100));
}

.Dniwja_spectrum-Button .Dniwja_spectrum-Button-label + .Dniwja_spectrum-Icon:is(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi)) {
  margin-left: var(--spectrum-button-primary-text-gap, var(--spectrum-global-dimension-size-100));
}

.Dniwja_spectrum-Button.Dniwja_spectrum-Button--iconOnly {
  min-inline-size: unset;
  padding: var(--spectrum-global-dimension-size-65);
}

.Dniwja_spectrum-Button.Dniwja_spectrum-Button--pending .Dniwja_spectrum-Button-circleLoader {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.Dniwja_spectrum-Button.Dniwja_spectrum-Button--pending .Dniwja_spectrum-Button-label, .Dniwja_spectrum-Button.Dniwja_spectrum-Button--pending .Dniwja_spectrum-Icon {
  visibility: hidden;
}

a.Dniwja_spectrum-Button, a.Dniwja_spectrum-ActionButton {
  -webkit-appearance: none;
  -webkit-user-select: none;
  user-select: none;
  cursor: pointer;
}

.Dniwja_spectrum-ActionButton {
  block-size: var(--spectrum-actionbutton-height, var(--spectrum-alias-single-line-height));
  min-inline-size: var(--spectrum-actionbutton-min-width, var(--spectrum-global-dimension-size-400));
  --spectrum-button-border-radius: var(--spectrum-actionbutton-border-radius, var(--spectrum-alias-border-radius-regular));
  --spectrum-button-border-width: var(--spectrum-actionbutton-border-size, var(--spectrum-alias-border-size-thin));
  font-size: var(--spectrum-actionbutton-text-size, var(--spectrum-alias-font-size-default));
  font-weight: var(--spectrum-actionbutton-text-font-weight, var(--spectrum-alias-body-text-font-weight));
  padding: 0;
  position: relative;
}

.Dniwja_spectrum-ActionButton .Dniwja_spectrum-Icon:not(:-webkit-any(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi))) {
  padding-left: var(--spectrum-actionbutton-icon-padding-x, var(--spectrum-global-dimension-size-85));
}

.Dniwja_spectrum-ActionButton .Dniwja_spectrum-Icon:not(:is(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi))) {
  padding-left: var(--spectrum-actionbutton-icon-padding-x, var(--spectrum-global-dimension-size-85));
}

.Dniwja_spectrum-ActionButton .Dniwja_spectrum-Icon:is(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi)) {
  padding-right: var(--spectrum-actionbutton-icon-padding-x, var(--spectrum-global-dimension-size-85));
}

.Dniwja_spectrum-ActionButton .Dniwja_spectrum-ActionButton-label:not(:-webkit-any(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi))) {
  padding-right: var(--spectrum-actionbutton-text-padding-x, var(--spectrum-global-dimension-size-150));
}

.Dniwja_spectrum-ActionButton .Dniwja_spectrum-ActionButton-label:not(:is(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi))) {
  padding-right: var(--spectrum-actionbutton-text-padding-x, var(--spectrum-global-dimension-size-150));
}

.Dniwja_spectrum-ActionButton .Dniwja_spectrum-ActionButton-label:is(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi)) {
  padding-left: var(--spectrum-actionbutton-text-padding-x, var(--spectrum-global-dimension-size-150));
}

.Dniwja_spectrum-ActionButton .Dniwja_spectrum-Icon + .Dniwja_spectrum-ActionButton-label:not(:-webkit-any(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi))) {
  padding-left: var(--spectrum-actionbutton-icon-padding-x, var(--spectrum-global-dimension-size-85));
}

.Dniwja_spectrum-ActionButton .Dniwja_spectrum-Icon + .Dniwja_spectrum-ActionButton-label:not(:is(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi))) {
  padding-left: var(--spectrum-actionbutton-icon-padding-x, var(--spectrum-global-dimension-size-85));
}

.Dniwja_spectrum-ActionButton .Dniwja_spectrum-Icon + .Dniwja_spectrum-ActionButton-label:is(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi)) {
  padding-right: var(--spectrum-actionbutton-icon-padding-x, var(--spectrum-global-dimension-size-85));
}

.Dniwja_spectrum-ActionButton .Dniwja_spectrum-ActionButton-label:not([hidden]) + .Dniwja_spectrum-Icon:not(:-webkit-any(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi))) {
  padding-right: var(--spectrum-actionbutton-icon-padding-x, var(--spectrum-global-dimension-size-85));
}

.Dniwja_spectrum-ActionButton .Dniwja_spectrum-ActionButton-label:not([hidden]) + .Dniwja_spectrum-Icon:not(:is(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi))) {
  padding-right: var(--spectrum-actionbutton-icon-padding-x, var(--spectrum-global-dimension-size-85));
}

.Dniwja_spectrum-ActionButton .Dniwja_spectrum-ActionButton-label:not([hidden]) + .Dniwja_spectrum-Icon:-webkit-any(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi)) {
  padding-left: var(--spectrum-actionbutton-icon-padding-x, var(--spectrum-global-dimension-size-85));
}

.Dniwja_spectrum-ActionButton .Dniwja_spectrum-ActionButton-label:not([hidden]) + .Dniwja_spectrum-Icon:is(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi)) {
  padding-left: var(--spectrum-actionbutton-icon-padding-x, var(--spectrum-global-dimension-size-85));
}

.Dniwja_spectrum-ActionButton .Dniwja_spectrum-ActionButton-label:only-child:not(:-webkit-any(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi))) {
  padding-left: var(--spectrum-actionbutton-text-padding-x, var(--spectrum-global-dimension-size-150));
}

.Dniwja_spectrum-ActionButton .Dniwja_spectrum-ActionButton-label:only-child:not(:is(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi))) {
  padding-left: var(--spectrum-actionbutton-text-padding-x, var(--spectrum-global-dimension-size-150));
}

.Dniwja_spectrum-ActionButton .Dniwja_spectrum-ActionButton-label:only-child:is(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi)) {
  padding-right: var(--spectrum-actionbutton-text-padding-x, var(--spectrum-global-dimension-size-150));
}

.Dniwja_spectrum-ActionButton .Dniwja_spectrum-ActionButton-hold + .Dniwja_spectrum-ActionButton-label:last-child:not(:-webkit-any(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi))) {
  padding-left: var(--spectrum-actionbutton-text-padding-x, var(--spectrum-global-dimension-size-150));
}

.Dniwja_spectrum-ActionButton .Dniwja_spectrum-ActionButton-hold + .Dniwja_spectrum-ActionButton-label:last-child:not(:is(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi))) {
  padding-left: var(--spectrum-actionbutton-text-padding-x, var(--spectrum-global-dimension-size-150));
}

.Dniwja_spectrum-ActionButton .Dniwja_spectrum-ActionButton-hold + .Dniwja_spectrum-ActionButton-label:last-child:is(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi)) {
  padding-right: var(--spectrum-actionbutton-text-padding-x, var(--spectrum-global-dimension-size-150));
}

.Dniwja_spectrum-ActionButton .Dniwja_spectrum-Icon:only-child:not(:-webkit-any(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi))) {
  padding-right: var(--spectrum-actionbutton-icon-padding-x, var(--spectrum-global-dimension-size-85));
}

.Dniwja_spectrum-ActionButton .Dniwja_spectrum-Icon:only-child:not(:is(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi))) {
  padding-right: var(--spectrum-actionbutton-icon-padding-x, var(--spectrum-global-dimension-size-85));
}

.Dniwja_spectrum-ActionButton .Dniwja_spectrum-Icon:only-child:is(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi)) {
  padding-left: var(--spectrum-actionbutton-icon-padding-x, var(--spectrum-global-dimension-size-85));
}

.Dniwja_spectrum-ActionButton .Dniwja_spectrum-ActionButton-hold + .Dniwja_spectrum-Icon:last-child:not(:-webkit-any(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi))) {
  padding-right: var(--spectrum-actionbutton-icon-padding-x, var(--spectrum-global-dimension-size-85));
}

.Dniwja_spectrum-ActionButton .Dniwja_spectrum-ActionButton-hold + .Dniwja_spectrum-Icon:last-child:not(:is(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi))) {
  padding-right: var(--spectrum-actionbutton-icon-padding-x, var(--spectrum-global-dimension-size-85));
}

.Dniwja_spectrum-ActionButton .Dniwja_spectrum-ActionButton-hold + .Dniwja_spectrum-Icon:last-child:is(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi)) {
  padding-left: var(--spectrum-actionbutton-icon-padding-x, var(--spectrum-global-dimension-size-85));
}

.Dniwja_spectrum-ActionButton-hold {
  bottom: var(--spectrum-actionbutton-hold-icon-padding-bottom, var(--spectrum-global-dimension-size-40));
  position: absolute;
}

.Dniwja_spectrum-ActionButton-hold:not(:-webkit-any(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi))) {
  right: var(--spectrum-actionbutton-hold-icon-padding-right, var(--spectrum-global-dimension-size-40));
}

.Dniwja_spectrum-ActionButton-hold:not(:is(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi))) {
  right: var(--spectrum-actionbutton-hold-icon-padding-right, var(--spectrum-global-dimension-size-40));
}

.Dniwja_spectrum-ActionButton-hold:is(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi)) {
  left: var(--spectrum-actionbutton-hold-icon-padding-right, var(--spectrum-global-dimension-size-40));
}

[dir="rtl"] .Dniwja_spectrum-ActionButton-hold {
  transform: rotate(90deg);
}

.Dniwja_spectrum-ActionButton-label, .Dniwja_spectrum-Button-label {
  text-align: center;
  order: 1;
  place-self: center;
  inline-size: 100%;
}

:is(.Dniwja_spectrum-ActionButton-label, .Dniwja_spectrum-Button-label):empty {
  display: none;
}

.Dniwja_spectrum-ActionButton-label {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.Dniwja_spectrum-ActionButton--quiet {
  border-width: var(--spectrum-actionbutton-quiet-border-size, var(--spectrum-alias-border-size-thin));
  border-radius: var(--spectrum-actionbutton-quiet-border-radius, var(--spectrum-alias-border-radius-regular));
  font-size: var(--spectrum-actionbutton-quiet-text-size, var(--spectrum-alias-font-size-default));
  font-weight: var(--spectrum-actionbutton-quiet-text-font-weight, var(--spectrum-alias-body-text-font-weight));
}

.Dniwja_spectrum-LogicButton {
  block-size: var(--spectrum-logicbutton-and-height, 24px);
  padding: var(--spectrum-logicbutton-and-padding-x, var(--spectrum-global-dimension-size-100));
  --spectrum-button-border-width: var(--spectrum-logicbutton-and-border-size, var(--spectrum-alias-border-size-thick));
  --spectrum-button-border-radius: var(--spectrum-logicbutton-and-border-radius, var(--spectrum-alias-border-radius-regular));
  font-size: var(--spectrum-logicbutton-and-text-size, var(--spectrum-alias-font-size-default));
  font-weight: var(--spectrum-logicbutton-and-text-font-weight, var(--spectrum-global-font-weight-bold));
  line-height: 0;
}

.Dniwja_spectrum-FieldButton {
  block-size: var(--spectrum-dropdown-height, var(--spectrum-global-dimension-size-400));
  padding: 0 var(--spectrum-dropdown-padding-x, var(--spectrum-global-dimension-size-150));
  font-family: inherit;
  font-weight: 400;
  font-size: var(--spectrum-dropdown-text-size, var(--spectrum-alias-font-size-default));
  -webkit-font-smoothing: initial;
  cursor: default;
  --spectrum-focus-ring-gap: var(--spectrum-alias-input-focusring-gap);
  --spectrum-focus-ring-size: var(--spectrum-alias-input-focusring-size);
  padding-top: 0;
  padding-bottom: 0;
  padding-inline: var(--spectrum-dropdown-padding-x, var(--spectrum-global-dimension-size-150));
  --spectrum-button-border-width: var(--spectrum-dropdown-border-size, var(--spectrum-alias-border-size-thin));
  --spectrum-button-border-radius: var(--spectrum-alias-border-radius-regular, var(--spectrum-global-dimension-size-50));
  transition: background-color var(--spectrum-global-animation-duration-100, .13s), box-shadow var(--spectrum-global-animation-duration-100, .13s), border-color var(--spectrum-global-animation-duration-100, .13s);
  border-style: solid;
  outline: none;
  margin: 0;
  line-height: normal;
  position: relative;
}

.Dniwja_spectrum-FieldButton:disabled, .Dniwja_spectrum-FieldButton.Dniwja_is-disabled {
  cursor: default;
  border-width: 0;
}

.Dniwja_spectrum-FieldButton.Dniwja_is-open {
  border-width: var(--spectrum-dropdown-border-size, var(--spectrum-alias-border-size-thin));
}

.Dniwja_spectrum-FieldButton--quiet {
  --spectrum-button-border-width: 0;
  --spectrum-button-border-radius: var(--spectrum-fieldbutton-quiet-border-radius, 0px);
  --spectrum-focus-ring-size: var(--spectrum-alias-focus-ring-size, var(--spectrum-global-dimension-static-size-25));
  margin: 0;
  padding: 0;
}

.Dniwja_spectrum-FieldButton--quiet:disabled.Dniwja_focus-ring, .Dniwja_spectrum-FieldButton--quiet.Dniwja_is-disabled.Dniwja_focus-ring {
  box-shadow: none;
}

:is().Dniwja_focus-ring {
  box-shadow: none;
}

.Dniwja_spectrum-ClearButton {
  inline-size: var(--spectrum-clearbutton-medium-width, var(--spectrum-alias-single-line-height));
  block-size: var(--spectrum-clearbutton-medium-height, var(--spectrum-alias-single-line-height));
  --spectrum-button-border-radius: 100%;
  --spectrum-button-border-width: 0px;
  border: none;
  margin: 0;
  padding: 0;
}

.Dniwja_spectrum-ClearButton > .Dniwja_spectrum-Icon {
  margin: 0 auto;
}

@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .Dniwja_spectrum-ClearButton > .Dniwja_spectrum-Icon {
    margin: 0;
  }
}

.Dniwja_spectrum-ClearButton--small {
  inline-size: var(--spectrum-clearbutton-small-width, var(--spectrum-global-dimension-size-300));
  block-size: var(--spectrum-clearbutton-small-height, var(--spectrum-global-dimension-size-300));
}

.Dniwja_spectrum-ClearButton {
  background-color: var(--spectrum-clearbutton-medium-background-color, var(--spectrum-alias-background-color-transparent));
  color: var(--spectrum-clearbutton-medium-icon-color, var(--spectrum-alias-icon-color));
}

.Dniwja_spectrum-ClearButton .Dniwja_spectrum-Icon {
  fill: var(--spectrum-clearbutton-medium-icon-color, var(--spectrum-alias-icon-color));
}

.Dniwja_spectrum-ClearButton.Dniwja_is-hovered {
  background-color: var(--spectrum-clearbutton-medium-background-color-hover, var(--spectrum-alias-background-color-transparent));
  color: var(--spectrum-clearbutton-medium-icon-color-hover, var(--spectrum-alias-icon-color-hover));
}

.Dniwja_spectrum-ClearButton.Dniwja_is-hovered .Dniwja_spectrum-Icon {
  fill: var(--spectrum-clearbutton-medium-icon-color-hover, var(--spectrum-alias-icon-color-hover));
}

.Dniwja_spectrum-ClearButton.Dniwja_is-active {
  background-color: var(--spectrum-clearbutton-medium-background-color-down, var(--spectrum-alias-background-color-transparent));
  color: var(--spectrum-clearbutton-medium-icon-color-down, var(--spectrum-alias-icon-color-down));
}

.Dniwja_spectrum-ClearButton.Dniwja_is-active .Dniwja_spectrum-Icon {
  fill: var(--spectrum-clearbutton-medium-icon-color-down, var(--spectrum-alias-icon-color-down));
}

.Dniwja_spectrum-ClearButton.Dniwja_focus-ring {
  background-color: var(--spectrum-clearbutton-medium-background-color-key-focus, var(--spectrum-alias-background-color-transparent));
  color: var(--spectrum-clearbutton-medium-icon-color-key-focus, var(--spectrum-alias-icon-color-focus));
}

.Dniwja_spectrum-ClearButton.Dniwja_focus-ring .Dniwja_spectrum-Icon {
  fill: var(--spectrum-clearbutton-medium-icon-color-key-focus, var(--spectrum-alias-icon-color-focus));
}

.Dniwja_spectrum-ClearButton:disabled {
  background-color: var(--spectrum-clearbutton-medium-background-color-disabled, var(--spectrum-alias-background-color-transparent));
  color: var(--spectrum-clearbutton-medium-icon-color-disabled, var(--spectrum-alias-icon-color-disabled));
}

.Dniwja_spectrum-ClearButton:disabled .Dniwja_spectrum-Icon {
  fill: var(--spectrum-clearbutton-medium-icon-color-disabled, var(--spectrum-alias-icon-color-disabled));
}

.Dniwja_spectrum-ClearButton.Dniwja_is-disabled {
  background-color: var(--spectrum-clearbutton-medium-background-color-disabled, var(--spectrum-alias-background-color-transparent));
  color: var(--spectrum-clearbutton-medium-icon-color-disabled, var(--spectrum-alias-icon-color-disabled));
}

.Dniwja_spectrum-ClearButton.Dniwja_is-disabled .Dniwja_spectrum-Icon {
  fill: var(--spectrum-clearbutton-medium-icon-color-disabled, var(--spectrum-alias-icon-color-disabled));
}

.Dniwja_spectrum-ClearButton--overBackground {
  --spectrum-clearbutton-medium-background-color: transparent;
  --spectrum-clearbutton-medium-background-color-hover: #ffffff1a;
  --spectrum-clearbutton-medium-background-color-key-focus: #ffffff1a;
  --spectrum-clearbutton-medium-background-color-down: #ffffff26;
  --spectrum-clearbutton-medium-background-color-disabled: transparent;
  --spectrum-clearbutton-medium-icon-color: white;
  --spectrum-clearbutton-medium-icon-color-hover: white;
  --spectrum-clearbutton-medium-icon-color-down: white;
  --spectrum-clearbutton-medium-icon-color-key-focus: white;
  --spectrum-clearbutton-medium-icon-color-disabled: #ffffff8c;
  --spectrum-focus-ring-color: white;
}

.Dniwja_spectrum-Button[data-style="fill"] {
  --spectrum-button-text-color: white;
  --spectrum-button-text-color-hover: var(--spectrum-button-text-color);
  --spectrum-button-text-color-down: var(--spectrum-button-text-color);
  --spectrum-button-text-color-key-focus: var(--spectrum-button-text-color);
  --spectrum-button-text-color-disabled: var(--spectrum-alias-text-color-disabled, var(--spectrum-global-color-gray-500));
  --spectrum-button-color-disabled: var(--spectrum-alias-background-color-disabled);
  background-color: var(--spectrum-high-contrast-button-text, var(--spectrum-button-color));
  color: var(--spectrum-high-contrast-button-face, var(--spectrum-button-text-color));
  border-color: #0000;
}

.Dniwja_spectrum-Button[data-style="fill"].Dniwja_is-hovered {
  background-color: var(--spectrum-high-contrast-highlight, var(--spectrum-button-color-hover));
  color: var(--spectrum-high-contrast-button-face, var(--spectrum-button-text-color-hover));
}

.Dniwja_spectrum-Button[data-style="fill"].Dniwja_focus-ring {
  background-color: var(--spectrum-high-contrast-highlight, var(--spectrum-button-color-key-focus));
  color: var(--spectrum-high-contrast-button-face, var(--spectrum-button-text-color-key-focus));
}

.Dniwja_spectrum-Button[data-style="fill"].Dniwja_is-active {
  background-color: var(--spectrum-high-contrast-highlight, var(--spectrum-button-color-down));
  color: var(--spectrum-high-contrast-button-face, var(--spectrum-button-text-color-down));
}

.Dniwja_spectrum-Button[data-style="fill"]:disabled, .Dniwja_spectrum-Button[data-style="fill"].Dniwja_is-disabled {
  background-color: var(--spectrum-high-contrast-button-face, var(--spectrum-button-color-disabled));
  color: var(--spectrum-high-contrast-gray-text, var(--spectrum-button-text-color-disabled));
}

.Dniwja_spectrum-Button[data-style="outline"] {
  --spectrum-button-text-color: var(--spectrum-button-color);
  --spectrum-button-text-color-hover: var(--spectrum-button-color-hover);
  --spectrum-button-text-color-down: var(--spectrum-button-color-down);
  --spectrum-button-text-color-key-focus: var(--spectrum-button-color-key-focus);
  --spectrum-button-text-color-disabled: var(--spectrum-alias-text-color-disabled, var(--spectrum-global-color-gray-500));
  --spectrum-button-color-disabled: var(--spectrum-alias-background-color-disabled);
  border-color: var(--spectrum-high-contrast-button-text, var(--spectrum-button-color));
  color: var(--spectrum-high-contrast-button-text, var(--spectrum-button-text-color));
  background-color: #0000;
}

.Dniwja_spectrum-Button[data-style="outline"].Dniwja_is-hovered {
  background-color: var(--spectrum-high-contrast-transparent, var(--spectrum-button-background-color-hover));
  border-color: var(--spectrum-high-contrast-highlight, var(--spectrum-button-color-hover));
  color: var(--spectrum-high-contrast-button-text, var(--spectrum-button-text-color-hover));
}

.Dniwja_spectrum-Button[data-style="outline"].Dniwja_focus-ring {
  background-color: var(--spectrum-high-contrast-transparent, var(--spectrum-button-background-color-key-focus));
  border-color: var(--spectrum-high-contrast-highlight, var(--spectrum-button-color-key-focus));
  color: var(--spectrum-high-contrast-button-text, var(--spectrum-button-text-color-key-focus));
}

.Dniwja_spectrum-Button[data-style="outline"].Dniwja_is-active {
  background-color: var(--spectrum-high-contrast-transparent, var(--spectrum-button-background-color-down));
  border-color: var(--spectrum-high-contrast-highlight, var(--spectrum-button-color-down));
  color: var(--spectrum-high-contrast-button-text, var(--spectrum-button-text-color-down));
}

.Dniwja_spectrum-Button[data-style="outline"]:disabled, .Dniwja_spectrum-Button[data-style="outline"].Dniwja_is-disabled {
  border-color: var(--spectrum-high-contrast-gray-text, var(--spectrum-button-color-disabled));
  color: var(--spectrum-high-contrast-gray-text, var(--spectrum-button-text-color-disabled));
  background-color: #0000;
}

.Dniwja_spectrum-Button[data-static-color="white"] {
  --spectrum-focus-ring-color: white;
}

.Dniwja_spectrum-Button[data-static-color="white"][data-variant="accent"][data-style="fill"] {
  --spectrum-button-color: #ffffffe6;
  --spectrum-button-color-hover: white;
  --spectrum-button-color-down: white;
  --spectrum-button-color-key-focus: white;
  --spectrum-button-color-disabled: #ffffff1a;
  --spectrum-button-text-color: black;
  --spectrum-button-text-color-disabled: #ffffff8c;
}

.Dniwja_spectrum-Button[data-static-color="white"][data-variant="accent"][data-style="outline"] {
  --spectrum-button-color: #ffffffe6;
  --spectrum-button-color-hover: white;
  --spectrum-button-color-down: white;
  --spectrum-button-color-key-focus: white;
  --spectrum-button-color-disabled: #ffffff40;
  --spectrum-button-text-color: white;
  --spectrum-button-text-color-hover: white;
  --spectrum-button-text-color-down: white;
  --spectrum-button-text-color-key-focus: white;
  --spectrum-button-text-color-disabled: #ffffff8c;
  --spectrum-button-background-color-hover: #ffffff1a;
  --spectrum-button-background-color-down: #ffffff26;
  --spectrum-button-background-color-key-focus: #ffffff1a;
}

.Dniwja_spectrum-Button[data-static-color="white"][data-variant="negative"][data-style="fill"] {
  --spectrum-button-color: #ffffffe6;
  --spectrum-button-color-hover: white;
  --spectrum-button-color-down: white;
  --spectrum-button-color-key-focus: white;
  --spectrum-button-color-disabled: #ffffff1a;
  --spectrum-button-text-color: black;
  --spectrum-button-text-color-disabled: #ffffff8c;
}

.Dniwja_spectrum-Button[data-static-color="white"][data-variant="negative"][data-style="outline"] {
  --spectrum-button-color: #ffffffe6;
  --spectrum-button-color-hover: white;
  --spectrum-button-color-down: white;
  --spectrum-button-color-key-focus: white;
  --spectrum-button-color-disabled: #ffffff40;
  --spectrum-button-text-color: white;
  --spectrum-button-text-color-hover: white;
  --spectrum-button-text-color-down: white;
  --spectrum-button-text-color-key-focus: white;
  --spectrum-button-text-color-disabled: #ffffff8c;
  --spectrum-button-background-color-hover: #ffffff1a;
  --spectrum-button-background-color-down: #ffffff26;
  --spectrum-button-background-color-key-focus: #ffffff1a;
}

.Dniwja_spectrum-Button[data-static-color="white"][data-variant="primary"][data-style="fill"] {
  --spectrum-button-color: #ffffffe6;
  --spectrum-button-color-hover: white;
  --spectrum-button-color-down: white;
  --spectrum-button-color-key-focus: white;
  --spectrum-button-color-disabled: #ffffff1a;
  --spectrum-button-text-color: black;
  --spectrum-button-text-color-disabled: #ffffff8c;
}

.Dniwja_spectrum-Button[data-static-color="white"][data-variant="primary"][data-style="outline"] {
  --spectrum-button-color: #ffffffe6;
  --spectrum-button-color-hover: white;
  --spectrum-button-color-down: white;
  --spectrum-button-color-key-focus: white;
  --spectrum-button-color-disabled: #ffffff40;
  --spectrum-button-text-color: white;
  --spectrum-button-text-color-hover: white;
  --spectrum-button-text-color-down: white;
  --spectrum-button-text-color-key-focus: white;
  --spectrum-button-text-color-disabled: #ffffff8c;
  --spectrum-button-background-color-hover: #ffffff1a;
  --spectrum-button-background-color-down: #ffffff26;
  --spectrum-button-background-color-key-focus: #ffffff1a;
}

.Dniwja_spectrum-Button[data-static-color="white"][data-variant="secondary"][data-style="fill"] {
  --spectrum-button-color: #ffffff12;
  --spectrum-button-color-hover: #ffffff1a;
  --spectrum-button-color-down: #ffffff26;
  --spectrum-button-color-key-focus: #ffffff1a;
  --spectrum-button-color-disabled: #ffffff1a;
  --spectrum-button-text-color: white;
  --spectrum-button-text-color-disabled: #ffffff8c;
}

.Dniwja_spectrum-Button[data-static-color="white"][data-variant="secondary"][data-style="outline"] {
  --spectrum-button-color: #ffffff40;
  --spectrum-button-color-hover: #fff6;
  --spectrum-button-color-down: #ffffff8c;
  --spectrum-button-color-key-focus: #fff6;
  --spectrum-button-color-disabled: #ffffff40;
  --spectrum-button-text-color: white;
  --spectrum-button-text-color-hover: white;
  --spectrum-button-text-color-down: white;
  --spectrum-button-text-color-key-focus: white;
  --spectrum-button-text-color-disabled: #ffffff8c;
  --spectrum-button-background-color-hover: #ffffff1a;
  --spectrum-button-background-color-down: #ffffff26;
  --spectrum-button-background-color-key-focus: #ffffff1a;
}

.Dniwja_spectrum-Button[data-static-color="black"] {
  --spectrum-focus-ring-color: black;
}

.Dniwja_spectrum-Button[data-static-color="black"][data-variant="accent"][data-style="fill"] {
  --spectrum-button-color: #000000e6;
  --spectrum-button-color-hover: black;
  --spectrum-button-color-down: black;
  --spectrum-button-color-key-focus: black;
  --spectrum-button-color-disabled: #0000001a;
  --spectrum-button-text-color: white;
  --spectrum-button-text-color-disabled: #0000008c;
}

.Dniwja_spectrum-Button[data-static-color="black"][data-variant="accent"][data-style="outline"] {
  --spectrum-button-color: #000000e6;
  --spectrum-button-color-hover: black;
  --spectrum-button-color-down: black;
  --spectrum-button-color-key-focus: black;
  --spectrum-button-color-disabled: #00000040;
  --spectrum-button-text-color: black;
  --spectrum-button-text-color-hover: black;
  --spectrum-button-text-color-down: black;
  --spectrum-button-text-color-key-focus: black;
  --spectrum-button-text-color-disabled: #0000008c;
  --spectrum-button-background-color-hover: #0000001a;
  --spectrum-button-background-color-down: #00000026;
  --spectrum-button-background-color-key-focus: #0000001a;
}

.Dniwja_spectrum-Button[data-static-color="black"][data-variant="negative"][data-style="fill"] {
  --spectrum-button-color: #000000e6;
  --spectrum-button-color-hover: black;
  --spectrum-button-color-down: black;
  --spectrum-button-color-key-focus: black;
  --spectrum-button-color-disabled: #0000001a;
  --spectrum-button-text-color: white;
  --spectrum-button-text-color-disabled: #0000008c;
}

.Dniwja_spectrum-Button[data-static-color="black"][data-variant="negative"][data-style="outline"] {
  --spectrum-button-color: #000000e6;
  --spectrum-button-color-hover: black;
  --spectrum-button-color-down: black;
  --spectrum-button-color-key-focus: black;
  --spectrum-button-color-disabled: #00000040;
  --spectrum-button-text-color: black;
  --spectrum-button-text-color-hover: black;
  --spectrum-button-text-color-down: black;
  --spectrum-button-text-color-key-focus: black;
  --spectrum-button-text-color-disabled: #0000008c;
  --spectrum-button-background-color-hover: #0000001a;
  --spectrum-button-background-color-down: #00000026;
  --spectrum-button-background-color-key-focus: #0000001a;
}

.Dniwja_spectrum-Button[data-static-color="black"][data-variant="primary"][data-style="fill"] {
  --spectrum-button-color: #000000e6;
  --spectrum-button-color-hover: black;
  --spectrum-button-color-down: black;
  --spectrum-button-color-key-focus: black;
  --spectrum-button-color-disabled: #0000001a;
  --spectrum-button-text-color: white;
  --spectrum-button-text-color-disabled: #0000008c;
}

.Dniwja_spectrum-Button[data-static-color="black"][data-variant="primary"][data-style="outline"] {
  --spectrum-button-color: #000000e6;
  --spectrum-button-color-hover: black;
  --spectrum-button-color-down: black;
  --spectrum-button-color-key-focus: black;
  --spectrum-button-color-disabled: #00000040;
  --spectrum-button-text-color: black;
  --spectrum-button-text-color-hover: black;
  --spectrum-button-text-color-down: black;
  --spectrum-button-text-color-key-focus: black;
  --spectrum-button-text-color-disabled: #0000008c;
  --spectrum-button-background-color-hover: #0000001a;
  --spectrum-button-background-color-down: #00000026;
  --spectrum-button-background-color-key-focus: #0000001a;
}

:is()[data-style="fill"] {
  --spectrum-button-color: #000000e6;
  --spectrum-button-color-hover: black;
  --spectrum-button-color-down: black;
  --spectrum-button-color-key-focus: black;
  --spectrum-button-color-disabled: #0000001a;
  --spectrum-button-text-color: white;
  --spectrum-button-text-color-disabled: #0000008c;
}

:is()[data-style="outline"] {
  --spectrum-button-color: #000000e6;
  --spectrum-button-color-hover: black;
  --spectrum-button-color-down: black;
  --spectrum-button-color-key-focus: black;
  --spectrum-button-color-disabled: #00000040;
  --spectrum-button-text-color: black;
  --spectrum-button-text-color-hover: black;
  --spectrum-button-text-color-down: black;
  --spectrum-button-text-color-key-focus: black;
  --spectrum-button-text-color-disabled: #0000008c;
  --spectrum-button-background-color-hover: #0000001a;
  --spectrum-button-background-color-down: #00000026;
  --spectrum-button-background-color-key-focus: #0000001a;
}

.Dniwja_spectrum-Button[data-static-color="black"][data-variant="secondary"][data-style="fill"] {
  --spectrum-button-color: #00000012;
  --spectrum-button-color-hover: #0000001a;
  --spectrum-button-color-down: #00000026;
  --spectrum-button-color-key-focus: #0000001a;
  --spectrum-button-color-disabled: #0000001a;
  --spectrum-button-text-color: black;
  --spectrum-button-text-color-disabled: #0000008c;
}

.Dniwja_spectrum-Button[data-static-color="black"][data-variant="secondary"][data-style="outline"] {
  --spectrum-button-color: #00000040;
  --spectrum-button-color-hover: #0006;
  --spectrum-button-color-down: #0000008c;
  --spectrum-button-color-key-focus: #0006;
  --spectrum-button-color-disabled: #00000040;
  --spectrum-button-text-color: black;
  --spectrum-button-text-color-hover: black;
  --spectrum-button-text-color-down: black;
  --spectrum-button-text-color-key-focus: black;
  --spectrum-button-text-color-disabled: #0000008c;
  --spectrum-button-background-color-hover: #0000001a;
  --spectrum-button-background-color-down: #00000026;
  --spectrum-button-background-color-key-focus: #0000001a;
}

.Dniwja_spectrum-Button:not([data-static-color])[data-variant="accent"][data-style="fill"] {
  --spectrum-button-color: var(--spectrum-accent-background-color-default);
  --spectrum-button-color-hover: var(--spectrum-accent-background-color-hover);
  --spectrum-button-color-down: var(--spectrum-accent-background-color-down);
  --spectrum-button-color-key-focus: var(--spectrum-accent-background-color-key-focus);
}

.Dniwja_spectrum-Button:not([data-static-color])[data-variant="accent"][data-style="outline"] {
  --spectrum-button-color: var(--spectrum-accent-color-900);
  --spectrum-button-color-hover: var(--spectrum-accent-color-1000);
  --spectrum-button-color-down: var(--spectrum-accent-color-1100);
  --spectrum-button-color-key-focus: var(--spectrum-accent-color-1000);
  --spectrum-button-background-color-hover: var(--spectrum-accent-color-200);
  --spectrum-button-background-color-down: var(--spectrum-accent-color-300);
  --spectrum-button-background-color-key-focus: var(--spectrum-accent-color-200);
}

.Dniwja_spectrum-Button:not([data-static-color])[data-variant="negative"][data-style="fill"] {
  --spectrum-button-color: var(--spectrum-negative-background-color-default);
  --spectrum-button-color-hover: var(--spectrum-negative-background-color-hover);
  --spectrum-button-color-down: var(--spectrum-negative-background-color-down);
  --spectrum-button-color-key-focus: var(--spectrum-negative-background-color-key-focus);
}

.Dniwja_spectrum-Button:not([data-static-color])[data-variant="negative"][data-style="outline"] {
  --spectrum-button-color: var(--spectrum-red-900);
  --spectrum-button-color-hover: var(--spectrum-red-1000);
  --spectrum-button-color-down: var(--spectrum-red-1100);
  --spectrum-button-color-key-focus: var(--spectrum-red-1000);
  --spectrum-button-background-color-hover: var(--spectrum-red-200);
  --spectrum-button-background-color-down: var(--spectrum-red-300);
  --spectrum-button-background-color-key-focus: var(--spectrum-red-200);
}

.Dniwja_spectrum-Button:not([data-static-color])[data-variant="primary"][data-style="fill"] {
  --spectrum-button-color: var(--spectrum-neutral-background-color-default);
  --spectrum-button-color-hover: var(--spectrum-neutral-background-color-hover);
  --spectrum-button-color-down: var(--spectrum-neutral-background-color-down);
  --spectrum-button-color-key-focus: var(--spectrum-neutral-background-color-key-focus);
}

.Dniwja_spectrum-Button:not([data-static-color])[data-variant="primary"][data-style="outline"] {
  --spectrum-button-color: var(--spectrum-gray-800);
  --spectrum-button-color-hover: var(--spectrum-gray-900);
  --spectrum-button-color-down: var(--spectrum-gray-900);
  --spectrum-button-color-key-focus: var(--spectrum-gray-900);
  --spectrum-button-background-color-hover: var(--spectrum-gray-300);
  --spectrum-button-background-color-down: var(--spectrum-gray-400);
  --spectrum-button-background-color-key-focus: var(--spectrum-gray-300);
}

.Dniwja_spectrum-Button:not([data-static-color])[data-variant="secondary"] {
  --spectrum-button-text-color: var(--spectrum-gray-800);
  --spectrum-button-text-color-hover: var(--spectrum-gray-900);
  --spectrum-button-text-color-down: var(--spectrum-gray-900);
  --spectrum-button-text-color-key-focus: var(--spectrum-gray-900);
}

.Dniwja_spectrum-Button:not([data-static-color])[data-variant="secondary"][data-style="fill"] {
  --spectrum-button-color: var(--spectrum-gray-200);
  --spectrum-button-color-hover: var(--spectrum-gray-300);
  --spectrum-button-color-down: var(--spectrum-gray-400);
  --spectrum-button-color-key-focus: var(--spectrum-gray-300);
}

.Dniwja_spectrum-Button:not([data-static-color])[data-variant="secondary"][data-style="outline"] {
  --spectrum-button-color: var(--spectrum-gray-300);
  --spectrum-button-color-hover: var(--spectrum-gray-400);
  --spectrum-button-color-down: var(--spectrum-gray-500);
  --spectrum-button-color-key-focus: var(--spectrum-gray-400);
  --spectrum-button-background-color-hover: var(--spectrum-gray-300);
  --spectrum-button-background-color-down: var(--spectrum-gray-400);
  --spectrum-button-background-color-key-focus: var(--spectrum-gray-300);
}

@media (forced-colors: active) {
  .Dniwja_spectrum-Button, .Dniwja_spectrum-ActionButton, .Dniwja_spectrum-FieldButton {
    forced-color-adjust: none;
    --spectrum-high-contrast-transparent: transparent;
    --spectrum-high-contrast-button-face: ButtonFace;
    --spectrum-high-contrast-button-text: ButtonText;
    --spectrum-high-contrast-highlight: Highlight;
    --spectrum-high-contrast-highlight-text: HighlightText;
    --spectrum-high-contrast-gray-text: GrayText;
  }

  .Dniwja_spectrum-Button, .Dniwja_spectrum-ActionButton {
    --spectrum-high-contrast-focus-ring-color: ButtonText;
  }

  .Dniwja_spectrum-FieldButton {
    --spectrum-high-contrast-focus-ring-color: Highlight;
  }

  .Dniwja_spectrum-Button--pending.Dniwja_is-disabled {
    border-color: var(--spectrum-high-contrast-gray-text);
  }
}

.Dniwja_spectrum-ActionButton {
  background-color: var(--spectrum-high-contrast-button-face, var(--spectrum-actionbutton-background-color, var(--spectrum-global-color-gray-75)));
  border-color: var(--spectrum-high-contrast-button-text, var(--spectrum-actionbutton-border-color, var(--spectrum-alias-border-color)));
  color: var(--spectrum-high-contrast-button-text, var(--spectrum-actionbutton-text-color, var(--spectrum-alias-text-color)));
}

.Dniwja_spectrum-ActionButton .Dniwja_spectrum-Icon {
  fill: var(--spectrum-high-contrast-button-text, var(--spectrum-actionbutton-icon-color, var(--spectrum-alias-icon-color)));
}

.Dniwja_spectrum-ActionButton .Dniwja_spectrum-ActionButton-hold {
  fill: var(--spectrum-high-contrast-button-text, var(--spectrum-actionbutton-hold-icon-color, var(--spectrum-alias-icon-color)));
}

.Dniwja_spectrum-ActionButton.Dniwja_is-hovered {
  background-color: var(--spectrum-high-contrast-button-face, var(--spectrum-actionbutton-background-color-hover, var(--spectrum-global-color-gray-50)));
  border-color: var(--spectrum-high-contrast-highlight, var(--spectrum-actionbutton-border-color-hover, var(--spectrum-alias-border-color-hover)));
  color: var(--spectrum-high-contrast-button-text, var(--spectrum-actionbutton-text-color-hover, var(--spectrum-alias-text-color-hover)));
}

.Dniwja_spectrum-ActionButton.Dniwja_is-hovered .Dniwja_spectrum-Icon {
  fill: var(--spectrum-high-contrast-button-text, var(--spectrum-actionbutton-icon-color-hover, var(--spectrum-alias-icon-color-hover)));
}

.Dniwja_spectrum-ActionButton.Dniwja_is-hovered .Dniwja_spectrum-ActionButton-hold {
  fill: var(--spectrum-actionbutton-hold-icon-color-hover, var(--spectrum-alias-icon-color-hover));
}

.Dniwja_spectrum-ActionButton.Dniwja_focus-ring {
  background-color: var(--spectrum-high-contrast-button-face, var(--spectrum-actionbutton-background-color-key-focus, var(--spectrum-global-color-gray-50)));
  border-color: var(--spectrum-high-contrast-highlight, var(--spectrum-actionbutton-border-color-hover, var(--spectrum-alias-border-color-hover)));
  color: var(--spectrum-high-contrast-button-text, var(--spectrum-actionbutton-text-color-key-focus, var(--spectrum-alias-text-color-hover)));
}

.Dniwja_spectrum-ActionButton.Dniwja_focus-ring .Dniwja_spectrum-Icon {
  fill: var(--spectrum-high-contrast-button-text, var(--spectrum-actionbutton-icon-color-key-focus, var(--spectrum-alias-icon-color-focus)));
}

.Dniwja_spectrum-ActionButton.Dniwja_focus-ring .Dniwja_spectrum-ActionButton-hold {
  fill: var(--spectrum-high-contrast-button-text, var(--spectrum-actionbutton-hold-icon-color-key-focus, var(--spectrum-alias-icon-color-hover)));
}

.Dniwja_spectrum-ActionButton.Dniwja_is-active {
  background-color: var(--spectrum-high-contrast-button-face, var(--spectrum-actionbutton-background-color-down, var(--spectrum-global-color-gray-200)));
  border-color: var(--spectrum-high-contrast-highlight, var(--spectrum-actionbutton-border-color-down, var(--spectrum-alias-border-color-down)));
  color: var(--spectrum-high-contrast-button-text, var(--spectrum-actionbutton-text-color-down, var(--spectrum-alias-text-color-down)));
}

.Dniwja_spectrum-ActionButton.Dniwja_is-active .Dniwja_spectrum-ActionButton-hold {
  fill: var(--spectrum-high-contrast-button-text, var(--spectrum-actionbutton-hold-icon-color-down, var(--spectrum-alias-icon-color-down)));
}

.Dniwja_spectrum-ActionButton:disabled {
  background-color: var(--spectrum-high-contrast-button-face, var(--spectrum-actionbutton-background-color-disabled, var(--spectrum-global-color-gray-200)));
  border-color: var(--spectrum-high-contrast-gray-text, var(--spectrum-actionbutton-border-color-disabled, var(--spectrum-alias-border-color-disabled)));
  color: var(--spectrum-high-contrast-gray-text, var(--spectrum-actionbutton-text-color-disabled, var(--spectrum-alias-text-color-disabled)));
}

.Dniwja_spectrum-ActionButton:disabled .Dniwja_spectrum-Icon {
  fill: var(--spectrum-high-contrast-gray-text, var(--spectrum-actionbutton-icon-color-disabled, var(--spectrum-alias-icon-color-disabled)));
}

.Dniwja_spectrum-ActionButton:disabled .Dniwja_spectrum-ActionButton-hold {
  fill: var(--spectrum-high-contrast-gray-text, var(--spectrum-actionbutton-hold-icon-color-disabled, var(--spectrum-alias-icon-color-disabled)));
}

.Dniwja_spectrum-ActionButton.Dniwja_is-disabled {
  background-color: var(--spectrum-high-contrast-button-face, var(--spectrum-actionbutton-background-color-disabled, var(--spectrum-global-color-gray-200)));
  border-color: var(--spectrum-high-contrast-gray-text, var(--spectrum-actionbutton-border-color-disabled, var(--spectrum-alias-border-color-disabled)));
  color: var(--spectrum-high-contrast-gray-text, var(--spectrum-actionbutton-text-color-disabled, var(--spectrum-alias-text-color-disabled)));
}

.Dniwja_spectrum-ActionButton.Dniwja_is-disabled .Dniwja_spectrum-Icon {
  fill: var(--spectrum-high-contrast-gray-text, var(--spectrum-actionbutton-icon-color-disabled, var(--spectrum-alias-icon-color-disabled)));
}

.Dniwja_spectrum-ActionButton.Dniwja_is-disabled .Dniwja_spectrum-ActionButton-hold {
  fill: var(--spectrum-high-contrast-gray-text, var(--spectrum-actionbutton-hold-icon-color-disabled, var(--spectrum-alias-icon-color-disabled)));
}

.Dniwja_spectrum-ActionButton.Dniwja_is-selected {
  background-color: var(--spectrum-high-contrast-highlight, var(--spectrum-alias-toggle-color-selected));
  border-color: var(--spectrum-alias-toggle-color-selected);
  color: var(--spectrum-high-contrast-highlight-text, var(--spectrum-gray-50));
}

.Dniwja_spectrum-ActionButton.Dniwja_is-selected .Dniwja_spectrum-Icon {
  fill: var(--spectrum-high-contrast-highlight-text, var(--spectrum-gray-50));
}

.Dniwja_spectrum-ActionButton.Dniwja_is-selected.Dniwja_is-hovered {
  background-color: var(--spectrum-high-contrast-highlight, var(--spectrum-alias-toggle-color-selected-hover));
  border-color: var(--spectrum-alias-toggle-color-selected-hover);
  color: var(--spectrum-high-contrast-highlight-text, var(--spectrum-gray-50));
}

.Dniwja_spectrum-ActionButton.Dniwja_is-selected.Dniwja_is-hovered .Dniwja_spectrum-Icon {
  fill: var(--spectrum-high-contrast-highlight-text, var(--spectrum-gray-50));
}

.Dniwja_spectrum-ActionButton.Dniwja_is-selected.Dniwja_focus-ring {
  background-color: var(--spectrum-high-contrast-highlight, var(--spectrum-alias-toggle-color-selected-key-focus));
  border-color: var(--spectrum-alias-toggle-color-selected-key-focus);
  color: var(--spectrum-high-contrast-highlight-text, var(--spectrum-gray-50));
}

.Dniwja_spectrum-ActionButton.Dniwja_is-selected.Dniwja_focus-ring .Dniwja_spectrum-Icon {
  fill: var(--spectrum-high-contrast-highlight-text, var(--spectrum-gray-50));
}

.Dniwja_spectrum-ActionButton.Dniwja_is-selected.Dniwja_is-active {
  background-color: var(--spectrum-high-contrast-highlight, var(--spectrum-alias-toggle-color-selected-down));
  border-color: var(--spectrum-alias-toggle-color-selected-down);
  color: var(--spectrum-high-contrast-highlight-text, var(--spectrum-gray-50));
}

.Dniwja_spectrum-ActionButton.Dniwja_is-selected.Dniwja_is-active .Dniwja_spectrum-Icon {
  fill: var(--spectrum-high-contrast-highlight-text, var(--spectrum-gray-50));
}

.Dniwja_spectrum-ActionButton.Dniwja_is-selected:disabled {
  background-color: var(--spectrum-high-contrast-button-face, var(--spectrum-actionbutton-background-color-selected-disabled, var(--spectrum-global-color-gray-200)));
  border-color: var(--spectrum-high-contrast-gray-text, var(--spectrum-actionbutton-border-color-selected-disabled, var(--spectrum-alias-border-color-disabled)));
  color: var(--spectrum-high-contrast-gray-text, var(--spectrum-actionbutton-text-color-selected-disabled, var(--spectrum-alias-text-color-disabled)));
}

.Dniwja_spectrum-ActionButton.Dniwja_is-selected:disabled .Dniwja_spectrum-Icon {
  fill: var(--spectrum-high-contrast-gray-text, var(--spectrum-actionbutton-icon-color-selected-disabled, var(--spectrum-alias-icon-color-disabled)));
}

.Dniwja_spectrum-ActionButton.Dniwja_is-selected.Dniwja_is-disabled {
  background-color: var(--spectrum-high-contrast-button-face, var(--spectrum-actionbutton-background-color-selected-disabled, var(--spectrum-global-color-gray-200)));
  border-color: var(--spectrum-high-contrast-gray-text, var(--spectrum-actionbutton-border-color-selected-disabled, var(--spectrum-alias-border-color-disabled)));
  color: var(--spectrum-high-contrast-gray-text, var(--spectrum-actionbutton-text-color-selected-disabled, var(--spectrum-alias-text-color-disabled)));
}

.Dniwja_spectrum-ActionButton.Dniwja_is-selected.Dniwja_is-disabled .Dniwja_spectrum-Icon {
  fill: var(--spectrum-high-contrast-gray-text, var(--spectrum-actionbutton-icon-color-selected-disabled, var(--spectrum-alias-icon-color-disabled)));
}

.Dniwja_spectrum-ActionButton--emphasized.Dniwja_spectrum-ActionButton--quiet.Dniwja_is-selected {
  background-color: var(--spectrum-high-contrast-highlight, var(--spectrum-accent-background-color-default));
  border-color: var(--spectrum-accent-background-color-default);
  color: var(--spectrum-high-contrast-highlight-text, var(--spectrum-actionbutton-emphasized-text-color-selected, var(--spectrum-global-color-static-white)));
}

.Dniwja_spectrum-ActionButton--emphasized.Dniwja_spectrum-ActionButton--quiet.Dniwja_is-selected .Dniwja_spectrum-Icon {
  fill: var(--spectrum-high-contrast-highlight-text, var(--spectrum-actionbutton-emphasized-icon-color-selected, var(--spectrum-global-color-static-white)));
}

.Dniwja_spectrum-ActionButton--emphasized.Dniwja_spectrum-ActionButton--quiet.Dniwja_is-selected.Dniwja_focus-ring {
  background-color: var(--spectrum-high-contrast-highlight, var(--spectrum-accent-background-color-key-focus));
  border-color: var(--spectrum-accent-background-color-hover);
  color: var(--spectrum-high-contrast-highlight-text, var(--spectrum-actionbutton-emphasized-text-color-selected-key-focus, var(--spectrum-global-color-static-white)));
}

.Dniwja_spectrum-ActionButton--emphasized.Dniwja_spectrum-ActionButton--quiet.Dniwja_is-selected.Dniwja_focus-ring .Dniwja_spectrum-Icon {
  fill: var(--spectrum-high-contrast-highlight-text, var(--spectrum-actionbutton-emphasized-icon-color-selected-key-focus, var(--spectrum-global-color-static-white)));
}

.Dniwja_spectrum-ActionButton--emphasized.Dniwja_spectrum-ActionButton--quiet.Dniwja_is-selected.Dniwja_is-hovered {
  background-color: var(--spectrum-high-contrast-highlight, var(--spectrum-accent-background-color-hover));
  border-color: var(--spectrum-accent-background-color-hover);
  color: var(--spectrum-high-contrast-highlight-text, var(--spectrum-actionbutton-emphasized-text-color-selected-hover, var(--spectrum-global-color-static-white)));
}

.Dniwja_spectrum-ActionButton--emphasized.Dniwja_spectrum-ActionButton--quiet.Dniwja_is-selected.Dniwja_is-hovered .Dniwja_spectrum-Icon {
  fill: var(--spectrum-high-contrast-highlight-text, var(--spectrum-actionbutton-emphasized-icon-color-selected-hover, var(--spectrum-global-color-static-white)));
}

.Dniwja_spectrum-ActionButton--emphasized.Dniwja_spectrum-ActionButton--quiet.Dniwja_is-selected.Dniwja_is-active {
  background-color: var(--spectrum-high-contrast-highlight, var(--spectrum-accent-background-color-down));
  border-color: var(--spectrum-accent-background-color-down);
  color: var(--spectrum-high-contrast-highlight-text, var(--spectrum-actionbutton-emphasized-text-color-selected-down, var(--spectrum-global-color-static-white)));
}

.Dniwja_spectrum-ActionButton--emphasized.Dniwja_spectrum-ActionButton--quiet.Dniwja_is-selected.Dniwja_is-active .Dniwja_spectrum-Icon {
  fill: var(--spectrum-high-contrast-highlight-text, var(--spectrum-actionbutton-emphasized-icon-color-selected-down, var(--spectrum-global-color-static-white)));
}

.Dniwja_spectrum-ActionButton--emphasized.Dniwja_spectrum-ActionButton--quiet.Dniwja_is-selected:disabled {
  background-color: var(--spectrum-high-contrast-button-face, var(--spectrum-actionbutton-emphasized-background-color-selected-disabled, var(--spectrum-global-color-gray-200)));
  border-color: var(--spectrum-high-contrast-gray-text, var(--spectrum-actionbutton-emphasized-border-color-selected-disabled, var(--spectrum-alias-border-color-disabled)));
  color: var(--spectrum-high-contrast-gray-text, var(--spectrum-actionbutton-emphasized-text-color-selected-disabled, var(--spectrum-alias-text-color-disabled)));
}

.Dniwja_spectrum-ActionButton--emphasized.Dniwja_spectrum-ActionButton--quiet.Dniwja_is-selected:disabled .Dniwja_spectrum-Icon {
  fill: var(--spectrum-high-contrast-gray-text, var(--spectrum-actionbutton-emphasized-icon-color-selected-disabled, var(--spectrum-alias-icon-color-disabled)));
}

.Dniwja_spectrum-ActionButton--emphasized.Dniwja_spectrum-ActionButton--quiet.Dniwja_is-selected.Dniwja_is-disabled {
  background-color: var(--spectrum-high-contrast-button-face, var(--spectrum-actionbutton-emphasized-background-color-selected-disabled, var(--spectrum-global-color-gray-200)));
  border-color: var(--spectrum-high-contrast-gray-text, var(--spectrum-actionbutton-emphasized-border-color-selected-disabled, var(--spectrum-alias-border-color-disabled)));
  color: var(--spectrum-high-contrast-gray-text, var(--spectrum-actionbutton-emphasized-text-color-selected-disabled, var(--spectrum-alias-text-color-disabled)));
}

.Dniwja_spectrum-ActionButton--emphasized.Dniwja_spectrum-ActionButton--quiet.Dniwja_is-selected.Dniwja_is-disabled .Dniwja_spectrum-Icon {
  fill: var(--spectrum-high-contrast-gray-text, var(--spectrum-actionbutton-emphasized-icon-color-selected-disabled, var(--spectrum-alias-icon-color-disabled)));
}

.Dniwja_spectrum-ActionButton--emphasized.Dniwja_is-selected {
  background-color: var(--spectrum-high-contrast-highlight, var(--spectrum-accent-background-color-default));
  border-color: var(--spectrum-accent-background-color-default);
  color: var(--spectrum-high-contrast-highlight-text, var(--spectrum-actionbutton-emphasized-text-color-selected, var(--spectrum-global-color-static-white)));
}

.Dniwja_spectrum-ActionButton--emphasized.Dniwja_is-selected .Dniwja_spectrum-Icon {
  fill: var(--spectrum-high-contrast-highlight-text, var(--spectrum-actionbutton-emphasized-icon-color-selected, var(--spectrum-global-color-static-white)));
}

.Dniwja_spectrum-ActionButton--emphasized.Dniwja_is-selected.Dniwja_focus-ring {
  background-color: var(--spectrum-high-contrast-highlight, var(--spectrum-accent-background-color-key-focus));
  border-color: var(--spectrum-accent-background-color-hover);
  color: var(--spectrum-high-contrast-highlight-text, var(--spectrum-actionbutton-emphasized-text-color-selected-key-focus, var(--spectrum-global-color-static-white)));
}

.Dniwja_spectrum-ActionButton--emphasized.Dniwja_is-selected.Dniwja_focus-ring .Dniwja_spectrum-Icon {
  fill: var(--spectrum-high-contrast-highlight-text, var(--spectrum-actionbutton-emphasized-icon-color-selected-key-focus, var(--spectrum-global-color-static-white)));
}

.Dniwja_spectrum-ActionButton--emphasized.Dniwja_is-selected.Dniwja_is-hovered {
  background-color: var(--spectrum-high-contrast-highlight, var(--spectrum-accent-background-color-hover));
  border-color: var(--spectrum-accent-background-color-hover);
  color: var(--spectrum-high-contrast-highlight-text, var(--spectrum-actionbutton-emphasized-text-color-selected-hover, var(--spectrum-global-color-static-white)));
}

.Dniwja_spectrum-ActionButton--emphasized.Dniwja_is-selected.Dniwja_is-hovered .Dniwja_spectrum-Icon {
  fill: var(--spectrum-high-contrast-highlight-text, var(--spectrum-actionbutton-emphasized-icon-color-selected-hover, var(--spectrum-global-color-static-white)));
}

.Dniwja_spectrum-ActionButton--emphasized.Dniwja_is-selected.Dniwja_is-active {
  background-color: var(--spectrum-high-contrast-highlight, var(--spectrum-accent-background-color-down));
  border-color: var(--spectrum-accent-background-color-down);
  color: var(--spectrum-high-contrast-highlight-text, var(--spectrum-actionbutton-emphasized-text-color-selected-down, var(--spectrum-global-color-static-white)));
}

.Dniwja_spectrum-ActionButton--emphasized.Dniwja_is-selected.Dniwja_is-active .Dniwja_spectrum-Icon {
  fill: var(--spectrum-high-contrast-highlight-text, var(--spectrum-actionbutton-emphasized-icon-color-selected-down, var(--spectrum-global-color-static-white)));
}

.Dniwja_spectrum-ActionButton--emphasized.Dniwja_is-selected:disabled {
  background-color: var(--spectrum-high-contrast-button-face, var(--spectrum-actionbutton-emphasized-background-color-selected-disabled, var(--spectrum-global-color-gray-200)));
  border-color: var(--spectrum-high-contrast-gray-text, var(--spectrum-actionbutton-emphasized-border-color-selected-disabled, var(--spectrum-alias-border-color-disabled)));
  color: var(--spectrum-high-contrast-gray-text, var(--spectrum-actionbutton-emphasized-text-color-selected-disabled, var(--spectrum-alias-text-color-disabled)));
}

.Dniwja_spectrum-ActionButton--emphasized.Dniwja_is-selected:disabled .Dniwja_spectrum-Icon {
  fill: var(--spectrum-high-contrast-gray-text, var(--spectrum-actionbutton-emphasized-icon-color-selected-disabled, var(--spectrum-alias-icon-color-disabled)));
}

.Dniwja_spectrum-ActionButton--emphasized.Dniwja_is-selected.Dniwja_is-disabled {
  background-color: var(--spectrum-high-contrast-button-face, var(--spectrum-actionbutton-emphasized-background-color-selected-disabled, var(--spectrum-global-color-gray-200)));
  border-color: var(--spectrum-high-contrast-gray-text, var(--spectrum-actionbutton-emphasized-border-color-selected-disabled, var(--spectrum-alias-border-color-disabled)));
  color: var(--spectrum-high-contrast-gray-text, var(--spectrum-actionbutton-emphasized-text-color-selected-disabled, var(--spectrum-alias-text-color-disabled)));
}

.Dniwja_spectrum-ActionButton--emphasized.Dniwja_is-selected.Dniwja_is-disabled .Dniwja_spectrum-Icon {
  fill: var(--spectrum-high-contrast-gray-text, var(--spectrum-actionbutton-emphasized-icon-color-selected-disabled, var(--spectrum-alias-icon-color-disabled)));
}

:is().Dniwja_focus-ring {
  background-color: var(--spectrum-high-contrast-highlight, var(--spectrum-accent-background-color-key-focus));
  border-color: var(--spectrum-accent-background-color-hover);
  color: var(--spectrum-high-contrast-highlight-text, var(--spectrum-actionbutton-emphasized-text-color-selected-key-focus, var(--spectrum-global-color-static-white)));
}

.Dniwja_spectrum-ActionButton--quiet {
  background-color: var(--spectrum-high-contrast-button-face, var(--spectrum-actionbutton-quiet-background-color, var(--spectrum-alias-background-color-transparent)));
  border-color: var(--spectrum-high-contrast-button-face, var(--spectrum-actionbutton-quiet-border-color, var(--spectrum-alias-border-color-transparent)));
  color: var(--spectrum-high-contrast-button-text, var(--spectrum-actionbutton-quiet-text-color, var(--spectrum-alias-text-color)));
}

.Dniwja_spectrum-ActionButton--quiet.Dniwja_is-hovered {
  background-color: var(--spectrum-high-contrast-button-face, var(--spectrum-actionbutton-quiet-background-color-hover, var(--spectrum-alias-background-color-transparent)));
  border-color: var(--spectrum-high-contrast-highlight, var(--spectrum-actionbutton-quiet-border-color-hover, var(--spectrum-alias-border-color-transparent)));
  color: var(--spectrum-high-contrast-button-text, var(--spectrum-actionbutton-quiet-text-color-hover, var(--spectrum-alias-text-color-hover)));
}

.Dniwja_spectrum-ActionButton--quiet.Dniwja_focus-ring {
  background-color: var(--spectrum-high-contrast-button-face, var(--spectrum-actionbutton-quiet-background-color-key-focus, var(--spectrum-alias-background-color-transparent)));
  border-color: var(--spectrum-high-contrast-highlight, var(--spectrum-actionbutton-quiet-border-color-hover, var(--spectrum-alias-border-color-transparent)));
  color: var(--spectrum-high-contrast-button-text, var(--spectrum-actionbutton-quiet-text-color-key-focus, var(--spectrum-alias-text-color-hover)));
}

.Dniwja_spectrum-ActionButton--quiet.Dniwja_is-active {
  background-color: var(--spectrum-high-contrast-button-face, var(--spectrum-actionbutton-quiet-background-color-down, var(--spectrum-global-color-gray-300)));
  border-color: var(--spectrum-high-contrast-highlight, var(--spectrum-actionbutton-quiet-border-color-down, var(--spectrum-global-color-gray-300)));
  color: var(--spectrum-high-contrast-button-text, var(--spectrum-actionbutton-quiet-text-color-down, var(--spectrum-alias-text-color-down)));
}

.Dniwja_spectrum-ActionButton--quiet:disabled, .Dniwja_spectrum-ActionButton--quiet.Dniwja_is-disabled {
  background-color: var(--spectrum-high-contrast-button-face, var(--spectrum-actionbutton-quiet-background-color-disabled, var(--spectrum-alias-background-color-transparent)));
  border-color: var(--spectrum-high-contrast-gray-text, var(--spectrum-actionbutton-quiet-border-color-disabled, var(--spectrum-alias-border-color-transparent)));
  color: var(--spectrum-high-contrast-gray-text, var(--spectrum-actionbutton-quiet-text-color-disabled, var(--spectrum-alias-text-color-disabled)));
}

.Dniwja_spectrum-ActionButton--staticWhite {
  mix-blend-mode: screen;
  --spectrum-actionbutton-static-background-color: var(--spectrum-actionbutton-static-white-background-color);
  --spectrum-actionbutton-static-background-color-hover: #ffffff1a;
  --spectrum-actionbutton-static-background-color-focus: #ffffff1a;
  --spectrum-actionbutton-static-background-color-active: #ffffff26;
  --spectrum-actionbutton-static-background-color-disabled: var(--spectrum-actionbutton-static-white-background-color-disabled);
  --spectrum-actionbutton-static-background-color-selected: #ffffffe6;
  --spectrum-actionbutton-static-background-color-selected-hover: white;
  --spectrum-actionbutton-static-background-color-selected-focus: white;
  --spectrum-actionbutton-static-background-color-selected-active: white;
  --spectrum-actionbutton-static-background-color-selected-disabled: #ffffff1a;
  --spectrum-actionbutton-static-border-color: var(--spectrum-actionbutton-static-white-border-color);
  --spectrum-actionbutton-static-border-color-hover: var(--spectrum-actionbutton-static-white-border-color-hover);
  --spectrum-actionbutton-static-border-color-active: var(--spectrum-actionbutton-static-white-border-color-down);
  --spectrum-actionbutton-static-border-color-focus: var(--spectrum-actionbutton-static-white-border-color-key-focus);
  --spectrum-actionbutton-static-border-disabled: var(--spectrum-actionbutton-static-white-border-color-disabled);
  --spectrum-actionbutton-static-border-color-selected-disabled: var(--spectrum-actionbutton-static-white-border-color-selected-disabled);
  --spectrum-actionbutton-static-color: white;
  --spectrum-actionbutton-static-color-selected: black;
  --spectrum-actionbutton-static-color-disabled: #ffffff8c;
}

.Dniwja_spectrum-ActionButton--staticWhite.Dniwja_spectrum-ActionButton--quiet {
  --spectrum-actionbutton-static-border-color: transparent;
  --spectrum-actionbutton-static-border-color-hover: transparent;
  --spectrum-actionbutton-static-border-color-active: transparent;
}

.Dniwja_spectrum-ActionButton--staticBlack {
  mix-blend-mode: multiply;
  --spectrum-actionbutton-static-background-color: var(--spectrum-actionbutton-static-black-background-color);
  --spectrum-actionbutton-static-background-color-hover: #0000001a;
  --spectrum-actionbutton-static-background-color-focus: #0000001a;
  --spectrum-actionbutton-static-background-color-active: #00000026;
  --spectrum-actionbutton-static-background-color-selected: #000000e6;
  --spectrum-actionbutton-static-background-color-disabled: var(--spectrum-actionbutton-static-black-background-color-disabled);
  --spectrum-actionbutton-static-background-color-selected-hover: black;
  --spectrum-actionbutton-static-background-color-selected-focus: black;
  --spectrum-actionbutton-static-background-color-selected-active: black;
  --spectrum-actionbutton-static-background-color-selected-disabled: #0000001a;
  --spectrum-actionbutton-static-border-color: var(--spectrum-actionbutton-static-black-border-color);
  --spectrum-actionbutton-static-border-color-hover: var(--spectrum-actionbutton-static-black-border-color-hover);
  --spectrum-actionbutton-static-border-color-active: var(--spectrum-actionbutton-static-black-border-color-down);
  --spectrum-actionbutton-static-border-color-focus: var(--spectrum-actionbutton-static-black-border-color-key-focus);
  --spectrum-actionbutton-static-border-disabled: var(--spectrum-actionbutton-static-black-border-color-disabled);
  --spectrum-actionbutton-static-border-color-selected-disabled: var(--spectrum-actionbutton-static-black-border-color-selected-disabled);
  --spectrum-actionbutton-static-color: black;
  --spectrum-actionbutton-static-color-selected: white;
  --spectrum-actionbutton-static-color-disabled: #0000008c;
}

.Dniwja_spectrum-ActionButton--staticBlack.Dniwja_spectrum-ActionButton--quiet {
  --spectrum-actionbutton-static-border-color: transparent;
  --spectrum-actionbutton-static-border-color-hover: transparent;
  --spectrum-actionbutton-static-border-color-active: transparent;
}

.Dniwja_spectrum-ActionButton--staticColor {
  background-color: var(--spectrum-actionbutton-static-background-color);
  border-color: var(--spectrum-actionbutton-static-border-color);
  color: var(--spectrum-actionbutton-static-color);
  --spectrum-focus-ring-color: var(--spectrum-actionbutton-static-color);
}

.Dniwja_spectrum-ActionButton--staticColor .Dniwja_spectrum-Icon {
  fill: var(--spectrum-actionbutton-static-color);
}

.Dniwja_spectrum-ActionButton--staticColor.Dniwja_is-hovered {
  background-color: var(--spectrum-actionbutton-static-background-color-hover);
  border-color: var(--spectrum-actionbutton-static-border-color-hover);
  color: var(--spectrum-actionbutton-static-color);
}

.Dniwja_spectrum-ActionButton--staticColor.Dniwja_is-hovered .Dniwja_spectrum-Icon, .Dniwja_spectrum-ActionButton--staticColor.Dniwja_is-hovered .Dniwja_spectrum-ActionButton-hold {
  fill: var(--spectrum-actionbutton-static-color);
}

.Dniwja_spectrum-ActionButton--staticColor.Dniwja_focus-ring {
  background-color: var(--spectrum-actionbutton-static-background-color-focus);
  border-color: var(--spectrum-actionbutton-static-border-color-focus);
  box-shadow: none;
  color: var(--spectrum-actionbutton-static-color);
}

.Dniwja_spectrum-ActionButton--staticColor.Dniwja_focus-ring.Dniwja_is-hovered, .Dniwja_spectrum-ActionButton--staticColor.Dniwja_focus-ring.Dniwja_is-active {
  border-color: var(--spectrum-actionbutton-static-border-color-focus);
}

.Dniwja_spectrum-ActionButton--staticColor.Dniwja_focus-ring .Dniwja_spectrum-Icon, .Dniwja_spectrum-ActionButton--staticColor.Dniwja_focus-ring .Dniwja_spectrum-ActionButton-hold {
  fill: var(--spectrum-actionbutton-static-color);
}

.Dniwja_spectrum-ActionButton--staticColor.Dniwja_is-active {
  background-color: var(--spectrum-actionbutton-static-background-color-active);
  border-color: var(--spectrum-actionbutton-static-border-color-active);
  color: var(--spectrum-actionbutton-static-color);
}

.Dniwja_spectrum-ActionButton--staticColor.Dniwja_is-active .Dniwja_spectrum-ActionButton-hold {
  fill: var(--spectrum-actionbutton-static-color);
}

.Dniwja_spectrum-ActionButton--staticColor:disabled {
  background-color: var(--spectrum-actionbutton-static-background-color-disabled);
  border-color: var(--spectrum-actionbutton-static-border-disabled);
  color: var(--spectrum-actionbutton-static-color-disabled);
}

.Dniwja_spectrum-ActionButton--staticColor:disabled .Dniwja_spectrum-Icon, .Dniwja_spectrum-ActionButton--staticColor:disabled .Dniwja_spectrum-ActionButton-hold {
  fill: var(--spectrum-actionbutton-static-color-disabled);
}

.Dniwja_spectrum-ActionButton--staticColor.Dniwja_is-disabled {
  background-color: var(--spectrum-actionbutton-static-background-color-disabled);
  border-color: var(--spectrum-actionbutton-static-border-disabled);
  color: var(--spectrum-actionbutton-static-color-disabled);
}

.Dniwja_spectrum-ActionButton--staticColor.Dniwja_is-disabled .Dniwja_spectrum-Icon, .Dniwja_spectrum-ActionButton--staticColor.Dniwja_is-disabled .Dniwja_spectrum-ActionButton-hold {
  fill: var(--spectrum-actionbutton-static-color-disabled);
}

.Dniwja_spectrum-ActionButton--staticColor.Dniwja_spectrum-ActionButton--quiet.Dniwja_is-selected {
  background-color: var(--spectrum-actionbutton-static-background-color-selected);
  border-color: var(--spectrum-actionbutton-static-background-color-selected);
  color: var(--spectrum-actionbutton-static-color-selected);
}

.Dniwja_spectrum-ActionButton--staticColor.Dniwja_spectrum-ActionButton--quiet.Dniwja_is-selected .Dniwja_spectrum-Icon {
  fill: var(--spectrum-actionbutton-static-color-selected);
}

.Dniwja_spectrum-ActionButton--staticColor.Dniwja_spectrum-ActionButton--quiet.Dniwja_is-selected.Dniwja_focus-ring {
  background-color: var(--spectrum-actionbutton-static-background-color-selected-focus);
  border-color: var(--spectrum-actionbutton-static-background-color-selected-focus);
  color: var(--spectrum-actionbutton-static-color-selected);
  box-shadow: none;
}

.Dniwja_spectrum-ActionButton--staticColor.Dniwja_spectrum-ActionButton--quiet.Dniwja_is-selected.Dniwja_focus-ring .Dniwja_spectrum-Icon {
  fill: var(--spectrum-actionbutton-static-color-selected);
}

.Dniwja_spectrum-ActionButton--staticColor.Dniwja_spectrum-ActionButton--quiet.Dniwja_is-selected.Dniwja_is-hovered {
  background-color: var(--spectrum-actionbutton-static-background-color-selected-hover);
  border-color: var(--spectrum-actionbutton-static-background-color-selected-hover);
  color: var(--spectrum-actionbutton-static-color-selected);
}

.Dniwja_spectrum-ActionButton--staticColor.Dniwja_spectrum-ActionButton--quiet.Dniwja_is-selected.Dniwja_is-hovered .Dniwja_spectrum-Icon {
  fill: var(--spectrum-actionbutton-static-color-selected);
}

.Dniwja_spectrum-ActionButton--staticColor.Dniwja_spectrum-ActionButton--quiet.Dniwja_is-selected.Dniwja_is-active {
  background-color: var(--spectrum-actionbutton-static-background-color-selected-hover);
  border-color: var(--spectrum-actionbutton-static-background-color-selected-hover);
  color: var(--spectrum-actionbutton-static-color-selected);
}

.Dniwja_spectrum-ActionButton--staticColor.Dniwja_spectrum-ActionButton--quiet.Dniwja_is-selected.Dniwja_is-active .Dniwja_spectrum-Icon {
  fill: var(--spectrum-actionbutton-static-color-selected);
}

.Dniwja_spectrum-ActionButton--staticColor.Dniwja_spectrum-ActionButton--quiet.Dniwja_is-selected:disabled {
  background-color: var(--spectrum-actionbutton-static-background-color-selected-disabled);
  border-color: var(--spectrum-actionbutton-static-border-color-selected-disabled);
  color: var(--spectrum-actionbutton-static-color-disabled);
}

.Dniwja_spectrum-ActionButton--staticColor.Dniwja_spectrum-ActionButton--quiet.Dniwja_is-selected:disabled .Dniwja_spectrum-Icon, .Dniwja_spectrum-ActionButton--staticColor.Dniwja_spectrum-ActionButton--quiet.Dniwja_is-selected:disabled .Dniwja_spectrum-ActionButton-hold {
  fill: var(--spectrum-actionbutton-static-color-disabled);
}

.Dniwja_spectrum-ActionButton--staticColor.Dniwja_spectrum-ActionButton--quiet.Dniwja_is-selected.Dniwja_is-disabled {
  background-color: var(--spectrum-actionbutton-static-background-color-selected-disabled);
  border-color: var(--spectrum-actionbutton-static-border-color-selected-disabled);
  color: var(--spectrum-actionbutton-static-color-disabled);
}

.Dniwja_spectrum-ActionButton--staticColor.Dniwja_spectrum-ActionButton--quiet.Dniwja_is-selected.Dniwja_is-disabled .Dniwja_spectrum-Icon, .Dniwja_spectrum-ActionButton--staticColor.Dniwja_spectrum-ActionButton--quiet.Dniwja_is-selected.Dniwja_is-disabled .Dniwja_spectrum-ActionButton-hold {
  fill: var(--spectrum-actionbutton-static-color-disabled);
}

.Dniwja_spectrum-ActionButton--staticColor.Dniwja_is-selected {
  background-color: var(--spectrum-actionbutton-static-background-color-selected);
  border-color: var(--spectrum-actionbutton-static-background-color-selected);
  color: var(--spectrum-actionbutton-static-color-selected);
}

.Dniwja_spectrum-ActionButton--staticColor.Dniwja_is-selected .Dniwja_spectrum-Icon {
  fill: var(--spectrum-actionbutton-static-color-selected);
}

.Dniwja_spectrum-ActionButton--staticColor.Dniwja_is-selected.Dniwja_focus-ring {
  background-color: var(--spectrum-actionbutton-static-background-color-selected-focus);
  border-color: var(--spectrum-actionbutton-static-background-color-selected-focus);
  color: var(--spectrum-actionbutton-static-color-selected);
  box-shadow: none;
}

.Dniwja_spectrum-ActionButton--staticColor.Dniwja_is-selected.Dniwja_focus-ring .Dniwja_spectrum-Icon {
  fill: var(--spectrum-actionbutton-static-color-selected);
}

.Dniwja_spectrum-ActionButton--staticColor.Dniwja_is-selected.Dniwja_is-hovered {
  background-color: var(--spectrum-actionbutton-static-background-color-selected-hover);
  border-color: var(--spectrum-actionbutton-static-background-color-selected-hover);
  color: var(--spectrum-actionbutton-static-color-selected);
}

.Dniwja_spectrum-ActionButton--staticColor.Dniwja_is-selected.Dniwja_is-hovered .Dniwja_spectrum-Icon {
  fill: var(--spectrum-actionbutton-static-color-selected);
}

.Dniwja_spectrum-ActionButton--staticColor.Dniwja_is-selected.Dniwja_is-active {
  background-color: var(--spectrum-actionbutton-static-background-color-selected-hover);
  border-color: var(--spectrum-actionbutton-static-background-color-selected-hover);
  color: var(--spectrum-actionbutton-static-color-selected);
}

.Dniwja_spectrum-ActionButton--staticColor.Dniwja_is-selected.Dniwja_is-active .Dniwja_spectrum-Icon {
  fill: var(--spectrum-actionbutton-static-color-selected);
}

.Dniwja_spectrum-ActionButton--staticColor.Dniwja_is-selected:disabled {
  background-color: var(--spectrum-actionbutton-static-background-color-selected-disabled);
  border-color: var(--spectrum-actionbutton-static-border-color-selected-disabled);
  color: var(--spectrum-actionbutton-static-color-disabled);
}

.Dniwja_spectrum-ActionButton--staticColor.Dniwja_is-selected:disabled .Dniwja_spectrum-Icon, .Dniwja_spectrum-ActionButton--staticColor.Dniwja_is-selected:disabled .Dniwja_spectrum-ActionButton-hold {
  fill: var(--spectrum-actionbutton-static-color-disabled);
}

.Dniwja_spectrum-ActionButton--staticColor.Dniwja_is-selected.Dniwja_is-disabled {
  background-color: var(--spectrum-actionbutton-static-background-color-selected-disabled);
  border-color: var(--spectrum-actionbutton-static-border-color-selected-disabled);
  color: var(--spectrum-actionbutton-static-color-disabled);
}

.Dniwja_spectrum-ActionButton--staticColor.Dniwja_is-selected.Dniwja_is-disabled .Dniwja_spectrum-Icon, .Dniwja_spectrum-ActionButton--staticColor.Dniwja_is-selected.Dniwja_is-disabled .Dniwja_spectrum-ActionButton-hold {
  fill: var(--spectrum-actionbutton-static-color-disabled);
}

:is().Dniwja_focus-ring {
  background-color: var(--spectrum-actionbutton-static-background-color-selected-focus);
  border-color: var(--spectrum-actionbutton-static-background-color-selected-focus);
  color: var(--spectrum-actionbutton-static-color-selected);
  box-shadow: none;
}

:is().Dniwja_focus-ring .Dniwja_spectrum-Icon {
  fill: var(--spectrum-actionbutton-static-color-selected);
}

:is().Dniwja_is-hovered {
  background-color: var(--spectrum-actionbutton-static-background-color-selected-hover);
  border-color: var(--spectrum-actionbutton-static-background-color-selected-hover);
  color: var(--spectrum-actionbutton-static-color-selected);
}

:is().Dniwja_is-hovered .Dniwja_spectrum-Icon {
  fill: var(--spectrum-actionbutton-static-color-selected);
}

:is().Dniwja_is-active {
  background-color: var(--spectrum-actionbutton-static-background-color-selected-hover);
  border-color: var(--spectrum-actionbutton-static-background-color-selected-hover);
  color: var(--spectrum-actionbutton-static-color-selected);
}

:is().Dniwja_is-active .Dniwja_spectrum-Icon {
  fill: var(--spectrum-actionbutton-static-color-selected);
}

:is():disabled {
  background-color: var(--spectrum-actionbutton-static-background-color-selected-disabled);
  border-color: var(--spectrum-actionbutton-static-border-color-selected-disabled);
  color: var(--spectrum-actionbutton-static-color-disabled);
}

:is():disabled .Dniwja_spectrum-Icon {
  fill: var(--spectrum-actionbutton-static-color-disabled);
}

:is():disabled .Dniwja_spectrum-ActionButton-hold {
  fill: var(--spectrum-actionbutton-static-color-disabled);
}

:is().Dniwja_is-disabled {
  background-color: var(--spectrum-actionbutton-static-background-color-selected-disabled);
  border-color: var(--spectrum-actionbutton-static-border-color-selected-disabled);
  color: var(--spectrum-actionbutton-static-color-disabled);
}

:is().Dniwja_is-disabled .Dniwja_spectrum-Icon {
  fill: var(--spectrum-actionbutton-static-color-disabled);
}

:is().Dniwja_is-disabled .Dniwja_spectrum-ActionButton-hold {
  fill: var(--spectrum-actionbutton-static-color-disabled);
}

:is() .Dniwja_spectrum-ActionButton-hold {
  fill: var(--spectrum-actionbutton-static-color-disabled);
}

.Dniwja_spectrum-ActionButton--staticColor.Dniwja_spectrum-ActionButton--quiet {
  --spectrum-actionbutton-static-background-color: transparent;
  --spectrum-actionbutton-static-background-color-disabled: transparent;
  border-color: #0000;
}

.Dniwja_spectrum-ActionButton--staticColor.Dniwja_spectrum-ActionButton--quiet.Dniwja_is-selected, .Dniwja_spectrum-ActionButton--staticColor.Dniwja_spectrum-ActionButton--quiet.Dniwja_is-hovered, .Dniwja_spectrum-ActionButton--staticColor.Dniwja_spectrum-ActionButton--quiet.Dniwja_is-hovered.Dniwja_is-selected, .Dniwja_spectrum-ActionButton--staticColor.Dniwja_spectrum-ActionButton--quiet.Dniwja_is-active, .Dniwja_spectrum-ActionButton--staticColor.Dniwja_spectrum-ActionButton--quiet.Dniwja_is-active.Dniwja_is-selected, .Dniwja_spectrum-ActionButton--staticColor.Dniwja_spectrum-ActionButton--quiet:disabled, .Dniwja_spectrum-ActionButton--staticColor.Dniwja_spectrum-ActionButton--quiet:disabled.Dniwja_is-selected, .Dniwja_spectrum-ActionButton--staticColor.Dniwja_spectrum-ActionButton--quiet.Dniwja_is-disabled, .Dniwja_spectrum-ActionButton--staticColor.Dniwja_spectrum-ActionButton--quiet.Dniwja_is-disabled.Dniwja_is-selected {
  border-color: #0000;
}

:is() {
  border-color: #0000;
}

:is().Dniwja_is-selected {
  border-color: #0000;
}

.Dniwja_spectrum-LogicButton--and {
  background-color: var(--spectrum-global-color-static-blue-600, #1473e6);
  border-color: var(--spectrum-global-color-static-blue-600, #1473e6);
  color: var(--spectrum-logicbutton-and-text-color, var(--spectrum-global-color-static-white));
}

.Dniwja_spectrum-LogicButton--and.Dniwja_is-hovered {
  background-color: var(--spectrum-global-color-static-blue-700, #0d66d0);
  border-color: var(--spectrum-global-color-static-blue-700, #0d66d0);
  color: var(--spectrum-logicbutton-and-text-color, var(--spectrum-global-color-static-white));
}

.Dniwja_spectrum-LogicButton--and:disabled, .Dniwja_spectrum-LogicButton--and.Dniwja_is-disabled {
  background-color: var(--spectrum-logicbutton-and-background-color-disabled, var(--spectrum-global-color-gray-200));
  border-color: var(--spectrum-logicbutton-and-border-color-disabled, var(--spectrum-global-color-gray-200));
  color: var(--spectrum-logicbutton-and-text-color-disabled, var(--spectrum-alias-text-color-disabled));
}

.Dniwja_spectrum-LogicButton--or {
  background-color: var(--spectrum-global-color-static-magenta-500, #d83790);
  border-color: var(--spectrum-global-color-static-magenta-500, #d83790);
  color: var(--spectrum-logicbutton-or-text-color, var(--spectrum-global-color-static-white));
}

.Dniwja_spectrum-LogicButton--or.Dniwja_is-hovered {
  background-color: var(--spectrum-global-color-static-magenta-600, #ca2982);
  border-color: var(--spectrum-global-color-static-magenta-600, #ca2982);
  color: var(--spectrum-logicbutton-or-text-color, var(--spectrum-global-color-static-white));
}

.Dniwja_spectrum-LogicButton--or:disabled, .Dniwja_spectrum-LogicButton--or.Dniwja_is-disabled {
  background-color: var(--spectrum-logicbutton-and-background-color-disabled, var(--spectrum-global-color-gray-200));
  border-color: var(--spectrum-logicbutton-and-border-color-disabled, var(--spectrum-global-color-gray-200));
  color: var(--spectrum-logicbutton-and-text-color-disabled, var(--spectrum-alias-text-color-disabled));
}

.Dniwja_spectrum-FieldButton {
  color: var(--spectrum-fieldbutton-text-color, var(--spectrum-alias-text-color));
  background-color: var(--spectrum-fieldbutton-background-color, var(--spectrum-global-color-gray-75));
  border-color: var(--spectrum-fieldbutton-border-color, var(--spectrum-alias-border-color));
}

.Dniwja_spectrum-FieldButton .Dniwja_spectrum-Icon {
  fill: var(--spectrum-fieldbutton-icon-color, var(--spectrum-alias-icon-color));
}

.Dniwja_spectrum-FieldButton.Dniwja_is-hovered {
  color: var(--spectrum-fieldbutton-text-color-hover, var(--spectrum-alias-text-color-hover));
  background-color: var(--spectrum-fieldbutton-background-color-hover, var(--spectrum-global-color-gray-50));
  border-color: var(--spectrum-fieldbutton-border-color-hover, var(--spectrum-alias-border-color-hover));
}

.Dniwja_spectrum-FieldButton.Dniwja_is-hovered .Dniwja_spectrum-Icon {
  fill: var(--spectrum-fieldbutton-icon-color-hover, var(--spectrum-alias-icon-color-hover));
}

.Dniwja_spectrum-FieldButton.Dniwja_is-active {
  background-color: var(--spectrum-fieldbutton-background-color-down, var(--spectrum-global-color-gray-200));
  border-color: var(--spectrum-fieldbutton-border-color-down, var(--spectrum-alias-border-color-down));
}

.Dniwja_spectrum-FieldButton.Dniwja_is-active .Dniwja_spectrum-Icon {
  fill: var(--spectrum-fieldbutton-icon-color-down, var(--spectrum-alias-icon-color-down));
}

.Dniwja_spectrum-FieldButton.Dniwja_is-selected {
  background-color: var(--spectrum-fieldbutton-background-color-down, var(--spectrum-global-color-gray-200));
  border-color: var(--spectrum-fieldbutton-border-color-down, var(--spectrum-alias-border-color-down));
}

.Dniwja_spectrum-FieldButton.Dniwja_is-selected .Dniwja_spectrum-Icon {
  fill: var(--spectrum-fieldbutton-icon-color-down, var(--spectrum-alias-icon-color-down));
}

.Dniwja_spectrum-FieldButton.Dniwja_focus-ring {
  background-color: var(--spectrum-fieldbutton-background-color-key-focus, var(--spectrum-global-color-gray-50));
  border-color: var(--spectrum-fieldbutton-border-color-key-focus, var(--spectrum-alias-border-color-focus));
  color: var(--spectrum-fieldbutton-text-color-key-focus, var(--spectrum-alias-text-color-hover));
}

.Dniwja_spectrum-FieldButton.Dniwja_focus-ring .Dniwja_spectrum-Icon {
  fill: var(--spectrum-fieldbutton-icon-color-key-focus, var(--spectrum-alias-icon-color-focus));
}

.Dniwja_spectrum-FieldButton.Dniwja_focus-ring.Dniwja_is-placeholder {
  fill: var(--spectrum-fieldbutton-placeholder-text-color-key-focus, var(--spectrum-alias-placeholder-text-color-hover));
}

.Dniwja_spectrum-FieldButton.Dniwja_is-focused {
  background-color: var(--spectrum-fieldbutton-background-color-key-focus, var(--spectrum-global-color-gray-50));
  border-color: var(--spectrum-fieldbutton-border-color-key-focus, var(--spectrum-alias-border-color-focus));
  color: var(--spectrum-fieldbutton-text-color-key-focus, var(--spectrum-alias-text-color-hover));
}

.Dniwja_spectrum-FieldButton.Dniwja_is-focused .Dniwja_spectrum-Icon {
  fill: var(--spectrum-fieldbutton-icon-color-key-focus, var(--spectrum-alias-icon-color-focus));
}

.Dniwja_spectrum-FieldButton.Dniwja_is-focused.Dniwja_is-placeholder {
  fill: var(--spectrum-fieldbutton-placeholder-text-color-key-focus, var(--spectrum-alias-placeholder-text-color-hover));
}

:is().Dniwja_is-placeholder {
  fill: var(--spectrum-fieldbutton-placeholder-text-color-key-focus, var(--spectrum-alias-placeholder-text-color-hover));
}

.Dniwja_spectrum-FieldButton.Dniwja_spectrum-FieldButton--invalid {
  border-color: var(--spectrum-fieldbutton-border-color-error, var(--spectrum-global-color-red-500));
}

.Dniwja_spectrum-FieldButton.Dniwja_spectrum-FieldButton--invalid.Dniwja_is-hovered {
  border-color: var(--spectrum-fieldbutton-border-color-error-hover, var(--spectrum-global-color-red-600));
}

.Dniwja_spectrum-FieldButton.Dniwja_spectrum-FieldButton--invalid.Dniwja_is-active, .Dniwja_spectrum-FieldButton.Dniwja_spectrum-FieldButton--invalid.Dniwja_is-selected {
  border-color: var(--spectrum-fieldbutton-border-color-error-down, var(--spectrum-global-color-red-600));
}

.Dniwja_spectrum-FieldButton.Dniwja_spectrum-FieldButton--invalid.Dniwja_focus-ring, .Dniwja_spectrum-FieldButton.Dniwja_spectrum-FieldButton--invalid.Dniwja_is-focused {
  border-color: var(--spectrum-fieldbutton-border-color-error-key-focus, var(--spectrum-alias-border-color-focus));
}

.Dniwja_spectrum-FieldButton:disabled {
  background-color: var(--spectrum-fieldbutton-background-color-disabled, var(--spectrum-global-color-gray-200));
  color: var(--spectrum-fieldbutton-text-color-disabled, var(--spectrum-alias-text-color-disabled));
}

.Dniwja_spectrum-FieldButton:disabled .Dniwja_spectrum-Icon {
  fill: var(--spectrum-fieldbutton-icon-color-disabled, var(--spectrum-alias-icon-color-disabled));
}

.Dniwja_spectrum-FieldButton.Dniwja_is-disabled {
  background-color: var(--spectrum-fieldbutton-background-color-disabled, var(--spectrum-global-color-gray-200));
  color: var(--spectrum-fieldbutton-text-color-disabled, var(--spectrum-alias-text-color-disabled));
}

.Dniwja_spectrum-FieldButton.Dniwja_is-disabled .Dniwja_spectrum-Icon {
  fill: var(--spectrum-fieldbutton-icon-color-disabled, var(--spectrum-alias-icon-color-disabled));
}

:is() .Dniwja_spectrum-Icon {
  fill: var(--spectrum-fieldbutton-icon-color-disabled, var(--spectrum-alias-icon-color-disabled));
}

.Dniwja_spectrum-FieldButton--quiet {
  color: var(--spectrum-fieldbutton-text-color, var(--spectrum-alias-text-color));
  border-color: var(--spectrum-fieldbutton-quiet-border-color, var(--spectrum-alias-border-color-transparent));
  background-color: var(--spectrum-fieldbutton-quiet-background-color, var(--spectrum-alias-background-color-transparent));
}

.Dniwja_spectrum-FieldButton--quiet.Dniwja_is-hovered {
  background-color: var(--spectrum-fieldbutton-quiet-background-color-hover, var(--spectrum-alias-background-color-transparent));
  color: var(--spectrum-fieldbutton-text-color-hover, var(--spectrum-alias-text-color-hover));
}

.Dniwja_spectrum-FieldButton--quiet.Dniwja_focus-ring {
  background-color: var(--spectrum-fieldbutton-quiet-background-color-key-focus, var(--spectrum-alias-background-color-transparent));
}

.Dniwja_spectrum-FieldButton--quiet.Dniwja_focus-ring.Dniwja_is-placeholder {
  color: var(--spectrum-fieldbutton-quiet-placeholder-text-color-key-focus, var(--spectrum-alias-placeholder-text-color-hover));
}

.Dniwja_spectrum-FieldButton--quiet.Dniwja_is-focused {
  background-color: var(--spectrum-fieldbutton-quiet-background-color-key-focus, var(--spectrum-alias-background-color-transparent));
}

.Dniwja_spectrum-FieldButton--quiet.Dniwja_is-focused.Dniwja_is-placeholder {
  color: var(--spectrum-fieldbutton-quiet-placeholder-text-color-key-focus, var(--spectrum-alias-placeholder-text-color-hover));
}

:is().Dniwja_is-placeholder {
  color: var(--spectrum-fieldbutton-quiet-placeholder-text-color-key-focus, var(--spectrum-alias-placeholder-text-color-hover));
}

.Dniwja_spectrum-FieldButton--quiet.Dniwja_is-active {
  background-color: var(--spectrum-fieldbutton-quiet-background-color-down, var(--spectrum-alias-background-color-transparent));
  border-color: var(--spectrum-fieldbutton-quiet-border-color-down, var(--spectrum-alias-border-color-transparent));
}

.Dniwja_spectrum-FieldButton--quiet.Dniwja_is-active.Dniwja_focus-ring, .Dniwja_spectrum-FieldButton--quiet.Dniwja_is-active.Dniwja_is-focused {
  background-color: var(--spectrum-fieldbutton-quiet-background-color-key-focus, var(--spectrum-alias-background-color-transparent));
}

.Dniwja_spectrum-FieldButton--quiet.Dniwja_is-selected {
  background-color: var(--spectrum-fieldbutton-quiet-background-color-down, var(--spectrum-alias-background-color-transparent));
  border-color: var(--spectrum-fieldbutton-quiet-border-color-down, var(--spectrum-alias-border-color-transparent));
}

.Dniwja_spectrum-FieldButton--quiet.Dniwja_is-selected.Dniwja_focus-ring, .Dniwja_spectrum-FieldButton--quiet.Dniwja_is-selected.Dniwja_is-focused {
  background-color: var(--spectrum-fieldbutton-quiet-background-color-key-focus, var(--spectrum-alias-background-color-transparent));
}

:is().Dniwja_focus-ring {
  background-color: var(--spectrum-fieldbutton-quiet-background-color-key-focus, var(--spectrum-alias-background-color-transparent));
}

:is().Dniwja_is-focused {
  background-color: var(--spectrum-fieldbutton-quiet-background-color-key-focus, var(--spectrum-alias-background-color-transparent));
}

.Dniwja_spectrum-FieldButton--quiet:disabled, .Dniwja_spectrum-FieldButton--quiet.Dniwja_is-disabled {
  background-color: var(--spectrum-fieldbutton-quiet-background-color-disabled, var(--spectrum-alias-background-color-transparent));
  color: var(--spectrum-fieldbutton-text-color-disabled, var(--spectrum-alias-text-color-disabled));
}

@media (forced-colors: active) {
  .Dniwja_spectrum-ActionButton, .Dniwja_spectrum-ClearButton, .Dniwja_spectrum-LogicButton, .Dniwja_spectrum-FieldButton {
    forced-color-adjust: none;
    --spectrum-clearbutton-medium-icon-color: ButtonText;
    --spectrum-clearbutton-medium-icon-color-disabled: GrayText;
    --spectrum-clearbutton-medium-icon-color-down: Highlight;
    --spectrum-clearbutton-medium-icon-color-hover: Highlight;
    --spectrum-clearbutton-medium-icon-color-key-focus: Highlight;
    --spectrum-fieldbutton-background-color: ButtonFace;
    --spectrum-fieldbutton-background-color-disabled: ButtonFace;
    --spectrum-fieldbutton-background-color-down: ButtonFace;
    --spectrum-fieldbutton-background-color-hover: ButtonFace;
    --spectrum-fieldbutton-background-color-key-focus: ButtonFace;
    --spectrum-fieldbutton-border-color: ButtonText;
    --spectrum-fieldbutton-border-color-down: Highlight;
    --spectrum-fieldbutton-border-color-error: ButtonText;
    --spectrum-fieldbutton-border-color-error-down: Highlight;
    --spectrum-fieldbutton-border-color-error-hover: Highlight;
    --spectrum-fieldbutton-border-color-error-key-focus: Highlight;
    --spectrum-fieldbutton-border-color-hover: Highlight;
    --spectrum-fieldbutton-border-color-key-focus: Highlight;
    --spectrum-fieldbutton-icon-color-disabled: GrayText;
    --spectrum-fieldbutton-placeholder-text-color-key-focus: ButtonText;
    --spectrum-fieldbutton-quiet-background-color: ButtonFace;
    --spectrum-fieldbutton-quiet-background-color-disabled: ButtonFace;
    --spectrum-fieldbutton-quiet-background-color-down: ButtonFace;
    --spectrum-fieldbutton-quiet-background-color-hover: ButtonFace;
    --spectrum-fieldbutton-quiet-background-color-key-focus: ButtonFace;
    --spectrum-fieldbutton-quiet-border-color: ButtonFace;
    --spectrum-fieldbutton-quiet-border-color-down: Highlight;
    --spectrum-fieldbutton-quiet-placeholder-text-color-key-focus: ButtonText;
    --spectrum-fieldbutton-text-color: ButtonText;
    --spectrum-fieldbutton-text-color-disabled: GrayText;
    --spectrum-fieldbutton-text-color-hover: ButtonText;
    --spectrum-fieldbutton-text-color-key-focus: ButtonText;
    --spectrum-logicbutton-and-background-color: ButtonFace;
    --spectrum-logicbutton-and-background-color-disabled: ButtonFace;
    --spectrum-logicbutton-and-background-color-hover: ButtonFace;
    --spectrum-logicbutton-and-border-color: ButtonText;
    --spectrum-logicbutton-and-border-color-disabled: GrayText;
    --spectrum-logicbutton-and-border-color-hover: Highlight;
    --spectrum-logicbutton-and-text-color: ButtonText;
    --spectrum-logicbutton-and-text-color-disabled: GrayText;
    --spectrum-logicbutton-or-background-color: ButtonFace;
    --spectrum-logicbutton-or-background-color-hover: ButtonFace;
    --spectrum-logicbutton-or-border-color: ButtonText;
    --spectrum-logicbutton-or-border-color-hover: Highlight;
    --spectrum-logicbutton-or-text-color: ButtonText;
    --spectrum-button-primary-focus-ring-color-key-focus: CanvasText;
    --spectrum-button-primary-focus-ring-size-key-focus: 3px;
    --spectrum-dropdown-border-color-key-focus: Highlight;
  }

  .Dniwja_spectrum-Button--overBackground {
    --spectrum-button-over-background-color: ButtonText;
  }

  .Dniwja_spectrum-ActionButton--staticWhite, .Dniwja_spectrum-ActionButton--staticBlack {
    mix-blend-mode: normal;
    --spectrum-actionbutton-static-background-color-hover: ButtonFace;
    --spectrum-actionbutton-static-background-color-focus: ButtonFace;
    --spectrum-actionbutton-static-background-color-active: ButtonFace;
    --spectrum-actionbutton-static-background-color-selected: Highlight;
    --spectrum-actionbutton-static-background-color-selected-hover: Highlight;
    --spectrum-actionbutton-static-background-color-selected-focus: Highlight;
    --spectrum-actionbutton-static-background-color-selected-active: Highlight;
    --spectrum-actionbutton-static-border-color: ButtonText;
    --spectrum-actionbutton-static-border-color-hover: Highlight;
    --spectrum-actionbutton-static-border-color-active: ButtonText;
    --spectrum-actionbutton-static-border-color-focus: CanvasText;
    --spectrum-actionbutton-static-border-disabled: GrayText;
    --spectrum-actionbutton-static-color: ButtonText;
    --spectrum-actionbutton-static-color-selected: HighlightText;
    --spectrum-actionbutton-static-color-disabled: GrayText;
  }
}

.uFrYBa_i18nFontFamily {
  font-synthesis: weight;
  font-family: adobe-clean, Source Sans Pro, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Ubuntu, Trebuchet MS, Lucida Grande, sans-serif;
}

.uFrYBa_i18nFontFamily:lang(ar) {
  font-family: myriad-arabic, adobe-clean, Source Sans Pro, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Ubuntu, Trebuchet MS, Lucida Grande, sans-serif;
}

.uFrYBa_i18nFontFamily:lang(he) {
  font-family: myriad-hebrew, adobe-clean, Source Sans Pro, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Ubuntu, Trebuchet MS, Lucida Grande, sans-serif;
}

.uFrYBa_i18nFontFamily:lang(zh) {
  font-family: adobe-clean-han-traditional, source-han-traditional, MingLiu, Heiti TC Light, sans-serif;
}

.uFrYBa_i18nFontFamily:lang(zh-Hans) {
  font-family: adobe-clean-han-simplified-c, source-han-simplified-c, SimSun, Heiti SC Light, sans-serif;
}

.uFrYBa_i18nFontFamily:lang(zh-Hant) {
  font-family: adobe-clean-han-traditional, source-han-traditional, MingLiu, Microsoft JhengHei UI, Microsoft JhengHei, Heiti TC Light, sans-serif;
}

.uFrYBa_i18nFontFamily:lang(zh-SG), .uFrYBa_i18nFontFamily:lang(zh-CN) {
  font-family: adobe-clean-han-simplified-c, source-han-simplified-c, SimSun, Heiti SC Light, sans-serif;
}

.uFrYBa_i18nFontFamily:lang(ko) {
  font-family: adobe-clean-han-korean, source-han-korean, Malgun Gothic, Apple Gothic, sans-serif;
}

.uFrYBa_i18nFontFamily:lang(ja) {
  font-family: adobe-clean-han-japanese, Hiragino Kaku Gothic ProN, ヒラギノ角ゴ ProN W3, Osaka, YuGothic, Yu Gothic, メイリオ, Meiryo, ＭＳ Ｐゴシック, MS PGothic, sans-serif;
}

.uFrYBa_spectrum-FocusRing-ring {
  --spectrum-focus-ring-border-radius: var(--spectrum-textfield-border-radius, var(--spectrum-alias-border-radius-regular));
  --spectrum-focus-ring-gap: var(--spectrum-alias-input-focusring-gap);
  --spectrum-focus-ring-size: var(--spectrum-alias-input-focusring-size);
  --spectrum-focus-ring-border-size: 0px;
  --spectrum-focus-ring-color: var(--spectrum-high-contrast-focus-ring-color, var(--spectrum-alias-focus-ring-color, var(--spectrum-alias-focus-color)));
}

.uFrYBa_spectrum-FocusRing-ring:after {
  border-radius: calc(var(--spectrum-focus-ring-border-radius)  + var(--spectrum-focus-ring-gap));
  content: "";
  margin: calc(-1 * var(--spectrum-focus-ring-border-size));
  pointer-events: none;
  transition: box-shadow var(--spectrum-global-animation-duration-100, .13s) ease-out, margin var(--spectrum-global-animation-duration-100, .13s) ease-out;
  display: block;
  position: absolute;
  inset: 0;
}

.uFrYBa_spectrum-FocusRing.uFrYBa_focus-ring:after {
  margin: calc(var(--spectrum-focus-ring-gap) * -1 - var(--spectrum-focus-ring-border-size));
  box-shadow: 0 0 0 var(--spectrum-focus-ring-size) var(--spectrum-focus-ring-color);
}

.uFrYBa_spectrum-FocusRing--quiet:after {
  border-radius: 0;
}

.uFrYBa_spectrum-FocusRing--quiet.uFrYBa_focus-ring:after {
  margin: 0 0 calc(var(--spectrum-focus-ring-gap) * -1 - var(--spectrum-focus-ring-border-size)) 0;
  box-shadow: 0 var(--spectrum-focus-ring-size) 0 var(--spectrum-focus-ring-color);
}

.uFrYBa_spectrum-BarLoader {
  min-inline-size: var(--spectrum-global-dimension-static-size-600, 48px);
  inline-size: var(--spectrum-barloader-large-width, var(--spectrum-global-dimension-size-2400));
  vertical-align: top;
  isolation: isolate;
  flex-flow: wrap;
  justify-content: space-between;
  align-items: center;
  display: inline-flex;
  position: relative;
}

.uFrYBa_spectrum-BarLoader.uFrYBa_spectrum-BarLoader--indeterminate {
  max-inline-size: var(--spectrum-barloader-large-width, var(--spectrum-global-dimension-size-2400));
}

.uFrYBa_spectrum-BarLoader.uFrYBa_spectrum-BarLoader--indeterminate.uFrYBa_spectrum-BarLoader--sideLabel .uFrYBa_spectrum-BarLoader-track {
  max-inline-size: var(--spectrum-barloader-large-width, var(--spectrum-global-dimension-size-2400));
  inline-size: 100%;
}

.uFrYBa_spectrum-BarLoader-track {
  min-inline-size: var(--spectrum-global-dimension-static-size-600, 48px);
  height: var(--spectrum-barloader-large-height, var(--spectrum-global-dimension-size-75));
  border-radius: var(--spectrum-barloader-large-border-radius);
  z-index: 1;
  inline-size: 100%;
  overflow: hidden;
}

.uFrYBa_spectrum-BarLoader-fill {
  height: var(--spectrum-barloader-large-height, var(--spectrum-global-dimension-size-75));
  border: none;
  transition: width 1s;
}

.uFrYBa_spectrum-BarLoader-label, .uFrYBa_spectrum-BarLoader-percentage {
  font-size: var(--spectrum-fieldlabel-text-size, var(--spectrum-global-dimension-font-size-75));
  font-weight: var(--spectrum-fieldlabel-text-font-weight, var(--spectrum-global-font-weight-regular));
  line-height: var(--spectrum-fieldlabel-text-line-height, var(--spectrum-global-font-line-height-small));
  text-align: start;
  margin-bottom: var(--spectrum-barloader-large-label-gap-y, var(--spectrum-global-dimension-size-115));
}

.uFrYBa_spectrum-BarLoader-label {
  flex: 1;
}

.uFrYBa_spectrum-BarLoader-percentage {
  align-self: flex-start;
}

.uFrYBa_spectrum-BarLoader-percentage:not(:-webkit-any(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi))) {
  margin-left: var(--spectrum-barloader-small-label-gap-x, var(--spectrum-global-dimension-size-150));
}

.uFrYBa_spectrum-BarLoader-percentage:not(:is(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi))) {
  margin-left: var(--spectrum-barloader-small-label-gap-x, var(--spectrum-global-dimension-size-150));
}

.uFrYBa_spectrum-BarLoader-percentage:is(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi)) {
  margin-right: var(--spectrum-barloader-small-label-gap-x, var(--spectrum-global-dimension-size-150));
}

.uFrYBa_spectrum-BarLoader--sideLabel {
  flex-flow: row;
  justify-content: space-between;
  min-inline-size: -moz-fit-content;
  min-inline-size: fit-content;
  display: inline-flex;
}

.uFrYBa_spectrum-BarLoader--sideLabel .uFrYBa_spectrum-BarLoader-track {
  inline-size: inherit;
}

.uFrYBa_spectrum-BarLoader--sideLabel .uFrYBa_spectrum-BarLoader-label {
  margin-bottom: 0;
}

.uFrYBa_spectrum-BarLoader--sideLabel .uFrYBa_spectrum-BarLoader-label:not(:-webkit-any(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi))) {
  margin-right: var(--spectrum-barloader-large-label-gap-x, var(--spectrum-global-dimension-size-150));
}

.uFrYBa_spectrum-BarLoader--sideLabel .uFrYBa_spectrum-BarLoader-label:not(:is(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi))) {
  margin-right: var(--spectrum-barloader-large-label-gap-x, var(--spectrum-global-dimension-size-150));
}

.uFrYBa_spectrum-BarLoader--sideLabel .uFrYBa_spectrum-BarLoader-label:is(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi)) {
  margin-left: var(--spectrum-barloader-large-label-gap-x, var(--spectrum-global-dimension-size-150));
}

.uFrYBa_spectrum-BarLoader--sideLabel .uFrYBa_spectrum-BarLoader-percentage {
  text-align: end;
  order: 3;
  margin-bottom: 0;
}

.uFrYBa_spectrum-BarLoader--sideLabel .uFrYBa_spectrum-BarLoader-percentage:not(:-webkit-any(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi))) {
  margin-left: var(--spectrum-barloader-large-label-gap-x, var(--spectrum-global-dimension-size-150));
}

.uFrYBa_spectrum-BarLoader--sideLabel .uFrYBa_spectrum-BarLoader-percentage:not(:is(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi))) {
  margin-left: var(--spectrum-barloader-large-label-gap-x, var(--spectrum-global-dimension-size-150));
}

.uFrYBa_spectrum-BarLoader--sideLabel .uFrYBa_spectrum-BarLoader-percentage:is(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi)) {
  margin-right: var(--spectrum-barloader-large-label-gap-x, var(--spectrum-global-dimension-size-150));
}

.uFrYBa_spectrum-BarLoader--small {
  min-inline-size: var(--spectrum-global-dimension-static-size-600, 48px);
}

.uFrYBa_spectrum-BarLoader--small .uFrYBa_spectrum-BarLoader-fill {
  height: var(--spectrum-barloader-small-height, var(--spectrum-global-dimension-size-50));
}

.uFrYBa_spectrum-BarLoader--small .uFrYBa_spectrum-BarLoader-track {
  height: var(--spectrum-barloader-small-height, var(--spectrum-global-dimension-size-50));
  border-radius: var(--spectrum-barloader-small-border-radius);
}

.uFrYBa_spectrum-BarLoader--indeterminate .uFrYBa_spectrum-BarLoader-fill {
  inline-size: var(--spectrum-barloader-large-indeterminate-fill-width, var(--spectrum-global-dimension-size-1700));
  animation-duration: var(--spectrum-barloader-large-indeterminate-duration, var(--spectrum-global-animation-duration-2000));
  animation-iteration-count: infinite;
  animation-timing-function: var(--spectrum-barloader-large-indeterminate-animation-ease, var(--spectrum-global-animation-ease-in-out));
  will-change: transform;
  position: relative;
}

[dir="ltr"] :is(.uFrYBa_spectrum-BarLoader--indeterminate .uFrYBa_spectrum-BarLoader-fill) {
  animation-name: uFrYBa_indeterminate-loop-ltr;
}

[dir="rtl"] :is(.uFrYBa_spectrum-BarLoader--indeterminate .uFrYBa_spectrum-BarLoader-fill) {
  animation-name: uFrYBa_indeterminate-loop-rtl;
}

@keyframes uFrYBa_indeterminate-loop-ltr {
  0% {
    transform: translate(calc(var(--spectrum-barloader-large-indeterminate-fill-width, var(--spectrum-global-dimension-size-1700)) * -1));
  }

  to {
    transform: translate(var(--spectrum-barloader-large-width, var(--spectrum-global-dimension-size-2400)));
  }
}

@keyframes uFrYBa_indeterminate-loop-rtl {
  0% {
    transform: translate(var(--spectrum-barloader-large-width, var(--spectrum-global-dimension-size-2400)));
  }

  to {
    transform: translate(calc(var(--spectrum-barloader-large-width, var(--spectrum-global-dimension-size-2400)) * -1));
  }
}

.uFrYBa_spectrum-BarLoader {
  --spectrum-barloader-large-track-fill-color: var(--spectrum-accent-color-900);
  --spectrum-barloader-static-black-track-color: #00000040;
  --spectrum-barloader-static-black-fill-color: var(--spectrum-global-color-static-black, #000);
}

.uFrYBa_spectrum-BarLoader .uFrYBa_spectrum-BarLoader-fill {
  background: var(--spectrum-barloader-large-track-fill-color, var(--spectrum-global-color-blue-500));
}

.uFrYBa_spectrum-BarLoader .uFrYBa_spectrum-BarLoader-track {
  background-color: var(--spectrum-barloader-large-track-color, var(--spectrum-alias-track-color-default));
}

.uFrYBa_spectrum-BarLoader.uFrYBa_spectrum-BarLoader--overBackground .uFrYBa_spectrum-BarLoader-fill {
  background: var(--spectrum-barloader-large-over-background-track-fill-color, var(--spectrum-global-color-static-white));
}

.uFrYBa_spectrum-BarLoader.uFrYBa_spectrum-BarLoader--overBackground .uFrYBa_spectrum-BarLoader-label, .uFrYBa_spectrum-BarLoader.uFrYBa_spectrum-BarLoader--overBackground .uFrYBa_spectrum-BarLoader-percentage {
  color: var(--spectrum-barloader-large-over-background-track-fill-color, var(--spectrum-global-color-static-white));
}

.uFrYBa_spectrum-BarLoader.uFrYBa_spectrum-BarLoader--overBackground .uFrYBa_spectrum-BarLoader-track {
  background-color: var(--spectrum-barloader-large-over-background-track-color, var(--spectrum-alias-track-color-over-background));
}

.uFrYBa_spectrum-BarLoader.uFrYBa_spectrum-BarLoader--staticWhite .uFrYBa_spectrum-BarLoader-fill {
  background: var(--spectrum-barloader-large-over-background-track-fill-color, var(--spectrum-global-color-static-white));
}

.uFrYBa_spectrum-BarLoader.uFrYBa_spectrum-BarLoader--staticWhite .uFrYBa_spectrum-BarLoader-label, .uFrYBa_spectrum-BarLoader.uFrYBa_spectrum-BarLoader--staticWhite .uFrYBa_spectrum-BarLoader-percentage {
  color: var(--spectrum-barloader-large-over-background-track-fill-color, var(--spectrum-global-color-static-white));
}

.uFrYBa_spectrum-BarLoader.uFrYBa_spectrum-BarLoader--staticWhite .uFrYBa_spectrum-BarLoader-track {
  background-color: var(--spectrum-barloader-large-over-background-track-color, var(--spectrum-alias-track-color-over-background));
}

:is() .uFrYBa_spectrum-BarLoader-fill {
  background: var(--spectrum-barloader-large-over-background-track-fill-color, var(--spectrum-global-color-static-white));
}

:is() .uFrYBa_spectrum-BarLoader-label {
  color: var(--spectrum-barloader-large-over-background-track-fill-color, var(--spectrum-global-color-static-white));
}

:is() .uFrYBa_spectrum-BarLoader-percentage {
  color: var(--spectrum-barloader-large-over-background-track-fill-color, var(--spectrum-global-color-static-white));
}

:is() .uFrYBa_spectrum-BarLoader-track {
  background-color: var(--spectrum-barloader-large-over-background-track-color, var(--spectrum-alias-track-color-over-background));
}

.uFrYBa_spectrum-BarLoader.uFrYBa_spectrum-BarLoader--staticBlack .uFrYBa_spectrum-BarLoader-track {
  background-color: var(--spectrum-barloader-static-black-track-color);
}

.uFrYBa_spectrum-BarLoader.uFrYBa_spectrum-BarLoader--staticBlack .uFrYBa_spectrum-BarLoader-label, .uFrYBa_spectrum-BarLoader.uFrYBa_spectrum-BarLoader--staticBlack .uFrYBa_spectrum-BarLoader-percentage {
  color: var(--spectrum-barloader-static-black-fill-color);
}

.uFrYBa_spectrum-BarLoader.uFrYBa_spectrum-BarLoader--staticBlack .uFrYBa_spectrum-BarLoader-fill {
  background: var(--spectrum-barloader-static-black-fill-color);
}

.uFrYBa_spectrum-BarLoader.uFrYBa_is-positive .uFrYBa_spectrum-BarLoader-fill {
  background: var(--spectrum-meter-large-track-color-positive, var(--spectrum-semantic-positive-color-status));
}

.uFrYBa_spectrum-BarLoader.uFrYBa_is-warning .uFrYBa_spectrum-BarLoader-fill {
  background: var(--spectrum-meter-large-track-color-warning, var(--spectrum-semantic-notice-color-status));
}

.uFrYBa_spectrum-BarLoader.uFrYBa_is-critical .uFrYBa_spectrum-BarLoader-fill {
  background: var(--spectrum-meter-large-track-color-critical, var(--spectrum-semantic-negative-color-status));
}

.uFrYBa_spectrum-BarLoader-label, .uFrYBa_spectrum-BarLoader-percentage {
  color: var(--spectrum-fieldlabel-text-color, var(--spectrum-alias-label-text-color));
}

@media (forced-colors: active) {
  .uFrYBa_spectrum-BarLoader-track {
    forced-color-adjust: none;
    --spectrum-barloader-large-track-fill-color: ButtonText;
    --spectrum-barloader-large-over-background-track-fill-color: ButtonText;
    --spectrum-barloader-large-over-background-track-color: ButtonFace;
    --spectrum-barloader-static-black-track-color: ButtonFace;
    --spectrum-barloader-static-black-fill-color: ButtonText;
    --spectrum-meter-large-track-color-positive: ButtonText;
    --spectrum-meter-large-track-color-warning: ButtonText;
    --spectrum-meter-large-track-color-critical: ButtonText;
    --spectrum-barloader-large-track-color: ButtonFace;
    border: 1px solid ButtonText;
  }
}

.EQYv7q_i18nFontFamily {
  font-synthesis: weight;
  font-family: adobe-clean, Source Sans Pro, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Ubuntu, Trebuchet MS, Lucida Grande, sans-serif;
}

.EQYv7q_i18nFontFamily:lang(ar) {
  font-family: myriad-arabic, adobe-clean, Source Sans Pro, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Ubuntu, Trebuchet MS, Lucida Grande, sans-serif;
}

.EQYv7q_i18nFontFamily:lang(he) {
  font-family: myriad-hebrew, adobe-clean, Source Sans Pro, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Ubuntu, Trebuchet MS, Lucida Grande, sans-serif;
}

.EQYv7q_i18nFontFamily:lang(zh) {
  font-family: adobe-clean-han-traditional, source-han-traditional, MingLiu, Heiti TC Light, sans-serif;
}

.EQYv7q_i18nFontFamily:lang(zh-Hans) {
  font-family: adobe-clean-han-simplified-c, source-han-simplified-c, SimSun, Heiti SC Light, sans-serif;
}

.EQYv7q_i18nFontFamily:lang(zh-Hant) {
  font-family: adobe-clean-han-traditional, source-han-traditional, MingLiu, Microsoft JhengHei UI, Microsoft JhengHei, Heiti TC Light, sans-serif;
}

.EQYv7q_i18nFontFamily:lang(zh-SG), .EQYv7q_i18nFontFamily:lang(zh-CN) {
  font-family: adobe-clean-han-simplified-c, source-han-simplified-c, SimSun, Heiti SC Light, sans-serif;
}

.EQYv7q_i18nFontFamily:lang(ko) {
  font-family: adobe-clean-han-korean, source-han-korean, Malgun Gothic, Apple Gothic, sans-serif;
}

.EQYv7q_i18nFontFamily:lang(ja) {
  font-family: adobe-clean-han-japanese, Hiragino Kaku Gothic ProN, ヒラギノ角ゴ ProN W3, Osaka, YuGothic, Yu Gothic, メイリオ, Meiryo, ＭＳ Ｐゴシック, MS PGothic, sans-serif;
}

.EQYv7q_spectrum-FocusRing-ring {
  --spectrum-focus-ring-border-radius: var(--spectrum-textfield-border-radius, var(--spectrum-alias-border-radius-regular));
  --spectrum-focus-ring-gap: var(--spectrum-alias-input-focusring-gap);
  --spectrum-focus-ring-size: var(--spectrum-alias-input-focusring-size);
  --spectrum-focus-ring-border-size: 0px;
  --spectrum-focus-ring-color: var(--spectrum-high-contrast-focus-ring-color, var(--spectrum-alias-focus-ring-color, var(--spectrum-alias-focus-color)));
}

.EQYv7q_spectrum-FocusRing-ring:after {
  border-radius: calc(var(--spectrum-focus-ring-border-radius)  + var(--spectrum-focus-ring-gap));
  content: "";
  margin: calc(-1 * var(--spectrum-focus-ring-border-size));
  pointer-events: none;
  transition: box-shadow var(--spectrum-global-animation-duration-100, .13s) ease-out, margin var(--spectrum-global-animation-duration-100, .13s) ease-out;
  display: block;
  position: absolute;
  inset: 0;
}

.EQYv7q_spectrum-FocusRing.EQYv7q_focus-ring:after {
  margin: calc(var(--spectrum-focus-ring-gap) * -1 - var(--spectrum-focus-ring-border-size));
  box-shadow: 0 0 0 var(--spectrum-focus-ring-size) var(--spectrum-focus-ring-color);
}

.EQYv7q_spectrum-FocusRing--quiet:after {
  border-radius: 0;
}

.EQYv7q_spectrum-FocusRing--quiet.EQYv7q_focus-ring:after {
  margin: 0 0 calc(var(--spectrum-focus-ring-gap) * -1 - var(--spectrum-focus-ring-border-size)) 0;
  box-shadow: 0 var(--spectrum-focus-ring-size) 0 var(--spectrum-focus-ring-color);
}

.EQYv7q_spectrum-CircleLoader--indeterminate-fill-submask-2 {
  animation: 1s linear infinite EQYv7q_spectrum-fill-mask-2;
}

@keyframes EQYv7q_spectrum-fill-mask-1 {
  0% {
    transform: rotate(90deg);
  }

  1.69% {
    transform: rotate(72.3deg);
  }

  3.39% {
    transform: rotate(55.5deg);
  }

  5.08% {
    transform: rotate(40.3deg);
  }

  6.78% {
    transform: rotate(25deg);
  }

  8.47% {
    transform: rotate(10.6deg);
  }

  10.17% {
    transform: rotate(0);
  }

  11.86% {
    transform: rotate(0);
  }

  13.56% {
    transform: rotate(0);
  }

  15.25% {
    transform: rotate(0);
  }

  16.95% {
    transform: rotate(0);
  }

  18.64% {
    transform: rotate(0);
  }

  20.34% {
    transform: rotate(0);
  }

  22.03% {
    transform: rotate(0);
  }

  23.73% {
    transform: rotate(0);
  }

  25.42% {
    transform: rotate(0);
  }

  27.12% {
    transform: rotate(0);
  }

  28.81% {
    transform: rotate(0);
  }

  30.51% {
    transform: rotate(0);
  }

  32.2% {
    transform: rotate(0);
  }

  33.9% {
    transform: rotate(0);
  }

  35.59% {
    transform: rotate(0);
  }

  37.29% {
    transform: rotate(0);
  }

  38.98% {
    transform: rotate(0);
  }

  40.68% {
    transform: rotate(0);
  }

  42.37% {
    transform: rotate(5.3deg);
  }

  44.07% {
    transform: rotate(13.4deg);
  }

  45.76% {
    transform: rotate(20.6deg);
  }

  47.46% {
    transform: rotate(29deg);
  }

  49.15% {
    transform: rotate(36.5deg);
  }

  50.85% {
    transform: rotate(42.6deg);
  }

  52.54% {
    transform: rotate(48.8deg);
  }

  54.24% {
    transform: rotate(54.2deg);
  }

  55.93% {
    transform: rotate(59.4deg);
  }

  57.63% {
    transform: rotate(63.2deg);
  }

  59.32% {
    transform: rotate(67.2deg);
  }

  61.02% {
    transform: rotate(70.8deg);
  }

  62.71% {
    transform: rotate(73.8deg);
  }

  64.41% {
    transform: rotate(76.2deg);
  }

  66.1% {
    transform: rotate(78.7deg);
  }

  67.8% {
    transform: rotate(80.6deg);
  }

  69.49% {
    transform: rotate(82.6deg);
  }

  71.19% {
    transform: rotate(83.7deg);
  }

  72.88% {
    transform: rotate(85deg);
  }

  74.58% {
    transform: rotate(86.3deg);
  }

  76.27% {
    transform: rotate(87deg);
  }

  77.97% {
    transform: rotate(87.7deg);
  }

  79.66% {
    transform: rotate(88.3deg);
  }

  81.36% {
    transform: rotate(88.6deg);
  }

  83.05% {
    transform: rotate(89.2deg);
  }

  84.75% {
    transform: rotate(89.2deg);
  }

  86.44% {
    transform: rotate(89.5deg);
  }

  88.14% {
    transform: rotate(89.9deg);
  }

  89.83% {
    transform: rotate(89.7deg);
  }

  91.53% {
    transform: rotate(90.1deg);
  }

  93.22% {
    transform: rotate(90.2deg);
  }

  94.92% {
    transform: rotate(90.1deg);
  }

  96.61% {
    transform: rotate(90deg);
  }

  98.31% {
    transform: rotate(89.8deg);
  }

  to {
    transform: rotate(90deg);
  }
}

@keyframes EQYv7q_spectrum-fill-mask-2 {
  0% {
    transform: rotate(180deg);
  }

  1.69% {
    transform: rotate(180deg);
  }

  3.39% {
    transform: rotate(180deg);
  }

  5.08% {
    transform: rotate(180deg);
  }

  6.78% {
    transform: rotate(180deg);
  }

  8.47% {
    transform: rotate(180deg);
  }

  10.17% {
    transform: rotate(179.2deg);
  }

  11.86% {
    transform: rotate(164deg);
  }

  13.56% {
    transform: rotate(151.8deg);
  }

  15.25% {
    transform: rotate(140.8deg);
  }

  16.95% {
    transform: rotate(130.3deg);
  }

  18.64% {
    transform: rotate(120.4deg);
  }

  20.34% {
    transform: rotate(110.8deg);
  }

  22.03% {
    transform: rotate(101.6deg);
  }

  23.73% {
    transform: rotate(93.5deg);
  }

  25.42% {
    transform: rotate(85.4deg);
  }

  27.12% {
    transform: rotate(78.1deg);
  }

  28.81% {
    transform: rotate(71.2deg);
  }

  30.51% {
    transform: rotate(89.1deg);
  }

  32.2% {
    transform: rotate(105.5deg);
  }

  33.9% {
    transform: rotate(121.3deg);
  }

  35.59% {
    transform: rotate(135.5deg);
  }

  37.29% {
    transform: rotate(148.4deg);
  }

  38.98% {
    transform: rotate(161deg);
  }

  40.68% {
    transform: rotate(173.5deg);
  }

  42.37% {
    transform: rotate(180deg);
  }

  44.07% {
    transform: rotate(180deg);
  }

  45.76% {
    transform: rotate(180deg);
  }

  47.46% {
    transform: rotate(180deg);
  }

  49.15% {
    transform: rotate(180deg);
  }

  50.85% {
    transform: rotate(180deg);
  }

  52.54% {
    transform: rotate(180deg);
  }

  54.24% {
    transform: rotate(180deg);
  }

  55.93% {
    transform: rotate(180deg);
  }

  57.63% {
    transform: rotate(180deg);
  }

  59.32% {
    transform: rotate(180deg);
  }

  61.02% {
    transform: rotate(180deg);
  }

  62.71% {
    transform: rotate(180deg);
  }

  64.41% {
    transform: rotate(180deg);
  }

  66.1% {
    transform: rotate(180deg);
  }

  67.8% {
    transform: rotate(180deg);
  }

  69.49% {
    transform: rotate(180deg);
  }

  71.19% {
    transform: rotate(180deg);
  }

  72.88% {
    transform: rotate(180deg);
  }

  74.58% {
    transform: rotate(180deg);
  }

  76.27% {
    transform: rotate(180deg);
  }

  77.97% {
    transform: rotate(180deg);
  }

  79.66% {
    transform: rotate(180deg);
  }

  81.36% {
    transform: rotate(180deg);
  }

  83.05% {
    transform: rotate(180deg);
  }

  84.75% {
    transform: rotate(180deg);
  }

  86.44% {
    transform: rotate(180deg);
  }

  88.14% {
    transform: rotate(180deg);
  }

  89.83% {
    transform: rotate(180deg);
  }

  91.53% {
    transform: rotate(180deg);
  }

  93.22% {
    transform: rotate(180deg);
  }

  94.92% {
    transform: rotate(180deg);
  }

  96.61% {
    transform: rotate(180deg);
  }

  98.31% {
    transform: rotate(180deg);
  }

  to {
    transform: rotate(180deg);
  }
}

@keyframes EQYv7q_spectrum-fills-rotate {
  0% {
    transform: rotate(-90deg);
  }

  to {
    transform: rotate(270deg);
  }
}

.EQYv7q_spectrum-CircleLoader {
  width: var(--spectrum-loader-circle-medium-width, var(--spectrum-global-dimension-static-size-400));
  height: var(--spectrum-loader-circle-medium-height, var(--spectrum-global-dimension-static-size-400));
  direction: ltr;
  display: inline-block;
  position: relative;
}

.EQYv7q_spectrum-CircleLoader-track {
  box-sizing: border-box;
  width: calc(var(--spectrum-loader-circle-medium-width, var(--spectrum-global-dimension-static-size-400)));
  height: calc(var(--spectrum-loader-circle-medium-height, var(--spectrum-global-dimension-static-size-400)));
  border-style: solid;
  border-width: var(--spectrum-loader-circle-medium-border-size);
  border-radius: var(--spectrum-loader-circle-medium-width, var(--spectrum-global-dimension-static-size-400));
}

.EQYv7q_spectrum-CircleLoader-fills {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.EQYv7q_spectrum-CircleLoader-fill {
  box-sizing: border-box;
  width: calc(var(--spectrum-loader-circle-medium-width, var(--spectrum-global-dimension-static-size-400)));
  height: calc(var(--spectrum-loader-circle-medium-height, var(--spectrum-global-dimension-static-size-400)));
  border-style: solid;
  border-width: var(--spectrum-loader-circle-medium-border-size);
  border-radius: var(--spectrum-loader-circle-medium-width, var(--spectrum-global-dimension-static-size-400));
}

.EQYv7q_spectrum-CircleLoader-fillMask1, .EQYv7q_spectrum-CircleLoader-fillMask2 {
  transform-origin: 100%;
  width: 50%;
  height: 100%;
  position: absolute;
  overflow: hidden;
  transform: rotate(180deg);
}

.EQYv7q_spectrum-CircleLoader-fillSubMask1, .EQYv7q_spectrum-CircleLoader-fillSubMask2 {
  transform-origin: 100%;
  width: 100%;
  height: 100%;
  overflow: hidden;
  transform: rotate(-180deg);
}

.EQYv7q_spectrum-CircleLoader-fillMask2 {
  transform: rotate(0);
}

.EQYv7q_spectrum-CircleLoader--small {
  width: var(--spectrum-loader-circle-small-width, var(--spectrum-global-dimension-static-size-200));
  height: var(--spectrum-loader-circle-small-height, var(--spectrum-global-dimension-static-size-200));
}

.EQYv7q_spectrum-CircleLoader--small .EQYv7q_spectrum-CircleLoader-track, .EQYv7q_spectrum-CircleLoader--small .EQYv7q_spectrum-CircleLoader-fill {
  width: calc(var(--spectrum-loader-circle-small-width, var(--spectrum-global-dimension-static-size-200)));
  height: calc(var(--spectrum-loader-circle-small-height, var(--spectrum-global-dimension-static-size-200)));
  border-style: solid;
  border-width: var(--spectrum-loader-circle-small-border-size);
  border-radius: var(--spectrum-loader-circle-small-width, var(--spectrum-global-dimension-static-size-200));
}

.EQYv7q_spectrum-CircleLoader--large {
  width: var(--spectrum-loader-circle-large-width, var(--spectrum-global-dimension-size-800));
  height: var(--spectrum-loader-circle-large-height, var(--spectrum-global-dimension-size-800));
}

.EQYv7q_spectrum-CircleLoader--large .EQYv7q_spectrum-CircleLoader-track, .EQYv7q_spectrum-CircleLoader--large .EQYv7q_spectrum-CircleLoader-fill {
  width: calc(var(--spectrum-loader-circle-large-width, var(--spectrum-global-dimension-size-800)));
  height: calc(var(--spectrum-loader-circle-large-height, var(--spectrum-global-dimension-size-800)));
  border-style: solid;
  border-width: var(--spectrum-loader-circle-large-border-size, var(--spectrum-global-dimension-size-50));
  border-radius: var(--spectrum-loader-circle-large-width, var(--spectrum-global-dimension-size-800));
}

.EQYv7q_spectrum-CircleLoader--indeterminate .EQYv7q_spectrum-CircleLoader-fills {
  will-change: transform;
  transform-origin: 50%;
  animation: 1s cubic-bezier(.25, .78, .48, .89) infinite EQYv7q_spectrum-fills-rotate;
  transform: translateZ(0);
}

.EQYv7q_spectrum-CircleLoader--indeterminate .EQYv7q_spectrum-CircleLoader-fillSubMask1 {
  will-change: transform;
  animation: 1s linear infinite EQYv7q_spectrum-fill-mask-1;
  transform: translateZ(0);
}

.EQYv7q_spectrum-CircleLoader--indeterminate .EQYv7q_spectrum-CircleLoader-fillSubMask2 {
  will-change: transform;
  animation: 1s linear infinite EQYv7q_spectrum-fill-mask-2;
  transform: translateZ(0);
}

.EQYv7q_spectrum-CircleLoader {
  --spectrum-loader-circle-medium-track-fill-color: var(--spectrum-accent-color-900);
  --spectrum-loader-circle-static-black-track-color: #00000040;
  --spectrum-loader-circle-static-black-fill-color: var(--spectrum-global-color-static-black, #000);
}

.EQYv7q_spectrum-CircleLoader-track {
  border-color: var(--spectrum-loader-circle-medium-track-color, var(--spectrum-alias-track-color-default));
}

.EQYv7q_spectrum-CircleLoader-fill {
  border-color: var(--spectrum-loader-circle-medium-track-fill-color, var(--spectrum-global-color-blue-500));
}

:is(.EQYv7q_spectrum-CircleLoader--overBackground, .EQYv7q_spectrum-CircleLoader--staticWhite) .EQYv7q_spectrum-CircleLoader-track {
  border-color: var(--spectrum-loader-circle-medium-over-background-track-color, var(--spectrum-alias-track-color-over-background));
}

:is(.EQYv7q_spectrum-CircleLoader--overBackground, .EQYv7q_spectrum-CircleLoader--staticWhite) .EQYv7q_spectrum-CircleLoader-fill {
  border-color: var(--spectrum-loader-circle-medium-over-background-track-fill-color, var(--spectrum-global-color-static-white));
}

.EQYv7q_spectrum-CircleLoader--staticBlack .EQYv7q_spectrum-CircleLoader-track {
  border-color: var(--spectrum-loader-circle-static-black-track-color);
}

.EQYv7q_spectrum-CircleLoader--staticBlack .EQYv7q_spectrum-CircleLoader-fill {
  border-color: var(--spectrum-loader-circle-static-black-fill-color);
}

@media (forced-colors: active) {
  .EQYv7q_spectrum-CircleLoader {
    --spectrum-loader-circle-medium-track-fill-color: Highlight;
    --spectrum-loader-circle-medium-over-background-track-fill-color: Highlight;
    --spectrum-loader-circle-static-black-fill-color: Highlight;
    --spectrum-loader-circle-medium-track-color: Background;
    --spectrum-loader-circle-medium-over-background-track-color: Background;
    --spectrum-loader-circle-static-black-track-color: Background;
  }
}

.JuTe6q_i18nFontFamily {
  font-synthesis: weight;
  font-family: adobe-clean, Source Sans Pro, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Ubuntu, Trebuchet MS, Lucida Grande, sans-serif;
}

.JuTe6q_i18nFontFamily:lang(ar) {
  font-family: myriad-arabic, adobe-clean, Source Sans Pro, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Ubuntu, Trebuchet MS, Lucida Grande, sans-serif;
}

.JuTe6q_i18nFontFamily:lang(he) {
  font-family: myriad-hebrew, adobe-clean, Source Sans Pro, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Ubuntu, Trebuchet MS, Lucida Grande, sans-serif;
}

.JuTe6q_i18nFontFamily:lang(zh) {
  font-family: adobe-clean-han-traditional, source-han-traditional, MingLiu, Heiti TC Light, sans-serif;
}

.JuTe6q_i18nFontFamily:lang(zh-Hans) {
  font-family: adobe-clean-han-simplified-c, source-han-simplified-c, SimSun, Heiti SC Light, sans-serif;
}

.JuTe6q_i18nFontFamily:lang(zh-Hant) {
  font-family: adobe-clean-han-traditional, source-han-traditional, MingLiu, Microsoft JhengHei UI, Microsoft JhengHei, Heiti TC Light, sans-serif;
}

.JuTe6q_i18nFontFamily:lang(zh-SG), .JuTe6q_i18nFontFamily:lang(zh-CN) {
  font-family: adobe-clean-han-simplified-c, source-han-simplified-c, SimSun, Heiti SC Light, sans-serif;
}

.JuTe6q_i18nFontFamily:lang(ko) {
  font-family: adobe-clean-han-korean, source-han-korean, Malgun Gothic, Apple Gothic, sans-serif;
}

.JuTe6q_i18nFontFamily:lang(ja) {
  font-family: adobe-clean-han-japanese, Hiragino Kaku Gothic ProN, ヒラギノ角ゴ ProN W3, Osaka, YuGothic, Yu Gothic, メイリオ, Meiryo, ＭＳ Ｐゴシック, MS PGothic, sans-serif;
}

.JuTe6q_spectrum-FocusRing-ring {
  --spectrum-focus-ring-border-radius: var(--spectrum-textfield-border-radius, var(--spectrum-alias-border-radius-regular));
  --spectrum-focus-ring-gap: var(--spectrum-alias-input-focusring-gap);
  --spectrum-focus-ring-size: var(--spectrum-alias-input-focusring-size);
  --spectrum-focus-ring-border-size: 0px;
  --spectrum-focus-ring-color: var(--spectrum-high-contrast-focus-ring-color, var(--spectrum-alias-focus-ring-color, var(--spectrum-alias-focus-color)));
}

.JuTe6q_spectrum-FocusRing-ring:after {
  border-radius: calc(var(--spectrum-focus-ring-border-radius)  + var(--spectrum-focus-ring-gap));
  content: "";
  margin: calc(-1 * var(--spectrum-focus-ring-border-size));
  pointer-events: none;
  transition: box-shadow var(--spectrum-global-animation-duration-100, .13s) ease-out, margin var(--spectrum-global-animation-duration-100, .13s) ease-out;
  display: block;
  position: absolute;
  inset: 0;
}

.JuTe6q_spectrum-FocusRing.JuTe6q_focus-ring:after {
  margin: calc(var(--spectrum-focus-ring-gap) * -1 - var(--spectrum-focus-ring-border-size));
  box-shadow: 0 0 0 var(--spectrum-focus-ring-size) var(--spectrum-focus-ring-color);
}

.JuTe6q_spectrum-FocusRing--quiet:after {
  border-radius: 0;
}

.JuTe6q_spectrum-FocusRing--quiet.JuTe6q_focus-ring:after {
  margin: 0 0 calc(var(--spectrum-focus-ring-gap) * -1 - var(--spectrum-focus-ring-border-size)) 0;
  box-shadow: 0 var(--spectrum-focus-ring-size) 0 var(--spectrum-focus-ring-color);
}

.JuTe6q_spectrum {
  background-color: var(--spectrum-alias-background-color-default, var(--spectrum-global-color-gray-100));
  -webkit-tap-highlight-color: #0000;
}

._5QszkG_i18nFontFamily {
  font-synthesis: weight;
  font-family: adobe-clean, Source Sans Pro, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Ubuntu, Trebuchet MS, Lucida Grande, sans-serif;
}

._5QszkG_i18nFontFamily:lang(ar) {
  font-family: myriad-arabic, adobe-clean, Source Sans Pro, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Ubuntu, Trebuchet MS, Lucida Grande, sans-serif;
}

._5QszkG_i18nFontFamily:lang(he) {
  font-family: myriad-hebrew, adobe-clean, Source Sans Pro, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Ubuntu, Trebuchet MS, Lucida Grande, sans-serif;
}

._5QszkG_i18nFontFamily:lang(zh) {
  font-family: adobe-clean-han-traditional, source-han-traditional, MingLiu, Heiti TC Light, sans-serif;
}

._5QszkG_i18nFontFamily:lang(zh-Hans) {
  font-family: adobe-clean-han-simplified-c, source-han-simplified-c, SimSun, Heiti SC Light, sans-serif;
}

._5QszkG_i18nFontFamily:lang(zh-Hant) {
  font-family: adobe-clean-han-traditional, source-han-traditional, MingLiu, Microsoft JhengHei UI, Microsoft JhengHei, Heiti TC Light, sans-serif;
}

._5QszkG_i18nFontFamily:lang(zh-SG), ._5QszkG_i18nFontFamily:lang(zh-CN) {
  font-family: adobe-clean-han-simplified-c, source-han-simplified-c, SimSun, Heiti SC Light, sans-serif;
}

._5QszkG_i18nFontFamily:lang(ko) {
  font-family: adobe-clean-han-korean, source-han-korean, Malgun Gothic, Apple Gothic, sans-serif;
}

._5QszkG_i18nFontFamily:lang(ja) {
  font-family: adobe-clean-han-japanese, Hiragino Kaku Gothic ProN, ヒラギノ角ゴ ProN W3, Osaka, YuGothic, Yu Gothic, メイリオ, Meiryo, ＭＳ Ｐゴシック, MS PGothic, sans-serif;
}

._5QszkG_spectrum-FocusRing-ring {
  --spectrum-focus-ring-border-radius: var(--spectrum-textfield-border-radius, var(--spectrum-alias-border-radius-regular));
  --spectrum-focus-ring-gap: var(--spectrum-alias-input-focusring-gap);
  --spectrum-focus-ring-size: var(--spectrum-alias-input-focusring-size);
  --spectrum-focus-ring-border-size: 0px;
  --spectrum-focus-ring-color: var(--spectrum-high-contrast-focus-ring-color, var(--spectrum-alias-focus-ring-color, var(--spectrum-alias-focus-color)));
}

._5QszkG_spectrum-FocusRing-ring:after {
  border-radius: calc(var(--spectrum-focus-ring-border-radius)  + var(--spectrum-focus-ring-gap));
  content: "";
  margin: calc(-1 * var(--spectrum-focus-ring-border-size));
  pointer-events: none;
  transition: box-shadow var(--spectrum-global-animation-duration-100, .13s) ease-out, margin var(--spectrum-global-animation-duration-100, .13s) ease-out;
  display: block;
  position: absolute;
  inset: 0;
}

._5QszkG_spectrum-FocusRing._5QszkG_focus-ring:after {
  margin: calc(var(--spectrum-focus-ring-gap) * -1 - var(--spectrum-focus-ring-border-size));
  box-shadow: 0 0 0 var(--spectrum-focus-ring-size) var(--spectrum-focus-ring-color);
}

._5QszkG_spectrum-FocusRing--quiet:after {
  border-radius: 0;
}

._5QszkG_spectrum-FocusRing--quiet._5QszkG_focus-ring:after {
  margin: 0 0 calc(var(--spectrum-focus-ring-gap) * -1 - var(--spectrum-focus-ring-border-size)) 0;
  box-shadow: 0 var(--spectrum-focus-ring-size) 0 var(--spectrum-focus-ring-color);
}

._5QszkG_spectrum {
  font-size: var(--spectrum-alias-font-size-default, var(--spectrum-global-dimension-font-size-100));
  color: var(--spectrum-body-text-color, var(--spectrum-alias-text-color));
}

._5QszkG_spectrum, ._5QszkG_spectrum-Body, ._5QszkG_spectrum, ._5QszkG_spectrum-Body {
  font-size: var(--spectrum-body-4-text-size, var(--spectrum-alias-font-size-default));
  font-weight: var(--spectrum-body-4-text-font-weight, var(--spectrum-alias-body-text-font-weight));
  line-height: var(--spectrum-body-4-text-line-height, var(--spectrum-alias-body-text-line-height));
  font-style: var(--spectrum-body-4-text-font-style, var(--spectrum-global-font-style-regular));
}

._5QszkG_spectrum-Body--italic {
  font-style: var(--spectrum-body-4-emphasis-text-font-style, var(--spectrum-global-font-style-italic));
}

.yxBNXG_spectrum-Icon, .yxBNXG_spectrum-UIIcon {
  color: inherit;
  fill: currentColor;
  pointer-events: none;
  display: inline-block;
}

:-webkit-any(.yxBNXG_spectrum-Icon, .yxBNXG_spectrum-UIIcon):not(:root) {
  overflow: hidden;
}

:is(.yxBNXG_spectrum-Icon, .yxBNXG_spectrum-UIIcon):not(:root) {
  overflow: hidden;
}

@media (forced-colors: active) {
  .yxBNXG_spectrum-Icon, .yxBNXG_spectrum-UIIcon {
    forced-color-adjust: auto;
  }
}

.yxBNXG_spectrum-Icon--sizeXXS, .yxBNXG_spectrum-Icon--sizeXXS img, .yxBNXG_spectrum-Icon--sizeXXS svg {
  block-size: calc(var(--spectrum-alias-workflow-icon-size, var(--spectrum-global-dimension-size-225)) / 2);
  inline-size: calc(var(--spectrum-alias-workflow-icon-size, var(--spectrum-global-dimension-size-225)) / 2);
}

.yxBNXG_spectrum-Icon--sizeXS, .yxBNXG_spectrum-Icon--sizeXS img, .yxBNXG_spectrum-Icon--sizeXS svg {
  block-size: calc(var(--spectrum-global-dimension-size-300) / 2);
  inline-size: calc(var(--spectrum-global-dimension-size-300) / 2);
}

.yxBNXG_spectrum-Icon--sizeS, .yxBNXG_spectrum-Icon--sizeS img, .yxBNXG_spectrum-Icon--sizeS svg {
  block-size: var(--spectrum-alias-workflow-icon-size, var(--spectrum-global-dimension-size-225));
  inline-size: var(--spectrum-alias-workflow-icon-size, var(--spectrum-global-dimension-size-225));
}

.yxBNXG_spectrum-Icon--sizeM, .yxBNXG_spectrum-Icon--sizeM img, .yxBNXG_spectrum-Icon--sizeM svg {
  block-size: var(--spectrum-global-dimension-size-300);
  inline-size: var(--spectrum-global-dimension-size-300);
}

.yxBNXG_spectrum-Icon--sizeL, .yxBNXG_spectrum-Icon--sizeL img, .yxBNXG_spectrum-Icon--sizeL svg {
  block-size: calc(var(--spectrum-alias-workflow-icon-size, var(--spectrum-global-dimension-size-225)) * 2);
  inline-size: calc(var(--spectrum-alias-workflow-icon-size, var(--spectrum-global-dimension-size-225)) * 2);
}

.yxBNXG_spectrum-Icon--sizeXL, .yxBNXG_spectrum-Icon--sizeXL img, .yxBNXG_spectrum-Icon--sizeXL svg {
  block-size: calc(var(--spectrum-global-dimension-size-300) * 2);
  inline-size: calc(var(--spectrum-global-dimension-size-300) * 2);
}

.yxBNXG_spectrum-Icon--sizeXXL, .yxBNXG_spectrum-Icon--sizeXXL img, .yxBNXG_spectrum-Icon--sizeXXL svg {
  block-size: calc(var(--spectrum-global-dimension-size-300) * 3);
  inline-size: calc(var(--spectrum-global-dimension-size-300) * 3);
}

.yxBNXG_spectrum--medium .yxBNXG_spectrum-UIIcon--large {
  display: none;
}

.yxBNXG_spectrum--medium .yxBNXG_spectrum-UIIcon--medium {
  display: inline;
}

.yxBNXG_spectrum--large .yxBNXG_spectrum-UIIcon--medium {
  display: none;
}

.yxBNXG_spectrum--large .yxBNXG_spectrum-UIIcon--large {
  display: inline;
}

.yxBNXG_spectrum--large {
  --ui-icon-large-display: block;
  --ui-icon-medium-display: none;
}

.yxBNXG_spectrum--medium {
  --ui-icon-medium-display: block;
  --ui-icon-large-display: none;
}

.yxBNXG_spectrum-UIIcon--large {
  display: var(--ui-icon-large-display);
}

.yxBNXG_spectrum-UIIcon--medium {
  display: var(--ui-icon-medium-display);
}

.yxBNXG_spectrum-UIIcon-AlertMedium {
  inline-size: var(--spectrum-icon-alert-medium-width, var(--spectrum-global-dimension-size-225));
  block-size: var(--spectrum-icon-alert-medium-height, var(--spectrum-global-dimension-size-225));
}

.yxBNXG_spectrum-UIIcon-AlertSmall {
  inline-size: var(--spectrum-icon-alert-small-width, var(--spectrum-global-dimension-size-175));
  block-size: var(--spectrum-icon-alert-small-height, var(--spectrum-global-dimension-size-175));
}

.yxBNXG_spectrum-UIIcon-ArrowDownSmall {
  inline-size: var(--spectrum-icon-arrow-down-small-width, var(--spectrum-global-dimension-size-100));
  block-size: var(--spectrum-icon-arrow-down-small-height);
}

.yxBNXG_spectrum-UIIcon-ArrowLeftMedium {
  inline-size: var(--spectrum-icon-arrow-left-medium-width, var(--spectrum-global-dimension-size-175));
  block-size: var(--spectrum-icon-arrow-left-medium-height);
}

.yxBNXG_spectrum-UIIcon-Asterisk {
  inline-size: var(--spectrum-fieldlabel-asterisk-size, var(--spectrum-global-dimension-size-100));
  block-size: var(--spectrum-fieldlabel-asterisk-size, var(--spectrum-global-dimension-size-100));
}

.yxBNXG_spectrum-UIIcon-CheckmarkMedium {
  inline-size: var(--spectrum-icon-checkmark-medium-width);
  block-size: var(--spectrum-icon-checkmark-medium-height);
}

.yxBNXG_spectrum-UIIcon-CheckmarkSmall {
  inline-size: var(--spectrum-icon-checkmark-small-width);
  block-size: var(--spectrum-icon-checkmark-small-height);
}

.yxBNXG_spectrum-UIIcon-ChevronDownMedium {
  inline-size: var(--spectrum-icon-chevron-down-medium-width);
  block-size: var(--spectrum-icon-chevron-down-medium-height, var(--spectrum-global-dimension-size-75));
}

.yxBNXG_spectrum-UIIcon-ChevronDownSmall {
  inline-size: var(--spectrum-icon-chevron-down-small-width, var(--spectrum-global-dimension-size-100));
  block-size: var(--spectrum-icon-chevron-down-small-height, var(--spectrum-global-dimension-size-75));
}

.yxBNXG_spectrum-UIIcon-ChevronLeftLarge {
  inline-size: var(--spectrum-icon-chevron-left-large-width);
  block-size: var(--spectrum-icon-chevron-left-large-height, var(--spectrum-global-dimension-size-200));
}

.yxBNXG_spectrum-UIIcon-ChevronLeftMedium {
  inline-size: var(--spectrum-icon-chevron-left-medium-width, var(--spectrum-global-dimension-size-75));
  block-size: var(--spectrum-icon-chevron-left-medium-height);
}

.yxBNXG_spectrum-UIIcon-ChevronRightLarge {
  inline-size: var(--spectrum-icon-chevron-right-large-width);
  block-size: var(--spectrum-icon-chevron-right-large-height, var(--spectrum-global-dimension-size-200));
}

.yxBNXG_spectrum-UIIcon-ChevronRightMedium {
  inline-size: var(--spectrum-icon-chevron-right-medium-width, var(--spectrum-global-dimension-size-75));
  block-size: var(--spectrum-icon-chevron-right-medium-height);
}

.yxBNXG_spectrum-UIIcon-ChevronRightSmall {
  inline-size: var(--spectrum-icon-chevron-right-small-width, var(--spectrum-global-dimension-size-75));
  block-size: var(--spectrum-icon-chevron-right-small-height, var(--spectrum-global-dimension-size-100));
}

.yxBNXG_spectrum-UIIcon-ChevronUpSmall {
  inline-size: var(--spectrum-icon-chevron-up-small-width, var(--spectrum-global-dimension-size-100));
  block-size: var(--spectrum-icon-chevron-up-small-height, var(--spectrum-global-dimension-size-75));
}

.yxBNXG_spectrum-UIIcon-CornerTriangle {
  inline-size: var(--spectrum-icon-cornertriangle-width, var(--spectrum-global-dimension-size-65));
  block-size: var(--spectrum-icon-cornertriangle-height, var(--spectrum-global-dimension-size-65));
}

.yxBNXG_spectrum-UIIcon-CrossLarge {
  inline-size: var(--spectrum-icon-cross-large-width);
  block-size: var(--spectrum-icon-cross-large-height);
}

.yxBNXG_spectrum-UIIcon-CrossMedium {
  inline-size: var(--spectrum-icon-cross-medium-width, var(--spectrum-global-dimension-size-100));
  block-size: var(--spectrum-icon-cross-medium-height, var(--spectrum-global-dimension-size-100));
}

.yxBNXG_spectrum-UIIcon-CrossSmall {
  inline-size: var(--spectrum-icon-cross-small-width, var(--spectrum-global-dimension-size-100));
  block-size: var(--spectrum-icon-cross-small-height, var(--spectrum-global-dimension-size-100));
}

.yxBNXG_spectrum-UIIcon-DashSmall {
  inline-size: var(--spectrum-icon-dash-small-width);
  block-size: var(--spectrum-icon-dash-small-height);
}

.yxBNXG_spectrum-UIIcon-DoubleGripper {
  inline-size: var(--spectrum-icon-doublegripper-width, var(--spectrum-global-dimension-size-200));
  block-size: var(--spectrum-icon-doublegripper-height, var(--spectrum-global-dimension-size-50));
}

.yxBNXG_spectrum-UIIcon-FolderBreadcrumb {
  inline-size: var(--spectrum-icon-folderbreadcrumb-width, var(--spectrum-global-dimension-size-225));
  block-size: var(--spectrum-icon-folderbreadcrumb-height, var(--spectrum-global-dimension-size-225));
}

.yxBNXG_spectrum-UIIcon-HelpMedium {
  inline-size: var(--spectrum-icon-info-medium-width, var(--spectrum-global-dimension-size-225));
  block-size: var(--spectrum-icon-info-medium-height, var(--spectrum-global-dimension-size-225));
}

.yxBNXG_spectrum-UIIcon-HelpSmall {
  inline-size: var(--spectrum-icon-info-small-width, var(--spectrum-global-dimension-size-175));
  block-size: var(--spectrum-icon-info-small-height, var(--spectrum-global-dimension-size-175));
}

.yxBNXG_spectrum-UIIcon-InfoMedium {
  inline-size: var(--spectrum-icon-info-medium-width, var(--spectrum-global-dimension-size-225));
  block-size: var(--spectrum-icon-info-medium-height, var(--spectrum-global-dimension-size-225));
}

.yxBNXG_spectrum-UIIcon-InfoSmall {
  inline-size: var(--spectrum-icon-info-small-width, var(--spectrum-global-dimension-size-175));
  block-size: var(--spectrum-icon-info-small-height, var(--spectrum-global-dimension-size-175));
}

.yxBNXG_spectrum-UIIcon-ListGripper {
  inline-size: var(--spectrum-global-dimension-size-65);
  block-size: var(--spectrum-global-dimension-size-150);
}

.yxBNXG_spectrum-UIIcon-Magnifier {
  inline-size: var(--spectrum-icon-magnifier-width, var(--spectrum-global-dimension-size-200));
  block-size: var(--spectrum-icon-magnifier-height, var(--spectrum-global-dimension-size-200));
}

.yxBNXG_spectrum-UIIcon-SkipLeft {
  inline-size: var(--spectrum-icon-skip-left-width);
  block-size: var(--spectrum-icon-skip-left-height);
}

.yxBNXG_spectrum-UIIcon-SkipRight {
  inline-size: var(--spectrum-icon-skip-right-width);
  block-size: var(--spectrum-icon-skip-right-height);
}

.yxBNXG_spectrum-UIIcon-Star {
  inline-size: var(--spectrum-icon-star-width, var(--spectrum-global-dimension-size-225));
  block-size: var(--spectrum-icon-star-height, var(--spectrum-global-dimension-size-225));
}

.yxBNXG_spectrum-UIIcon-StarOutline {
  inline-size: var(--spectrum-icon-star-outline-width, var(--spectrum-global-dimension-size-225));
  block-size: var(--spectrum-icon-star-outline-height, var(--spectrum-global-dimension-size-225));
}

.yxBNXG_spectrum-UIIcon-SuccessMedium {
  inline-size: var(--spectrum-icon-success-medium-width, var(--spectrum-global-dimension-size-225));
  block-size: var(--spectrum-icon-success-medium-height, var(--spectrum-global-dimension-size-225));
}

.yxBNXG_spectrum-UIIcon-SuccessSmall {
  inline-size: var(--spectrum-icon-success-small-width, var(--spectrum-global-dimension-size-175));
  block-size: var(--spectrum-icon-success-small-height, var(--spectrum-global-dimension-size-175));
}

.yxBNXG_spectrum-UIIcon-TripleGripper {
  inline-size: var(--spectrum-icon-triplegripper-width);
  block-size: var(--spectrum-icon-triplegripper-height, var(--spectrum-global-dimension-size-85));
}

.Q7FggG_i18nFontFamily {
  font-synthesis: weight;
  font-family: adobe-clean, Source Sans Pro, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Ubuntu, Trebuchet MS, Lucida Grande, sans-serif;
}

.Q7FggG_i18nFontFamily:lang(ar) {
  font-family: myriad-arabic, adobe-clean, Source Sans Pro, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Ubuntu, Trebuchet MS, Lucida Grande, sans-serif;
}

.Q7FggG_i18nFontFamily:lang(he) {
  font-family: myriad-hebrew, adobe-clean, Source Sans Pro, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Ubuntu, Trebuchet MS, Lucida Grande, sans-serif;
}

.Q7FggG_i18nFontFamily:lang(zh) {
  font-family: adobe-clean-han-traditional, source-han-traditional, MingLiu, Heiti TC Light, sans-serif;
}

.Q7FggG_i18nFontFamily:lang(zh-Hans) {
  font-family: adobe-clean-han-simplified-c, source-han-simplified-c, SimSun, Heiti SC Light, sans-serif;
}

.Q7FggG_i18nFontFamily:lang(zh-Hant) {
  font-family: adobe-clean-han-traditional, source-han-traditional, MingLiu, Microsoft JhengHei UI, Microsoft JhengHei, Heiti TC Light, sans-serif;
}

.Q7FggG_i18nFontFamily:lang(zh-SG), .Q7FggG_i18nFontFamily:lang(zh-CN) {
  font-family: adobe-clean-han-simplified-c, source-han-simplified-c, SimSun, Heiti SC Light, sans-serif;
}

.Q7FggG_i18nFontFamily:lang(ko) {
  font-family: adobe-clean-han-korean, source-han-korean, Malgun Gothic, Apple Gothic, sans-serif;
}

.Q7FggG_i18nFontFamily:lang(ja) {
  font-family: adobe-clean-han-japanese, Hiragino Kaku Gothic ProN, ヒラギノ角ゴ ProN W3, Osaka, YuGothic, Yu Gothic, メイリオ, Meiryo, ＭＳ Ｐゴシック, MS PGothic, sans-serif;
}

.Q7FggG_spectrum-FocusRing-ring {
  --spectrum-focus-ring-border-radius: var(--spectrum-textfield-border-radius, var(--spectrum-alias-border-radius-regular));
  --spectrum-focus-ring-gap: var(--spectrum-alias-input-focusring-gap);
  --spectrum-focus-ring-size: var(--spectrum-alias-input-focusring-size);
  --spectrum-focus-ring-border-size: 0px;
  --spectrum-focus-ring-color: var(--spectrum-high-contrast-focus-ring-color, var(--spectrum-alias-focus-ring-color, var(--spectrum-alias-focus-color)));
}

.Q7FggG_spectrum-FocusRing-ring:after {
  border-radius: calc(var(--spectrum-focus-ring-border-radius)  + var(--spectrum-focus-ring-gap));
  content: "";
  margin: calc(-1 * var(--spectrum-focus-ring-border-size));
  pointer-events: none;
  transition: box-shadow var(--spectrum-global-animation-duration-100, .13s) ease-out, margin var(--spectrum-global-animation-duration-100, .13s) ease-out;
  display: block;
  position: absolute;
  inset: 0;
}

.Q7FggG_spectrum-FocusRing.Q7FggG_focus-ring:after {
  margin: calc(var(--spectrum-focus-ring-gap) * -1 - var(--spectrum-focus-ring-border-size));
  box-shadow: 0 0 0 var(--spectrum-focus-ring-size) var(--spectrum-focus-ring-color);
}

.Q7FggG_spectrum-FocusRing--quiet:after {
  border-radius: 0;
}

.Q7FggG_spectrum-FocusRing--quiet.Q7FggG_focus-ring:after {
  margin: 0 0 calc(var(--spectrum-focus-ring-gap) * -1 - var(--spectrum-focus-ring-border-size)) 0;
  box-shadow: 0 var(--spectrum-focus-ring-size) 0 var(--spectrum-focus-ring-color);
}

.Q7FggG_spectrum-Menu-popover {
  max-width: 320px;
}

.Q7FggG_spectrum-Menu {
  text-align: start;
  box-sizing: border-box;
  padding: var(--spectrum-popover-padding-y, var(--spectrum-global-dimension-size-50)) 0;
  -webkit-user-select: none;
  user-select: none;
  margin: 0;
  list-style-type: none;
  display: block;
  overflow-y: auto;
}

.Q7FggG_spectrum-Menu .Q7FggG_spectrum-Menu-sectionHeading {
  margin-top: var(--spectrum-global-dimension-size-75);
  margin-bottom: var(--spectrum-global-dimension-size-40);
}

.Q7FggG_spectrum-Menu:focus {
  outline: none;
}

.Q7FggG_spectrum-Menu-checkmark {
  opacity: 1;
  padding-top: calc(var(--spectrum-selectlist-option-icon-padding-y)  - var(--spectrum-selectlist-option-padding-height));
  transform: scale(1);
}

.Q7FggG_spectrum-Menu-item {
  cursor: default;
  box-sizing: border-box;
  border-inline-start: var(--spectrum-selectlist-border-size-key-focus, var(--spectrum-global-dimension-static-size-25)) solid transparent;
  font-size: var(--spectrum-selectlist-option-text-size, var(--spectrum-alias-font-size-default));
  font-weight: var(--spectrum-selectlist-option-text-font-weight, var(--spectrum-global-font-weight-regular));
  margin: 0;
  font-style: normal;
  text-decoration: none;
  display: block;
  position: relative;
}

.Q7FggG_spectrum-Menu-item[href] {
  cursor: pointer;
}

.Q7FggG_spectrum-Menu-item:focus {
  outline: none;
}

.Q7FggG_spectrum-Menu-item.Q7FggG_is-disabled {
  cursor: default;
}

.Q7FggG_spectrum-Menu-item.Q7FggG_is-selected .Q7FggG_spectrum-Menu-checkmark {
  display: block;
}

.Q7FggG_spectrum-Menu-item .Q7FggG_spectrum-Icon + .Q7FggG_spectrum-Menu-itemLabel:not(:-webkit-any(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi))) {
  margin-left: var(--spectrum-selectlist-thumbnail-image-padding-x, var(--spectrum-global-dimension-size-100));
}

.Q7FggG_spectrum-Menu-item .Q7FggG_spectrum-Icon + .Q7FggG_spectrum-Menu-itemLabel:not(:is(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi))) {
  margin-left: var(--spectrum-selectlist-thumbnail-image-padding-x, var(--spectrum-global-dimension-size-100));
}

.Q7FggG_spectrum-Menu-item .Q7FggG_spectrum-Icon + .Q7FggG_spectrum-Menu-itemLabel:is(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi)) {
  margin-right: var(--spectrum-selectlist-thumbnail-image-padding-x, var(--spectrum-global-dimension-size-100));
}

.Q7FggG_spectrum-Menu-item .Q7FggG_spectrum-Menu-itemIcon + .Q7FggG_spectrum-Menu-itemLabel:not(:-webkit-any(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi))) {
  margin-left: var(--spectrum-selectlist-thumbnail-image-padding-x, var(--spectrum-global-dimension-size-100));
}

.Q7FggG_spectrum-Menu-item .Q7FggG_spectrum-Menu-itemIcon + .Q7FggG_spectrum-Menu-itemLabel:not(:is(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi))) {
  margin-left: var(--spectrum-selectlist-thumbnail-image-padding-x, var(--spectrum-global-dimension-size-100));
}

.Q7FggG_spectrum-Menu-item .Q7FggG_spectrum-Menu-itemIcon + .Q7FggG_spectrum-Menu-itemLabel:is(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi)) {
  margin-right: var(--spectrum-selectlist-thumbnail-image-padding-x, var(--spectrum-global-dimension-size-100));
}

.Q7FggG_spectrum-Menu-itemLabel {
  line-height: var(--spectrum-global-font-line-height-small, 1.3);
  word-break: break-word;
  grid-area: Q7FggG_text;
}

.Q7FggG_spectrum-Menu-itemLabel--wrapping {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.Q7FggG_spectrum-Menu-checkmark {
  grid-area: Q7FggG_checkmark;
  place-self: flex-start end;
  display: none;
}

.Q7FggG_spectrum-Menu-divider {
  box-sizing: content-box;
  block-size: var(--spectrum-selectlist-divider-size, var(--spectrum-alias-border-size-thick));
  margin: calc(var(--spectrum-selectlist-divider-padding, 3px) / 2) var(--spectrum-global-dimension-size-150);
  border: none;
  padding: 0;
  overflow: visible;
}

.Q7FggG_spectrum-Menu-sectionHeading {
  margin: var(--spectrum-global-dimension-size-75) 0 0 0;
  font-size: var(--spectrum-global-dimension-font-size-100);
  font-weight: var(--spectrum-global-font-weight-bold, 700);
  line-height: var(--spectrum-alias-line-height-medium, var(--spectrum-global-dimension-size-250));
  padding-top: 0;
  padding-bottom: 0;
  display: block;
}

.Q7FggG_spectrum-Menu-sectionHeading:not(:-webkit-any(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi))) {
  padding-left: var(--spectrum-global-dimension-size-150);
  padding-right: var(--spectrum-global-dimension-size-450);
}

.Q7FggG_spectrum-Menu-sectionHeading:not(:is(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi))) {
  padding-left: var(--spectrum-global-dimension-size-150);
  padding-right: var(--spectrum-global-dimension-size-450);
}

.Q7FggG_spectrum-Menu-sectionHeading:is(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi)) {
  padding-right: var(--spectrum-global-dimension-size-150);
  padding-left: var(--spectrum-global-dimension-size-450);
}

.Q7FggG_spectrum-Menu .Q7FggG_spectrum-Menu {
  margin: 0;
  display: block;
}

.Q7FggG_spectrum-Menu-itemGrid {
  grid-template-columns: calc(var(--spectrum-global-dimension-size-150)  - var(--spectrum-selectlist-border-size-key-focus, var(--spectrum-global-dimension-static-size-25))) auto 1fr auto auto auto var(--spectrum-global-dimension-size-150);
  grid-template-rows: var(--spectrum-selectlist-option-padding-height) 1fr auto var(--spectrum-selectlist-option-padding-height);
  grid-template-areas: ". . . . . . ."
                       ". Q7FggG_icon Q7FggG_text Q7FggG_checkmark Q7FggG_end Q7FggG_keyboard ."
                       ". Q7FggG_icon Q7FggG_description Q7FggG_checkmark Q7FggG_end Q7FggG_keyboard ."
                       ". . . . . . .";
  display: grid;
}

.Q7FggG_spectrum-Menu-item.Q7FggG_is-selectable .Q7FggG_spectrum-Menu-itemGrid {
  grid-template-columns: calc(var(--spectrum-global-dimension-size-150)  - var(--spectrum-selectlist-border-size-key-focus, var(--spectrum-global-dimension-static-size-25))) auto 1fr calc(var(--spectrum-icon-checkmark-medium-width)  + var(--spectrum-selectlist-option-icon-padding-x, var(--spectrum-global-dimension-size-150))) auto auto var(--spectrum-global-dimension-size-150);
}

.Q7FggG_spectrum-Menu .Q7FggG_spectrum-Menu-end {
  grid-area: Q7FggG_end;
  place-self: flex-start end;
}

.Q7FggG_spectrum-Menu .Q7FggG_spectrum-Menu-end:not(:-webkit-any(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi))) {
  padding-left: var(--spectrum-global-dimension-size-250);
}

.Q7FggG_spectrum-Menu .Q7FggG_spectrum-Menu-end:not(:is(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi))) {
  padding-left: var(--spectrum-global-dimension-size-250);
}

.Q7FggG_spectrum-Menu .Q7FggG_spectrum-Menu-end:is(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi)) {
  padding-right: var(--spectrum-global-dimension-size-250);
}

.Q7FggG_spectrum-Menu-icon {
  padding-top: var(--spectrum-global-dimension-size-10);
  grid-area: Q7FggG_icon;
}

.Q7FggG_spectrum-Menu-icon:not(:-webkit-any(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi))) {
  padding-right: var(--spectrum-global-dimension-size-100);
}

.Q7FggG_spectrum-Menu-icon:not(:is(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi))) {
  padding-right: var(--spectrum-global-dimension-size-100);
}

.Q7FggG_spectrum-Menu-icon:is(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi)) {
  padding-left: var(--spectrum-global-dimension-size-100);
}

.Q7FggG_spectrum-Menu-description {
  line-height: var(--spectrum-global-font-line-height-small, 1.3);
  font-size: var(--spectrum-global-dimension-size-150);
  grid-area: Q7FggG_description;
}

.Q7FggG_spectrum-Menu-keyboard {
  grid-area: Q7FggG_keyboard;
  align-self: flex-start;
  font-family: adobe-clean, Source Sans Pro, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Ubuntu, Trebuchet MS, Lucida Grande, sans-serif;
}

.Q7FggG_spectrum-Menu-keyboard:not(:-webkit-any(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi))) {
  padding-left: var(--spectrum-global-dimension-size-125);
}

.Q7FggG_spectrum-Menu-keyboard:not(:is(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi))) {
  padding-left: var(--spectrum-global-dimension-size-125);
}

.Q7FggG_spectrum-Menu-keyboard:is(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi)) {
  padding-right: var(--spectrum-global-dimension-size-125);
}

.Q7FggG_spectrum-Menu {
  --spectrum-heading-subtitle3-text-color: var(--spectrum-global-color-gray-900);
  background-color: var(--spectrum-selectlist-background-color, var(--spectrum-alias-background-color-transparent));
}

.Q7FggG_spectrum-Menu-item {
  background-color: var(--spectrum-selectlist-option-background-color, var(--spectrum-alias-background-color-transparent));
  color: var(--spectrum-selectlist-option-text-color, var(--spectrum-alias-text-color));
}

.Q7FggG_spectrum-Menu-item.Q7FggG_focus-ring, .Q7FggG_spectrum-Menu-item.Q7FggG_is-focused {
  background-color: var(--spectrum-selectlist-option-background-color-key-focus, var(--spectrum-alias-background-color-hover-overlay));
  color: var(--spectrum-selectlist-option-text-color-key-focus, var(--spectrum-alias-text-color));
  border-inline-start-color: var(--spectrum-selectlist-option-focus-indicator-color, var(--spectrum-alias-border-color-focus));
}

.Q7FggG_spectrum-Menu-item.Q7FggG_is-hovered, .Q7FggG_spectrum-Menu-item:focus, .Q7FggG_spectrum-Menu-item.Q7FggG_is-highlighted, .Q7FggG_spectrum-Menu-item.Q7FggG_is-open {
  background-color: var(--spectrum-selectlist-option-background-color-hover, var(--spectrum-alias-background-color-hover-overlay));
  color: var(--spectrum-selectlist-option-text-color-hover, var(--spectrum-alias-text-color));
}

.Q7FggG_spectrum-Menu-item.Q7FggG_is-selected .Q7FggG_spectrum-Menu-checkmark {
  color: var(--spectrum-selectlist-option-icon-color-selected, var(--spectrum-alias-icon-color-selected));
}

.Q7FggG_spectrum-Menu-item .Q7FggG_is-active, .Q7FggG_spectrum-Menu-item:active {
  background-color: var(--spectrum-selectlist-option-background-color-down, var(--spectrum-alias-background-color-hover-overlay));
}

.Q7FggG_spectrum-Menu-item.Q7FggG_is-disabled {
  background-color: var(--spectrum-selectlist-option-background-color-disabled, var(--spectrum-alias-background-color-transparent));
  color: var(--spectrum-selectlist-option-text-color-disabled, var(--spectrum-alias-text-color-disabled));
  cursor: default;
  background-image: none;
}

.Q7FggG_spectrum-Menu-item.Q7FggG_is-disabled .Q7FggG_spectrum-Menu-description {
  color: var(--spectrum-selectlist-option-text-color-disabled, var(--spectrum-alias-text-color-disabled));
}

.Q7FggG_spectrum-Menu-sectionHeading {
  color: var(--spectrum-heading-subtitle3-text-color, var(--spectrum-global-color-gray-700));
}

.Q7FggG_spectrum-Menu-divider {
  background-color: var(--spectrum-global-color-gray-300);
}

.Q7FggG_spectrum-Menu-description {
  color: var(--spectrum-global-color-gray-700);
}

@media (forced-colors: active) {
  .Q7FggG_spectrum-Menu-divider {
    background-color: CanvasText;
    forced-color-adjust: none;
  }

  .Q7FggG_spectrum-Menu-item {
    forced-color-adjust: none;
    --spectrum-heading-subtitle3-text-color: ButtonText;
    --spectrum-selectlist-background-color: ButtonFace;
    --spectrum-selectlist-divider-color: ButtonText;
    --spectrum-selectlist-option-background-color: ButtonFace;
    --spectrum-selectlist-option-background-color-disabled: ButtonFace;
    --spectrum-selectlist-option-background-color-down: ButtonFace;
    --spectrum-selectlist-option-background-color-hover: Highlight;
    --spectrum-selectlist-option-background-color-key-focus: Highlight;
    --spectrum-selectlist-option-focus-indicator-color: Highlight;
    --spectrum-selectlist-option-icon-color-selected: Highlight;
    --spectrum-selectlist-option-text-color: ButtonText;
    --spectrum-selectlist-option-text-color-disabled: GrayText;
    --spectrum-selectlist-option-text-color-hover: HighlightText;
    --spectrum-selectlist-option-text-color-key-focus: HighlightText;
    --spectrum-alias-border-color-focus: Highlight;
  }

  .Q7FggG_spectrum-Menu-item .Q7FggG_spectrum-Menu-description {
    color: var(--spectrum-selectlist-option-text-color, var(--spectrum-alias-text-color));
  }

  .Q7FggG_spectrum-Menu-item.Q7FggG_is-hovered {
    background-color: var(--spectrum-selectlist-option-background-color-key-focus, var(--spectrum-alias-background-color-hover-overlay));
    color: var(--spectrum-selectlist-option-text-color-key-focus, var(--spectrum-alias-text-color));
  }

  .Q7FggG_spectrum-Menu-item.Q7FggG_is-hovered.Q7FggG_is-selected .Q7FggG_spectrum-Menu-checkmark {
    color: HighlightText;
  }

  .Q7FggG_spectrum-Menu-item.Q7FggG_is-hovered .Q7FggG_spectrum-Menu-description {
    color: var(--spectrum-selectlist-option-text-color-key-focus, var(--spectrum-alias-text-color));
    background-color: var(--spectrum-selectlist-option-background-color-key-focus, var(--spectrum-alias-background-color-hover-overlay));
  }

  .Q7FggG_spectrum-Menu-item:focus {
    background-color: var(--spectrum-selectlist-option-background-color-key-focus, var(--spectrum-alias-background-color-hover-overlay));
    color: var(--spectrum-selectlist-option-text-color-key-focus, var(--spectrum-alias-text-color));
  }

  .Q7FggG_spectrum-Menu-item:focus.Q7FggG_is-selected .Q7FggG_spectrum-Menu-checkmark {
    color: HighlightText;
  }

  .Q7FggG_spectrum-Menu-item:focus .Q7FggG_spectrum-Menu-description {
    color: var(--spectrum-selectlist-option-text-color-key-focus, var(--spectrum-alias-text-color));
    background-color: var(--spectrum-selectlist-option-background-color-key-focus, var(--spectrum-alias-background-color-hover-overlay));
  }

  .Q7FggG_spectrum-Menu-item.Q7FggG_is-highlighted {
    background-color: var(--spectrum-selectlist-option-background-color-key-focus, var(--spectrum-alias-background-color-hover-overlay));
    color: var(--spectrum-selectlist-option-text-color-key-focus, var(--spectrum-alias-text-color));
  }

  .Q7FggG_spectrum-Menu-item.Q7FggG_is-highlighted.Q7FggG_is-selected .Q7FggG_spectrum-Menu-checkmark {
    color: HighlightText;
  }

  .Q7FggG_spectrum-Menu-item.Q7FggG_is-highlighted .Q7FggG_spectrum-Menu-description {
    color: var(--spectrum-selectlist-option-text-color-key-focus, var(--spectrum-alias-text-color));
    background-color: var(--spectrum-selectlist-option-background-color-key-focus, var(--spectrum-alias-background-color-hover-overlay));
  }

  .Q7FggG_spectrum-Menu-item.Q7FggG_is-open {
    background-color: var(--spectrum-selectlist-option-background-color-key-focus, var(--spectrum-alias-background-color-hover-overlay));
    color: var(--spectrum-selectlist-option-text-color-key-focus, var(--spectrum-alias-text-color));
  }

  .Q7FggG_spectrum-Menu-item.Q7FggG_is-open.Q7FggG_is-selected .Q7FggG_spectrum-Menu-checkmark {
    color: HighlightText;
  }

  .Q7FggG_spectrum-Menu-item.Q7FggG_is-open .Q7FggG_spectrum-Menu-description {
    color: var(--spectrum-selectlist-option-text-color-key-focus, var(--spectrum-alias-text-color));
    background-color: var(--spectrum-selectlist-option-background-color-key-focus, var(--spectrum-alias-background-color-hover-overlay));
  }

  .Q7FggG_spectrum-Menu-item.Q7FggG_focus-ring {
    background-color: var(--spectrum-selectlist-option-background-color-key-focus, var(--spectrum-alias-background-color-hover-overlay));
    color: var(--spectrum-selectlist-option-text-color-key-focus, var(--spectrum-alias-text-color));
  }

  .Q7FggG_spectrum-Menu-item.Q7FggG_focus-ring.Q7FggG_is-selected .Q7FggG_spectrum-Menu-checkmark {
    color: HighlightText;
  }

  .Q7FggG_spectrum-Menu-item.Q7FggG_focus-ring .Q7FggG_spectrum-Menu-description {
    color: var(--spectrum-selectlist-option-text-color-key-focus, var(--spectrum-alias-text-color));
    background-color: var(--spectrum-selectlist-option-background-color-key-focus, var(--spectrum-alias-background-color-hover-overlay));
  }

  .Q7FggG_spectrum-Menu-item.Q7FggG_is-focused {
    background-color: var(--spectrum-selectlist-option-background-color-key-focus, var(--spectrum-alias-background-color-hover-overlay));
    color: var(--spectrum-selectlist-option-text-color-key-focus, var(--spectrum-alias-text-color));
  }

  .Q7FggG_spectrum-Menu-item.Q7FggG_is-focused.Q7FggG_is-selected .Q7FggG_spectrum-Menu-checkmark {
    color: HighlightText;
  }

  .Q7FggG_spectrum-Menu-item.Q7FggG_is-focused .Q7FggG_spectrum-Menu-description {
    color: var(--spectrum-selectlist-option-text-color-key-focus, var(--spectrum-alias-text-color));
    background-color: var(--spectrum-selectlist-option-background-color-key-focus, var(--spectrum-alias-background-color-hover-overlay));
  }

  :is().Q7FggG_is-selected .Q7FggG_spectrum-Menu-checkmark {
    color: HighlightText;
  }

  :is() .Q7FggG_spectrum-Menu-description {
    color: var(--spectrum-selectlist-option-text-color-key-focus, var(--spectrum-alias-text-color));
    background-color: var(--spectrum-selectlist-option-background-color-key-focus, var(--spectrum-alias-background-color-hover-overlay));
  }
}

.L6gUaa_react-spectrum-ContextualHelp-button {
  --spectrum-button-primary-height: var(--spectrum-global-dimension-size-250);
  --spectrum-actionbutton-height: var(--spectrum-global-dimension-size-250);
  --spectrum-actionbutton-min-width: var(--spectrum-global-dimension-size-250);
  --spectrum-actionbutton-icon-padding-x: var(--spectrum-global-dimension-size-40);
}

.L6gUaa_react-spectrum-ContextualHelp-button svg {
  block-size: var(--spectrum-global-dimension-size-175);
  inline-size: var(--spectrum-global-dimension-size-175);
}

.L6gUaa_react-spectrum-ContextualHelp-dialog.L6gUaa_react-spectrum-ContextualHelp-dialog {
  --spectrum-dialog-padding-x: var(--spectrum-global-dimension-static-size-300, 24px);
  --spectrum-dialog-padding-y: var(--spectrum-global-dimension-static-size-300, 24px);
  width: 250px;
}

.L6gUaa_react-spectrum-ContextualHelp-dialog.L6gUaa_react-spectrum-ContextualHelp-dialog .L6gUaa_react-spectrum-ContextualHelp-content {
  margin-top: var(--spectrum-global-dimension-static-size-100, 8px);
}

.L6gUaa_react-spectrum-ContextualHelp-dialog.L6gUaa_react-spectrum-ContextualHelp-dialog .L6gUaa_react-spectrum-ContextualHelp-footer {
  padding-top: var(--spectrum-global-dimension-static-size-100, 8px);
}

.OhnpTq_spectrum-Popover.OhnpTq_react-spectrum-Popover {
  visibility: visible;
}

.OhnpTq_spectrum-Popover .OhnpTq_spectrum-Dialog-content {
  max-height: initial;
}

.OhnpTq_spectrum-Modal-wrapper.OhnpTq_react-spectrum-Modal-wrapper, .OhnpTq_spectrum-Modal.OhnpTq_react-spectrum-Modal, .OhnpTq_spectrum-Tray.OhnpTq_react-spectrum-Tray {
  visibility: visible;
}

.cH0MeW_i18nFontFamily {
  font-synthesis: weight;
  font-family: adobe-clean, Source Sans Pro, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Ubuntu, Trebuchet MS, Lucida Grande, sans-serif;
}

.cH0MeW_i18nFontFamily:lang(ar) {
  font-family: myriad-arabic, adobe-clean, Source Sans Pro, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Ubuntu, Trebuchet MS, Lucida Grande, sans-serif;
}

.cH0MeW_i18nFontFamily:lang(he) {
  font-family: myriad-hebrew, adobe-clean, Source Sans Pro, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Ubuntu, Trebuchet MS, Lucida Grande, sans-serif;
}

.cH0MeW_i18nFontFamily:lang(zh) {
  font-family: adobe-clean-han-traditional, source-han-traditional, MingLiu, Heiti TC Light, sans-serif;
}

.cH0MeW_i18nFontFamily:lang(zh-Hans) {
  font-family: adobe-clean-han-simplified-c, source-han-simplified-c, SimSun, Heiti SC Light, sans-serif;
}

.cH0MeW_i18nFontFamily:lang(zh-Hant) {
  font-family: adobe-clean-han-traditional, source-han-traditional, MingLiu, Microsoft JhengHei UI, Microsoft JhengHei, Heiti TC Light, sans-serif;
}

.cH0MeW_i18nFontFamily:lang(zh-SG), .cH0MeW_i18nFontFamily:lang(zh-CN) {
  font-family: adobe-clean-han-simplified-c, source-han-simplified-c, SimSun, Heiti SC Light, sans-serif;
}

.cH0MeW_i18nFontFamily:lang(ko) {
  font-family: adobe-clean-han-korean, source-han-korean, Malgun Gothic, Apple Gothic, sans-serif;
}

.cH0MeW_i18nFontFamily:lang(ja) {
  font-family: adobe-clean-han-japanese, Hiragino Kaku Gothic ProN, ヒラギノ角ゴ ProN W3, Osaka, YuGothic, Yu Gothic, メイリオ, Meiryo, ＭＳ Ｐゴシック, MS PGothic, sans-serif;
}

.cH0MeW_spectrum-FocusRing-ring {
  --spectrum-focus-ring-border-radius: var(--spectrum-textfield-border-radius, var(--spectrum-alias-border-radius-regular));
  --spectrum-focus-ring-gap: var(--spectrum-alias-input-focusring-gap);
  --spectrum-focus-ring-size: var(--spectrum-alias-input-focusring-size);
  --spectrum-focus-ring-border-size: 0px;
  --spectrum-focus-ring-color: var(--spectrum-high-contrast-focus-ring-color, var(--spectrum-alias-focus-ring-color, var(--spectrum-alias-focus-color)));
}

.cH0MeW_spectrum-FocusRing-ring:after {
  border-radius: calc(var(--spectrum-focus-ring-border-radius)  + var(--spectrum-focus-ring-gap));
  content: "";
  margin: calc(-1 * var(--spectrum-focus-ring-border-size));
  pointer-events: none;
  transition: box-shadow var(--spectrum-global-animation-duration-100, .13s) ease-out, margin var(--spectrum-global-animation-duration-100, .13s) ease-out;
  display: block;
  position: absolute;
  inset: 0;
}

.cH0MeW_spectrum-FocusRing.cH0MeW_focus-ring:after {
  margin: calc(var(--spectrum-focus-ring-gap) * -1 - var(--spectrum-focus-ring-border-size));
  box-shadow: 0 0 0 var(--spectrum-focus-ring-size) var(--spectrum-focus-ring-color);
}

.cH0MeW_spectrum-FocusRing--quiet:after {
  border-radius: 0;
}

.cH0MeW_spectrum-FocusRing--quiet.cH0MeW_focus-ring:after {
  margin: 0 0 calc(var(--spectrum-focus-ring-gap) * -1 - var(--spectrum-focus-ring-border-size)) 0;
  box-shadow: 0 var(--spectrum-focus-ring-size) 0 var(--spectrum-focus-ring-color);
}

.cH0MeW_spectrum-overlay {
  visibility: hidden;
  opacity: 0;
  transition: transform var(--spectrum-global-animation-duration-100, .13s) ease-in-out, opacity var(--spectrum-global-animation-duration-100, .13s) ease-in-out, visibility 0s linear var(--spectrum-global-animation-duration-100, .13s);
  pointer-events: none;
}

.cH0MeW_spectrum-overlay--open {
  visibility: visible;
  opacity: .9999;
  pointer-events: auto;
  transition-delay: 0s;
}

.cH0MeW_spectrum-overlay--bottom--open {
  transform: translateY(var(--spectrum-overlay-positive-transform-distance));
}

.cH0MeW_spectrum-overlay--top--open {
  transform: translateY(var(--spectrum-overlay-negative-transform-distance));
}

.cH0MeW_spectrum-overlay--right--open {
  transform: translateX(var(--spectrum-overlay-positive-transform-distance));
}

.cH0MeW_spectrum-overlay--left--open {
  transform: translateX(var(--spectrum-overlay-negative-transform-distance));
}

.cH0MeW_spectrum-Popover {
  box-sizing: border-box;
  min-width: var(--spectrum-global-dimension-size-400);
  min-height: var(--spectrum-global-dimension-size-400);
  border-style: solid;
  border-width: var(--spectrum-popover-border-size, var(--spectrum-alias-border-size-thin));
  border-radius: var(--spectrum-popover-border-radius, var(--spectrum-alias-border-radius-regular));
  outline: none;
  flex-direction: column;
  display: inline-flex;
  position: absolute;
  overflow: hidden;
}

.cH0MeW_spectrum-Popover--withTip {
  overflow: visible;
}

.cH0MeW_spectrum-Popover-tip {
  --spectrum-popover-tip-size: var(--spectrum-popover-tip-width, var(--spectrum-global-dimension-size-250));
  --spectrum-popover-tip-borderWidth: var(--spectrum-popover-border-size, var(--spectrum-alias-border-size-thin));
  position: absolute;
  -webkit-transform: translate(0);
}

.cH0MeW_spectrum-Popover-tip .cH0MeW_spectrum-Popover-tip-triangle {
  stroke-linecap: square;
  stroke-linejoin: miter;
  stroke-width: var(--spectrum-popover-border-size, var(--spectrum-alias-border-size-thin));
}

.cH0MeW_spectrum-Popover--dialog {
  min-width: 270px;
  padding: 30px 29px;
}

.cH0MeW_spectrum-Popover--left.cH0MeW_spectrum-Popover--withTip {
  margin-right: 13px;
}

.cH0MeW_spectrum-Popover--left .cH0MeW_spectrum-Popover-tip {
  left: 100%;
}

.cH0MeW_spectrum-Popover--right.cH0MeW_spectrum-Popover--withTip {
  margin-left: 13px;
}

.cH0MeW_spectrum-Popover--right .cH0MeW_spectrum-Popover-tip {
  right: 100%;
  transform: scaleX(-1);
}

:is(.cH0MeW_spectrum-Popover--left, .cH0MeW_spectrum-Popover--right) .cH0MeW_spectrum-Popover-tip {
  margin-top: calc(var(--spectrum-global-dimension-size-150) * -1);
  top: 50%;
}

.cH0MeW_spectrum-Popover--bottom.cH0MeW_spectrum-Popover--withTip {
  margin-top: 13px;
}

.cH0MeW_spectrum-Popover--bottom .cH0MeW_spectrum-Popover-tip {
  bottom: 100%;
  transform: scaleY(-1);
}

.cH0MeW_spectrum-Popover--top.cH0MeW_spectrum-Popover--withTip {
  margin-bottom: 13px;
}

.cH0MeW_spectrum-Popover--top .cH0MeW_spectrum-Popover-tip {
  top: 100%;
}

:is(.cH0MeW_spectrum-Popover--bottom, .cH0MeW_spectrum-Popover--top) .cH0MeW_spectrum-Popover-tip {
  margin-left: calc(var(--spectrum-global-dimension-size-150) * -1);
  left: 50%;
}

.cH0MeW_spectrum-Popover {
  background-color: var(--spectrum-popover-background-color, var(--spectrum-global-color-gray-50));
  border-color: var(--spectrum-popover-border-color, var(--spectrum-alias-border-color-dark));
  -webkit-filter: drop-shadow(0 var(--spectrum-popover-shadow-offset-y, var(--spectrum-alias-dropshadow-offset-y)) var(--spectrum-popover-shadow-blur, var(--spectrum-alias-dropshadow-blur)) var(--spectrum-popover-shadow-color, var(--spectrum-alias-dropshadow-color)));
  will-change: filter;
  -webkit-clip-path: inset(-30px);
  clip-path: inset(-30px);
}

.cH0MeW_spectrum-Popover .cH0MeW_spectrum-Popover-tip .cH0MeW_spectrum-Popover-tip-triangle {
  fill: var(--spectrum-popover-background-color, var(--spectrum-global-color-gray-50));
  stroke: var(--spectrum-popover-border-color, var(--spectrum-alias-border-color-dark));
}

@media (forced-colors: active) {
  .cH0MeW_spectrum-Popover {
    --spectrum-popover-background-color: Canvas;
    --spectrum-popover-border-color: CanvasText;
  }
}

.F-oYUa_i18nFontFamily {
  font-synthesis: weight;
  font-family: adobe-clean, Source Sans Pro, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Ubuntu, Trebuchet MS, Lucida Grande, sans-serif;
}

.F-oYUa_i18nFontFamily:lang(ar) {
  font-family: myriad-arabic, adobe-clean, Source Sans Pro, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Ubuntu, Trebuchet MS, Lucida Grande, sans-serif;
}

.F-oYUa_i18nFontFamily:lang(he) {
  font-family: myriad-hebrew, adobe-clean, Source Sans Pro, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Ubuntu, Trebuchet MS, Lucida Grande, sans-serif;
}

.F-oYUa_i18nFontFamily:lang(zh) {
  font-family: adobe-clean-han-traditional, source-han-traditional, MingLiu, Heiti TC Light, sans-serif;
}

.F-oYUa_i18nFontFamily:lang(zh-Hans) {
  font-family: adobe-clean-han-simplified-c, source-han-simplified-c, SimSun, Heiti SC Light, sans-serif;
}

.F-oYUa_i18nFontFamily:lang(zh-Hant) {
  font-family: adobe-clean-han-traditional, source-han-traditional, MingLiu, Microsoft JhengHei UI, Microsoft JhengHei, Heiti TC Light, sans-serif;
}

.F-oYUa_i18nFontFamily:lang(zh-SG), .F-oYUa_i18nFontFamily:lang(zh-CN) {
  font-family: adobe-clean-han-simplified-c, source-han-simplified-c, SimSun, Heiti SC Light, sans-serif;
}

.F-oYUa_i18nFontFamily:lang(ko) {
  font-family: adobe-clean-han-korean, source-han-korean, Malgun Gothic, Apple Gothic, sans-serif;
}

.F-oYUa_i18nFontFamily:lang(ja) {
  font-family: adobe-clean-han-japanese, Hiragino Kaku Gothic ProN, ヒラギノ角ゴ ProN W3, Osaka, YuGothic, Yu Gothic, メイリオ, Meiryo, ＭＳ Ｐゴシック, MS PGothic, sans-serif;
}

.F-oYUa_spectrum-FocusRing-ring {
  --spectrum-focus-ring-border-radius: var(--spectrum-textfield-border-radius, var(--spectrum-alias-border-radius-regular));
  --spectrum-focus-ring-gap: var(--spectrum-alias-input-focusring-gap);
  --spectrum-focus-ring-size: var(--spectrum-alias-input-focusring-size);
  --spectrum-focus-ring-border-size: 0px;
  --spectrum-focus-ring-color: var(--spectrum-high-contrast-focus-ring-color, var(--spectrum-alias-focus-ring-color, var(--spectrum-alias-focus-color)));
}

.F-oYUa_spectrum-FocusRing-ring:after {
  border-radius: calc(var(--spectrum-focus-ring-border-radius)  + var(--spectrum-focus-ring-gap));
  content: "";
  margin: calc(-1 * var(--spectrum-focus-ring-border-size));
  pointer-events: none;
  transition: box-shadow var(--spectrum-global-animation-duration-100, .13s) ease-out, margin var(--spectrum-global-animation-duration-100, .13s) ease-out;
  display: block;
  position: absolute;
  inset: 0;
}

.F-oYUa_spectrum-FocusRing.F-oYUa_focus-ring:after {
  margin: calc(var(--spectrum-focus-ring-gap) * -1 - var(--spectrum-focus-ring-border-size));
  box-shadow: 0 0 0 var(--spectrum-focus-ring-size) var(--spectrum-focus-ring-color);
}

.F-oYUa_spectrum-FocusRing--quiet:after {
  border-radius: 0;
}

.F-oYUa_spectrum-FocusRing--quiet.F-oYUa_focus-ring:after {
  margin: 0 0 calc(var(--spectrum-focus-ring-gap) * -1 - var(--spectrum-focus-ring-border-size)) 0;
  box-shadow: 0 var(--spectrum-focus-ring-size) 0 var(--spectrum-focus-ring-color);
}

.F-oYUa_spectrum-overlay {
  visibility: hidden;
  opacity: 0;
  transition: transform var(--spectrum-global-animation-duration-100, .13s) ease-in-out, opacity var(--spectrum-global-animation-duration-100, .13s) ease-in-out, visibility 0s linear var(--spectrum-global-animation-duration-100, .13s);
  pointer-events: none;
}

.F-oYUa_spectrum-overlay--open {
  visibility: visible;
  opacity: .9999;
  pointer-events: auto;
  transition-delay: 0s;
}

.F-oYUa_spectrum-overlay--bottom--open {
  transform: translateY(var(--spectrum-overlay-positive-transform-distance));
}

.F-oYUa_spectrum-overlay--top--open {
  transform: translateY(var(--spectrum-overlay-negative-transform-distance));
}

.F-oYUa_spectrum-overlay--right--open {
  transform: translateX(var(--spectrum-overlay-positive-transform-distance));
}

.F-oYUa_spectrum-overlay--left--open {
  transform: translateX(var(--spectrum-overlay-negative-transform-distance));
}

.F-oYUa_spectrum-Underlay {
  z-index: 1;
  transition: opacity var(--spectrum-dialog-background-exit-animation-duration, var(--spectrum-global-animation-duration-300)) cubic-bezier(.5, 0, 1, 1) var(--spectrum-dialog-background-exit-animation-delay, var(--spectrum-global-animation-duration-200)), visibility 0s linear calc(var(--spectrum-dialog-background-exit-animation-delay, var(--spectrum-global-animation-duration-200))  + var(--spectrum-dialog-background-exit-animation-duration, var(--spectrum-global-animation-duration-300)));
  position: fixed;
  inset: 0;
  overflow: hidden;
}

.F-oYUa_spectrum-Underlay.F-oYUa_spectrum-Underlay--transparent {
  background: none;
  transition: none;
}

.F-oYUa_is-open {
  transition: opacity var(--spectrum-dialog-background-entry-animation-duration, var(--spectrum-global-animation-duration-600)) cubic-bezier(0, 0, .4, 1) 0s;
}

.F-oYUa_spectrum-Underlay {
  background: var(--spectrum-dialog-underlay-background-color, var(--spectrum-alias-background-color-modal-overlay));
}

._0YML2q_i18nFontFamily {
  font-synthesis: weight;
  font-family: adobe-clean, Source Sans Pro, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Ubuntu, Trebuchet MS, Lucida Grande, sans-serif;
}

._0YML2q_i18nFontFamily:lang(ar) {
  font-family: myriad-arabic, adobe-clean, Source Sans Pro, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Ubuntu, Trebuchet MS, Lucida Grande, sans-serif;
}

._0YML2q_i18nFontFamily:lang(he) {
  font-family: myriad-hebrew, adobe-clean, Source Sans Pro, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Ubuntu, Trebuchet MS, Lucida Grande, sans-serif;
}

._0YML2q_i18nFontFamily:lang(zh) {
  font-family: adobe-clean-han-traditional, source-han-traditional, MingLiu, Heiti TC Light, sans-serif;
}

._0YML2q_i18nFontFamily:lang(zh-Hans) {
  font-family: adobe-clean-han-simplified-c, source-han-simplified-c, SimSun, Heiti SC Light, sans-serif;
}

._0YML2q_i18nFontFamily:lang(zh-Hant) {
  font-family: adobe-clean-han-traditional, source-han-traditional, MingLiu, Microsoft JhengHei UI, Microsoft JhengHei, Heiti TC Light, sans-serif;
}

._0YML2q_i18nFontFamily:lang(zh-SG), ._0YML2q_i18nFontFamily:lang(zh-CN) {
  font-family: adobe-clean-han-simplified-c, source-han-simplified-c, SimSun, Heiti SC Light, sans-serif;
}

._0YML2q_i18nFontFamily:lang(ko) {
  font-family: adobe-clean-han-korean, source-han-korean, Malgun Gothic, Apple Gothic, sans-serif;
}

._0YML2q_i18nFontFamily:lang(ja) {
  font-family: adobe-clean-han-japanese, Hiragino Kaku Gothic ProN, ヒラギノ角ゴ ProN W3, Osaka, YuGothic, Yu Gothic, メイリオ, Meiryo, ＭＳ Ｐゴシック, MS PGothic, sans-serif;
}

._0YML2q_spectrum-FocusRing-ring {
  --spectrum-focus-ring-border-radius: var(--spectrum-textfield-border-radius, var(--spectrum-alias-border-radius-regular));
  --spectrum-focus-ring-gap: var(--spectrum-alias-input-focusring-gap);
  --spectrum-focus-ring-size: var(--spectrum-alias-input-focusring-size);
  --spectrum-focus-ring-border-size: 0px;
  --spectrum-focus-ring-color: var(--spectrum-high-contrast-focus-ring-color, var(--spectrum-alias-focus-ring-color, var(--spectrum-alias-focus-color)));
}

._0YML2q_spectrum-FocusRing-ring:after {
  border-radius: calc(var(--spectrum-focus-ring-border-radius)  + var(--spectrum-focus-ring-gap));
  content: "";
  margin: calc(-1 * var(--spectrum-focus-ring-border-size));
  pointer-events: none;
  transition: box-shadow var(--spectrum-global-animation-duration-100, .13s) ease-out, margin var(--spectrum-global-animation-duration-100, .13s) ease-out;
  display: block;
  position: absolute;
  inset: 0;
}

._0YML2q_spectrum-FocusRing._0YML2q_focus-ring:after {
  margin: calc(var(--spectrum-focus-ring-gap) * -1 - var(--spectrum-focus-ring-border-size));
  box-shadow: 0 0 0 var(--spectrum-focus-ring-size) var(--spectrum-focus-ring-color);
}

._0YML2q_spectrum-FocusRing--quiet:after {
  border-radius: 0;
}

._0YML2q_spectrum-FocusRing--quiet._0YML2q_focus-ring:after {
  margin: 0 0 calc(var(--spectrum-focus-ring-gap) * -1 - var(--spectrum-focus-ring-border-size)) 0;
  box-shadow: 0 var(--spectrum-focus-ring-size) 0 var(--spectrum-focus-ring-color);
}

._0YML2q_spectrum-overlay {
  visibility: hidden;
  opacity: 0;
  transition: transform var(--spectrum-global-animation-duration-100, .13s) ease-in-out, opacity var(--spectrum-global-animation-duration-100, .13s) ease-in-out, visibility 0s linear var(--spectrum-global-animation-duration-100, .13s);
  pointer-events: none;
}

._0YML2q_spectrum-overlay--open {
  visibility: visible;
  opacity: .9999;
  pointer-events: auto;
  transition-delay: 0s;
}

._0YML2q_spectrum-overlay--bottom--open {
  transform: translateY(var(--spectrum-overlay-positive-transform-distance));
}

._0YML2q_spectrum-overlay--top--open {
  transform: translateY(var(--spectrum-overlay-negative-transform-distance));
}

._0YML2q_spectrum-overlay--right--open {
  transform: translateX(var(--spectrum-overlay-positive-transform-distance));
}

._0YML2q_spectrum-overlay--left--open {
  transform: translateX(var(--spectrum-overlay-negative-transform-distance));
}

._0YML2q_spectrum-Modal-wrapper {
  box-sizing: border-box;
  height: var(--spectrum-visual-viewport-height);
  visibility: hidden;
  pointer-events: none;
  z-index: 2;
  transition: visibility 0s linear var(--spectrum-global-animation-duration-100, .13s);
  justify-content: center;
  align-items: center;
  width: 100vw;
  display: flex;
  position: fixed;
  top: 0;
  left: 0;
}

._0YML2q_spectrum-Modal-wrapper._0YML2q_is-open {
  visibility: visible;
}

._0YML2q_spectrum-Modal {
  transform: translateY(var(--spectrum-dialog-entry-animation-distance, var(--spectrum-global-dimension-size-250)));
  z-index: 2;
  max-height: calc(var(--spectrum-visual-viewport-height) * .9);
  min-width: var(--spectrum-dialog-min-width, var(--spectrum-global-dimension-static-size-3600));
  border-radius: var(--spectrum-dialog-border-radius, var(--spectrum-global-dimension-size-50));
  border-width: var(--spectrum-popover-border-size, var(--spectrum-alias-border-size-thin));
  pointer-events: auto;
  transition: opacity var(--spectrum-dialog-exit-animation-duration, var(--spectrum-global-animation-duration-100)) cubic-bezier(.5, 0, 1, 1) 0s, visibility 0s linear calc(0s + var(--spectrum-dialog-exit-animation-duration, var(--spectrum-global-animation-duration-100))), transform 0s linear calc(0s + var(--spectrum-dialog-exit-animation-duration, var(--spectrum-global-animation-duration-100)));
  outline: none;
  max-width: 90vw;
}

._0YML2q_is-open {
  transition: transform var(--spectrum-dialog-entry-animation-duration, var(--spectrum-global-animation-duration-500)) cubic-bezier(0, 0, .4, 1) var(--spectrum-dialog-entry-animation-delay, var(--spectrum-global-animation-duration-200)), opacity var(--spectrum-dialog-entry-animation-duration, var(--spectrum-global-animation-duration-500)) cubic-bezier(0, 0, .4, 1) var(--spectrum-dialog-entry-animation-delay, var(--spectrum-global-animation-duration-200));
  transform: translateY(0);
}

@media only screen and (max-device-width: 400px), only screen and (max-device-height: 350px) {
  ._0YML2q_spectrum-Modal--responsive {
    border-radius: 0;
    width: 100%;
    max-width: 100%;
    height: 100%;
    max-height: 100%;
  }

  ._0YML2q_spectrum-Modal-wrapper ._0YML2q_spectrum-Modal--responsive {
    margin-top: 0;
  }
}

._0YML2q_spectrum-Modal--fullscreen {
  width: calc(100% - 80px);
  max-width: none;
  height: calc(100% - 80px);
  max-height: none;
  position: fixed;
  inset: 40px;
}

._0YML2q_spectrum-Modal--fullscreenTakeover {
  box-sizing: border-box;
  border: none;
  border-radius: 0;
  max-width: none;
  max-height: none;
  position: fixed;
  inset: 0;
  transform: none;
}

._0YML2q_spectrum-Modal--fullscreenTakeover._0YML2q_is-open {
  transform: none;
}

@media (forced-colors: active) {
  ._0YML2q_spectrum-Modal:not(._0YML2q_spectrum-Modal--fullscreenTakeover) {
    border-style: solid;
  }
}

._0YML2q_spectrum-Modal {
  background: var(--spectrum-dialog-background-color, var(--spectrum-alias-background-color-default));
  border-color: var(--spectrum-alias-border-color-transparent, transparent);
}

.UuxJvG_i18nFontFamily {
  font-synthesis: weight;
  font-family: adobe-clean, Source Sans Pro, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Ubuntu, Trebuchet MS, Lucida Grande, sans-serif;
}

.UuxJvG_i18nFontFamily:lang(ar) {
  font-family: myriad-arabic, adobe-clean, Source Sans Pro, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Ubuntu, Trebuchet MS, Lucida Grande, sans-serif;
}

.UuxJvG_i18nFontFamily:lang(he) {
  font-family: myriad-hebrew, adobe-clean, Source Sans Pro, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Ubuntu, Trebuchet MS, Lucida Grande, sans-serif;
}

.UuxJvG_i18nFontFamily:lang(zh) {
  font-family: adobe-clean-han-traditional, source-han-traditional, MingLiu, Heiti TC Light, sans-serif;
}

.UuxJvG_i18nFontFamily:lang(zh-Hans) {
  font-family: adobe-clean-han-simplified-c, source-han-simplified-c, SimSun, Heiti SC Light, sans-serif;
}

.UuxJvG_i18nFontFamily:lang(zh-Hant) {
  font-family: adobe-clean-han-traditional, source-han-traditional, MingLiu, Microsoft JhengHei UI, Microsoft JhengHei, Heiti TC Light, sans-serif;
}

.UuxJvG_i18nFontFamily:lang(zh-SG), .UuxJvG_i18nFontFamily:lang(zh-CN) {
  font-family: adobe-clean-han-simplified-c, source-han-simplified-c, SimSun, Heiti SC Light, sans-serif;
}

.UuxJvG_i18nFontFamily:lang(ko) {
  font-family: adobe-clean-han-korean, source-han-korean, Malgun Gothic, Apple Gothic, sans-serif;
}

.UuxJvG_i18nFontFamily:lang(ja) {
  font-family: adobe-clean-han-japanese, Hiragino Kaku Gothic ProN, ヒラギノ角ゴ ProN W3, Osaka, YuGothic, Yu Gothic, メイリオ, Meiryo, ＭＳ Ｐゴシック, MS PGothic, sans-serif;
}

.UuxJvG_spectrum-FocusRing-ring {
  --spectrum-focus-ring-border-radius: var(--spectrum-textfield-border-radius, var(--spectrum-alias-border-radius-regular));
  --spectrum-focus-ring-gap: var(--spectrum-alias-input-focusring-gap);
  --spectrum-focus-ring-size: var(--spectrum-alias-input-focusring-size);
  --spectrum-focus-ring-border-size: 0px;
  --spectrum-focus-ring-color: var(--spectrum-high-contrast-focus-ring-color, var(--spectrum-alias-focus-ring-color, var(--spectrum-alias-focus-color)));
}

.UuxJvG_spectrum-FocusRing-ring:after {
  border-radius: calc(var(--spectrum-focus-ring-border-radius)  + var(--spectrum-focus-ring-gap));
  content: "";
  margin: calc(-1 * var(--spectrum-focus-ring-border-size));
  pointer-events: none;
  transition: box-shadow var(--spectrum-global-animation-duration-100, .13s) ease-out, margin var(--spectrum-global-animation-duration-100, .13s) ease-out;
  display: block;
  position: absolute;
  inset: 0;
}

.UuxJvG_spectrum-FocusRing.UuxJvG_focus-ring:after {
  margin: calc(var(--spectrum-focus-ring-gap) * -1 - var(--spectrum-focus-ring-border-size));
  box-shadow: 0 0 0 var(--spectrum-focus-ring-size) var(--spectrum-focus-ring-color);
}

.UuxJvG_spectrum-FocusRing--quiet:after {
  border-radius: 0;
}

.UuxJvG_spectrum-FocusRing--quiet.UuxJvG_focus-ring:after {
  margin: 0 0 calc(var(--spectrum-focus-ring-gap) * -1 - var(--spectrum-focus-ring-border-size)) 0;
  box-shadow: 0 var(--spectrum-focus-ring-size) 0 var(--spectrum-focus-ring-color);
}

.UuxJvG_spectrum-overlay {
  visibility: hidden;
  opacity: 0;
  transition: transform var(--spectrum-global-animation-duration-100, .13s) ease-in-out, opacity var(--spectrum-global-animation-duration-100, .13s) ease-in-out, visibility 0s linear var(--spectrum-global-animation-duration-100, .13s);
  pointer-events: none;
}

.UuxJvG_spectrum-overlay--open {
  visibility: visible;
  opacity: .9999;
  pointer-events: auto;
  transition-delay: 0s;
}

.UuxJvG_spectrum-overlay--bottom--open {
  transform: translateY(var(--spectrum-overlay-positive-transform-distance));
}

.UuxJvG_spectrum-overlay--top--open {
  transform: translateY(var(--spectrum-overlay-negative-transform-distance));
}

.UuxJvG_spectrum-overlay--right--open {
  transform: translateX(var(--spectrum-overlay-positive-transform-distance));
}

.UuxJvG_spectrum-overlay--left--open {
  transform: translateX(var(--spectrum-overlay-negative-transform-distance));
}

.UuxJvG_spectrum-Tray-wrapper {
  pointer-events: none;
  z-index: 2;
  justify-content: center;
  width: 100%;
  height: 100vh;
  display: flex;
  position: fixed;
  top: 0;
}

.UuxJvG_spectrum-Tray-wrapper:not(:-webkit-any(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi))) {
  left: 0;
}

.UuxJvG_spectrum-Tray-wrapper:not(:is(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi))) {
  left: 0;
}

.UuxJvG_spectrum-Tray-wrapper:is(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi)) {
  right: 0;
}

.UuxJvG_spectrum-Tray {
  --spectrum-tray-margin-top: 64px;
  --spectrum-tray-max-width: 450px;
  width: var(--spectrum-tray-width, 100%);
  max-width: var(--spectrum-tray-max-width, 375px);
  min-height: var(--spectrum-tray-min-height, var(--spectrum-global-dimension-static-size-800));
  max-height: calc(var(--spectrum-visual-viewport-height)  - var(--spectrum-tray-margin-top));
  padding-bottom: max(calc(100vh - var(--spectrum-visual-viewport-height)), env(safe-area-inset-bottom));
  border-radius: var(--spectrum-tray-full-width-border-radius, var(--spectrum-alias-border-radius-regular)) var(--spectrum-tray-full-width-border-radius, var(--spectrum-alias-border-radius-regular)) var(--spectrum-tray-border-radius, 0px) var(--spectrum-tray-border-radius, 0px);
  transition: opacity var(--spectrum-dialog-exit-animation-duration, var(--spectrum-global-animation-duration-100)) cubic-bezier(.5, 0, 1, 1) 0s, transform var(--spectrum-dialog-exit-animation-duration, var(--spectrum-global-animation-duration-100)) cubic-bezier(.5, 0, 1, 1) 0s;
  outline: none;
  flex-direction: column;
  display: flex;
  position: absolute;
  bottom: 0;
  overflow-x: hidden;
  overflow-y: auto;
  transform: translateY(100%);
}

.UuxJvG_is-open {
  transition: transform var(--spectrum-dialog-entry-animation-duration, var(--spectrum-global-animation-duration-500)) cubic-bezier(0, 0, .4, 1) var(--spectrum-dialog-entry-animation-delay, var(--spectrum-global-animation-duration-200)), opacity var(--spectrum-dialog-entry-animation-duration, var(--spectrum-global-animation-duration-500)) cubic-bezier(0, 0, .4, 1) var(--spectrum-dialog-entry-animation-delay, var(--spectrum-global-animation-duration-200));
  transform: translateY(0);
}

.UuxJvG_spectrum-Tray--fixedHeight {
  height: calc(var(--spectrum-visual-viewport-height)  - var(--spectrum-tray-margin-top));
  top: var(--spectrum-tray-margin-top);
}

@media (max-width: 450px) {
  .UuxJvG_spectrum-Tray {
    border-radius: var(--spectrum-tray-border-radius, 0px);
  }
}

@media (forced-colors: active) {
  .UuxJvG_spectrum-Tray {
    border-width: var(--spectrum-alias-border-size-thin, var(--spectrum-global-dimension-static-size-10));
    border-style: solid solid none;
  }
}

.UuxJvG_spectrum-Tray {
  background-color: var(--spectrum-tray-background-color);
}

.gP7gvq_spectrum--darkest {
  --spectrum-global-color-status: Verified;
  --spectrum-global-color-version: 5.1;
  --spectrum-gray-50: #000;
  --spectrum-gray-75: #0e0e0e;
  --spectrum-gray-100: #1d1d1d;
  --spectrum-gray-200: #303030;
  --spectrum-gray-300: #4b4b4b;
  --spectrum-gray-400: #6a6a6a;
  --spectrum-gray-500: #8d8d8d;
  --spectrum-gray-600: #b0b0b0;
  --spectrum-gray-700: #d0d0d0;
  --spectrum-gray-800: #ebebeb;
  --spectrum-gray-900: #fff;
  --spectrum-red-100: #570000;
  --spectrum-red-200: #6e0000;
  --spectrum-red-300: #8a0000;
  --spectrum-red-400: #a70000;
  --spectrum-red-500: #c40706;
  --spectrum-red-600: #dd2118;
  --spectrum-red-700: #ee4331;
  --spectrum-red-800: #f9634c;
  --spectrum-red-900: #ff816b;
  --spectrum-red-1000: #ff9e8c;
  --spectrum-red-1100: #ffb7a9;
  --spectrum-red-1200: #ffcdc3;
  --spectrum-red-1300: #ffdfd9;
  --spectrum-red-1400: #ffedea;
  --spectrum-orange-100: #481801;
  --spectrum-orange-200: #5c2000;
  --spectrum-orange-300: #732b00;
  --spectrum-orange-400: #8a3700;
  --spectrum-orange-500: #a24400;
  --spectrum-orange-600: #ba5200;
  --spectrum-orange-700: #d26200;
  --spectrum-orange-800: #e87400;
  --spectrum-orange-900: #f98917;
  --spectrum-orange-1000: #ffa23b;
  --spectrum-orange-1100: #ffbc66;
  --spectrum-orange-1200: #fdd291;
  --spectrum-orange-1300: #ffe2b5;
  --spectrum-orange-1400: #ffefd5;
  --spectrum-yellow-100: #352400;
  --spectrum-yellow-200: #442f00;
  --spectrum-yellow-300: #563e00;
  --spectrum-yellow-400: #674d00;
  --spectrum-yellow-500: #7a5c00;
  --spectrum-yellow-600: #8d6c00;
  --spectrum-yellow-700: #a17e00;
  --spectrum-yellow-800: #b49000;
  --spectrum-yellow-900: #c7a200;
  --spectrum-yellow-1000: #d8b500;
  --spectrum-yellow-1100: #e9c700;
  --spectrum-yellow-1200: #f7d804;
  --spectrum-yellow-1300: #f9e961;
  --spectrum-yellow-1400: #fcf3aa;
  --spectrum-chartreuse-100: #202b00;
  --spectrum-chartreuse-200: #2a3800;
  --spectrum-chartreuse-300: #364800;
  --spectrum-chartreuse-400: #425800;
  --spectrum-chartreuse-500: #4f6900;
  --spectrum-chartreuse-600: #5d7b00;
  --spectrum-chartreuse-700: #6b8e00;
  --spectrum-chartreuse-800: #7aa100;
  --spectrum-chartreuse-900: #8ab403;
  --spectrum-chartreuse-1000: #9ac60b;
  --spectrum-chartreuse-1100: #aad816;
  --spectrum-chartreuse-1200: #bbe829;
  --spectrum-chartreuse-1300: #cdf648;
  --spectrum-chartreuse-1400: #e1fd84;
  --spectrum-celery-100: #002f07;
  --spectrum-celery-200: #003d09;
  --spectrum-celery-300: #004d0c;
  --spectrum-celery-400: #005f0f;
  --spectrum-celery-500: #00710f;
  --spectrum-celery-600: #00840f;
  --spectrum-celery-700: #009714;
  --spectrum-celery-800: #0dab25;
  --spectrum-celery-900: #2dbf3a;
  --spectrum-celery-1000: #50d052;
  --spectrum-celery-1100: #73e06b;
  --spectrum-celery-1200: #93ed83;
  --spectrum-celery-1300: #b4f7a2;
  --spectrum-celery-1400: #d5fcca;
  --spectrum-green-100: #0a2c1c;
  --spectrum-green-200: #073b24;
  --spectrum-green-300: #004c2e;
  --spectrum-green-400: #005d39;
  --spectrum-green-500: #006f45;
  --spectrum-green-600: #008252;
  --spectrum-green-700: #009562;
  --spectrum-green-800: #1ca872;
  --spectrum-green-900: #34bb84;
  --spectrum-green-1000: #4bcd95;
  --spectrum-green-1100: #67dea8;
  --spectrum-green-1200: #89ecbc;
  --spectrum-green-1300: #b1f4d1;
  --spectrum-green-1400: #d6f9e4;
  --spectrum-seafoam-100: #122b2a;
  --spectrum-seafoam-200: #133937;
  --spectrum-seafoam-300: #104946;
  --spectrum-seafoam-400: #035b58;
  --spectrum-seafoam-500: #006c68;
  --spectrum-seafoam-600: #007f79;
  --spectrum-seafoam-700: #00928c;
  --spectrum-seafoam-800: #00a59f;
  --spectrum-seafoam-900: #1ab9b2;
  --spectrum-seafoam-1000: #42cac3;
  --spectrum-seafoam-1100: #66dad3;
  --spectrum-seafoam-1200: #8be8e1;
  --spectrum-seafoam-1300: #b3f2ed;
  --spectrum-seafoam-1400: #d7f8f4;
  --spectrum-cyan-100: #002944;
  --spectrum-cyan-200: #003658;
  --spectrum-cyan-300: #00456c;
  --spectrum-cyan-400: #005680;
  --spectrum-cyan-500: #006793;
  --spectrum-cyan-600: #0079a7;
  --spectrum-cyan-700: #008cba;
  --spectrum-cyan-800: #04a0cd;
  --spectrum-cyan-900: #17b4dd;
  --spectrum-cyan-1000: #39c7ea;
  --spectrum-cyan-1100: #60d8f3;
  --spectrum-cyan-1200: #86e6fa;
  --spectrum-cyan-1300: #aaf2ff;
  --spectrum-cyan-1400: #cef9ff;
  --spectrum-blue-100: #002651;
  --spectrum-blue-200: #00326a;
  --spectrum-blue-300: #004087;
  --spectrum-blue-400: #004ea6;
  --spectrum-blue-500: #005cc8;
  --spectrum-blue-600: #066ce7;
  --spectrum-blue-700: #1d80f5;
  --spectrum-blue-800: #4096f3;
  --spectrum-blue-900: #5eaaf7;
  --spectrum-blue-1000: #7cbdfa;
  --spectrum-blue-1100: #98cefd;
  --spectrum-blue-1200: #b3defe;
  --spectrum-blue-1300: #ceeaff;
  --spectrum-blue-1400: #e3f3ff;
  --spectrum-indigo-100: #1a1d61;
  --spectrum-indigo-200: #23277d;
  --spectrum-indigo-300: #2e329e;
  --spectrum-indigo-400: #3a3fbd;
  --spectrum-indigo-500: #494ed8;
  --spectrum-indigo-600: #5a60eb;
  --spectrum-indigo-700: #6e73f6;
  --spectrum-indigo-800: #8488fd;
  --spectrum-indigo-900: #999dff;
  --spectrum-indigo-1000: #aeb1ff;
  --spectrum-indigo-1100: #c2c4ff;
  --spectrum-indigo-1200: #d4d5ff;
  --spectrum-indigo-1300: #e3e4ff;
  --spectrum-indigo-1400: #f0f0ff;
  --spectrum-purple-100: #321068;
  --spectrum-purple-200: #430d8c;
  --spectrum-purple-300: #5610ad;
  --spectrum-purple-400: #6a1dc8;
  --spectrum-purple-500: #7e31de;
  --spectrum-purple-600: #9146ec;
  --spectrum-purple-700: #a25ef6;
  --spectrum-purple-800: #b277fa;
  --spectrum-purple-900: #c08ffc;
  --spectrum-purple-1000: #cea6fd;
  --spectrum-purple-1100: #dbbcfe;
  --spectrum-purple-1200: #e6cfff;
  --spectrum-purple-1300: #f0e0ff;
  --spectrum-purple-1400: #f8edff;
  --spectrum-fuchsia-100: #460e44;
  --spectrum-fuchsia-200: #5d095c;
  --spectrum-fuchsia-300: #780078;
  --spectrum-fuchsia-400: #920093;
  --spectrum-fuchsia-500: #a913aa;
  --spectrum-fuchsia-600: #bf2bbf;
  --spectrum-fuchsia-700: #d341d5;
  --spectrum-fuchsia-800: #e45be5;
  --spectrum-fuchsia-900: #ef78ee;
  --spectrum-fuchsia-1000: #f695f3;
  --spectrum-fuchsia-1100: #fbaff6;
  --spectrum-fuchsia-1200: #fec7f8;
  --spectrum-fuchsia-1300: #ffdcfa;
  --spectrum-fuchsia-1400: #ffebfc;
  --spectrum-magenta-100: #530329;
  --spectrum-magenta-200: #6a0034;
  --spectrum-magenta-300: #850041;
  --spectrum-magenta-400: #a1004e;
  --spectrum-magenta-500: #ba165d;
  --spectrum-magenta-600: #d12b72;
  --spectrum-magenta-700: #e34589;
  --spectrum-magenta-800: #f1619c;
  --spectrum-magenta-900: #fc7cad;
  --spectrum-magenta-1000: #ff98bf;
  --spectrum-magenta-1100: #ffb3cf;
  --spectrum-magenta-1200: #ffcadd;
  --spectrum-magenta-1300: #ffdde9;
  --spectrum-magenta-1400: #ffecf3;
  --spectrum-legacy-color-celery-400: var(--spectrum-celery-800);
  --spectrum-legacy-color-celery-500: var(--spectrum-celery-900);
  --spectrum-legacy-color-celery-600: var(--spectrum-celery-1000);
  --spectrum-legacy-color-celery-700: var(--spectrum-celery-1100);
  --spectrum-legacy-color-chartreuse-400: var(--spectrum-chartreuse-900);
  --spectrum-legacy-color-chartreuse-500: var(--spectrum-chartreuse-1000);
  --spectrum-legacy-color-chartreuse-600: var(--spectrum-chartreuse-1100);
  --spectrum-legacy-color-chartreuse-700: var(--spectrum-chartreuse-1200);
  --spectrum-legacy-color-yellow-400: var(--spectrum-yellow-1000);
  --spectrum-legacy-color-yellow-500: var(--spectrum-yellow-1100);
  --spectrum-legacy-color-yellow-600: var(--spectrum-yellow-1200);
  --spectrum-legacy-color-yellow-700: var(--spectrum-yellow-1300);
  --spectrum-legacy-color-magenta-400: var(--spectrum-magenta-600);
  --spectrum-legacy-color-magenta-500: var(--spectrum-magenta-700);
  --spectrum-legacy-color-magenta-600: var(--spectrum-magenta-800);
  --spectrum-legacy-color-magenta-700: var(--spectrum-magenta-900);
  --spectrum-legacy-color-fuchsia-400: var(--spectrum-fuchsia-600);
  --spectrum-legacy-color-fuchsia-500: var(--spectrum-fuchsia-700);
  --spectrum-legacy-color-fuchsia-600: var(--spectrum-fuchsia-800);
  --spectrum-legacy-color-fuchsia-700: var(--spectrum-fuchsia-900);
  --spectrum-legacy-color-purple-400: var(--spectrum-purple-600);
  --spectrum-legacy-color-purple-500: var(--spectrum-purple-700);
  --spectrum-legacy-color-purple-600: var(--spectrum-purple-800);
  --spectrum-legacy-color-purple-700: var(--spectrum-purple-900);
  --spectrum-legacy-color-indigo-400: var(--spectrum-indigo-600);
  --spectrum-legacy-color-indigo-500: var(--spectrum-indigo-700);
  --spectrum-legacy-color-indigo-600: var(--spectrum-indigo-800);
  --spectrum-legacy-color-indigo-700: var(--spectrum-indigo-900);
  --spectrum-legacy-color-red-400: var(--spectrum-red-600);
  --spectrum-legacy-color-red-500: var(--spectrum-red-700);
  --spectrum-legacy-color-red-600: var(--spectrum-red-800);
  --spectrum-legacy-color-red-700: var(--spectrum-red-900);
  --spectrum-legacy-color-orange-400: var(--spectrum-orange-800);
  --spectrum-legacy-color-orange-500: var(--spectrum-orange-900);
  --spectrum-legacy-color-orange-600: var(--spectrum-orange-1000);
  --spectrum-legacy-color-orange-700: var(--spectrum-orange-1100);
  --spectrum-legacy-color-green-400: var(--spectrum-green-700);
  --spectrum-legacy-color-green-500: var(--spectrum-green-800);
  --spectrum-legacy-color-green-600: var(--spectrum-green-900);
  --spectrum-legacy-color-green-700: var(--spectrum-green-1000);
  --spectrum-legacy-color-blue-400: var(--spectrum-blue-700);
  --spectrum-legacy-color-blue-500: var(--spectrum-blue-800);
  --spectrum-legacy-color-blue-600: var(--spectrum-blue-900);
  --spectrum-legacy-color-blue-700: var(--spectrum-blue-1000);
  --spectrum-alias-background-color-modal-overlay: #0009;
  --spectrum-alias-dropshadow-color: #000c;
  --spectrum-alias-background-color-hover-overlay: #ffffff14;
  --spectrum-alias-highlight-hover: #ffffff14;
  --spectrum-alias-highlight-active: #efefef26;
  --spectrum-alias-highlight-selected: #4096f333;
  --spectrum-alias-highlight-selected-hover: #4096f34d;
  --spectrum-alias-highlight-invalid: #dd211826;
  --spectrum-alias-text-highlight-color: #4096f34d;
  --spectrum-alias-background-color-quickactions: #1d1d1de6;
  --spectrum-alias-radial-reaction-color-default: #ebebeb99;
  --spectrum-alias-pasteboard-background-color: var(--spectrum-global-color-gray-50);
  --spectrum-alias-appframe-border-color: var(--spectrum-global-color-gray-50);
  --spectrum-alias-appframe-separator-color: var(--spectrum-global-color-gray-50);
  --spectrum-colorarea-border-color: #efefef1a;
  --spectrum-colorarea-border-color-hover: #efefef1a;
  --spectrum-colorarea-border-color-down: #efefef1a;
  --spectrum-colorarea-border-color-key-focus: #efefef1a;
  --spectrum-colorslider-border-color: #efefef1a;
  --spectrum-colorslider-border-color-hover: #efefef1a;
  --spectrum-colorslider-border-color-down: #efefef1a;
  --spectrum-colorslider-border-color-key-focus: #efefef1a;
  --spectrum-colorslider-vertical-border-color: #efefef1a;
  --spectrum-colorslider-vertical-border-color-hover: #efefef1a;
  --spectrum-colorslider-vertical-border-color-down: #efefef1a;
  --spectrum-colorslider-vertical-border-color-key-focus: #efefef1a;
  --spectrum-colorwheel-border-color: #efefef1a;
  --spectrum-colorwheel-border-color-hover: #efefef1a;
  --spectrum-colorwheel-border-color-down: #efefef1a;
  --spectrum-colorwheel-border-color-key-focus: #efefef1a;
  --spectrum-miller-column-item-background-color-selected: #2680eb1a;
  --spectrum-miller-column-item-background-color-selected-hover: #2680eb33;
  --spectrum-tabs-compact-selection-indicator-color: var(--spectrum-global-color-blue-500);
  --spectrum-tabs-compact-vertical-rule-color: var(--spectrum-global-color-gray-200);
  --spectrum-tabs-compact-vertical-emphasized-selection-indicator-color: var(--spectrum-global-color-blue-500);
  --spectrum-tabs-compact-vertical-emphasized-rule-color: var(--spectrum-global-color-gray-200);
  --spectrum-tabs-emphasized-selection-indicator-color: var(--spectrum-global-color-blue-500);
  --spectrum-tabs-quiet-compact-emphasized-selection-indicator-color: var(--spectrum-global-color-blue-500);
  --spectrum-tabs-quiet-compact-vertical-emphasized-selection-indicator-color: var(--spectrum-global-color-blue-500);
  --spectrum-tabs-quiet-emphasized-selection-indicator-color: var(--spectrum-global-color-blue-500);
  --spectrum-tabs-quiet-vertical-emphasized-selection-indicator-color: var(--spectrum-global-color-blue-500);
  --spectrum-well-background-color: #ebebeb05;
  --spectrum-well-border-color: #efefef0d;
  --spectrum-tray-background-color: var(--spectrum-global-color-gray-100);
  --react-spectrum-datepicker-placeholder-color: var(--spectrum-global-color-gray-600);
  --spectrum-accent-background-color-default: var(--spectrum-accent-color-600);
  --spectrum-accent-background-color-hover: var(--spectrum-accent-color-500);
  --spectrum-accent-background-color-down: var(--spectrum-accent-color-400);
  --spectrum-accent-background-color-key-focus: var(--spectrum-accent-color-500);
  --spectrum-neutral-background-color-default: var(--spectrum-gray-400);
  --spectrum-neutral-background-color-hover: var(--spectrum-gray-300);
  --spectrum-neutral-background-color-down: var(--spectrum-gray-200);
  --spectrum-neutral-background-color-key-focus: var(--spectrum-gray-300);
  --spectrum-neutral-subdued-background-color-default: var(--spectrum-gray-400);
  --spectrum-neutral-subdued-background-color-hover: var(--spectrum-gray-300);
  --spectrum-neutral-subdued-background-color-down: var(--spectrum-gray-200);
  --spectrum-neutral-subdued-background-color-key-focus: var(--spectrum-gray-300);
  --spectrum-negative-background-color-default: var(--spectrum-red-600);
  --spectrum-negative-background-color-hover: var(--spectrum-red-500);
  --spectrum-negative-background-color-down: var(--spectrum-red-400);
  --spectrum-negative-background-color-key-focus: var(--spectrum-red-500);
  --spectrum-positive-background-color-default: var(--spectrum-green-600);
  --spectrum-positive-background-color-hover: var(--spectrum-green-500);
  --spectrum-positive-background-color-down: var(--spectrum-green-400);
  --spectrum-positive-background-color-key-focus: var(--spectrum-green-500);
  --spectrum-informative-background-color-default: var(--spectrum-blue-600);
  --spectrum-informative-background-color-hover: var(--spectrum-blue-500);
  --spectrum-informative-background-color-down: var(--spectrum-blue-400);
  --spectrum-informative-background-color-key-focus: var(--spectrum-blue-500);
  --spectrum-gray-background-color-default: var(--spectrum-gray-700);
  --spectrum-red-background-color-default: var(--spectrum-red-700);
  --spectrum-orange-background-color-default: var(--spectrum-orange-800);
  --spectrum-yellow-background-color-default: var(--spectrum-yellow-1000);
  --spectrum-chartreuse-background-color-default: var(--spectrum-chartreuse-900);
  --spectrum-celery-background-color-default: var(--spectrum-celery-800);
  --spectrum-green-background-color-default: var(--spectrum-green-700);
  --spectrum-seafoam-background-color-default: var(--spectrum-seafoam-700);
  --spectrum-cyan-background-color-default: var(--spectrum-cyan-700);
  --spectrum-blue-background-color-default: var(--spectrum-blue-700);
  --spectrum-indigo-background-color-default: var(--spectrum-indigo-700);
  --spectrum-purple-background-color-default: var(--spectrum-purple-700);
  --spectrum-fuchsia-background-color-default: var(--spectrum-fuchsia-700);
  --spectrum-magenta-background-color-default: var(--spectrum-magenta-700);
  --spectrum-negative-visual-color: var(--spectrum-red-700);
  --spectrum-positive-visual-color: var(--spectrum-green-800);
  --spectrum-notice-visual-color: var(--spectrum-orange-900);
  --spectrum-informative-visual-color: var(--spectrum-blue-900);
  --spectrum-gray-visual-color: var(--spectrum-gray-600);
  --spectrum-red-visual-color: var(--spectrum-red-700);
  --spectrum-orange-visual-color: var(--spectrum-orange-900);
  --spectrum-yellow-visual-color: var(--spectrum-yellow-1100);
  --spectrum-chartreuse-visual-color: var(--spectrum-chartreuse-900);
  --spectrum-celery-visual-color: var(--spectrum-celery-800);
  --spectrum-green-visual-color: var(--spectrum-green-800);
  --spectrum-seafoam-visual-color: var(--spectrum-seafoam-800);
  --spectrum-cyan-visual-color: var(--spectrum-cyan-900);
  --spectrum-blue-visual-color: var(--spectrum-blue-900);
  --spectrum-indigo-visual-color: var(--spectrum-indigo-900);
  --spectrum-purple-visual-color: var(--spectrum-purple-900);
  --spectrum-fuchsia-visual-color: var(--spectrum-fuchsia-900);
  --spectrum-magenta-visual-color: var(--spectrum-magenta-900);
}

.zA6MfG_spectrum {
  --spectrum-global-animation-duration-0: 0s;
  --spectrum-global-animation-duration-100: .13s;
  --spectrum-global-animation-duration-200: .16s;
  --spectrum-global-animation-duration-300: .19s;
  --spectrum-global-animation-duration-400: .22s;
  --spectrum-global-animation-duration-500: .25s;
  --spectrum-global-animation-duration-600: .3s;
  --spectrum-global-animation-duration-700: .35s;
  --spectrum-global-animation-duration-800: .4s;
  --spectrum-global-animation-duration-900: .45s;
  --spectrum-global-animation-duration-1000: .5s;
  --spectrum-global-animation-duration-2000: 1s;
  --spectrum-global-animation-duration-4000: 2s;
  --spectrum-global-animation-ease-in-out: cubic-bezier(.45, 0, .4, 1);
  --spectrum-global-animation-ease-in: cubic-bezier(.5, 0, 1, 1);
  --spectrum-global-animation-ease-out: cubic-bezier(0, 0, .4, 1);
  --spectrum-global-animation-linear: cubic-bezier(0, 0, 1, 1);
  --spectrum-global-color-status: Verified;
  --spectrum-global-color-version: 5.1;
  --spectrum-global-color-static-black: #000;
  --spectrum-global-color-static-white: #fff;
  --spectrum-global-color-static-blue: #0057bf;
  --spectrum-global-color-static-gray-50: #fff;
  --spectrum-global-color-static-gray-75: #fff;
  --spectrum-global-color-static-gray-100: #fff;
  --spectrum-global-color-static-gray-200: #ebebeb;
  --spectrum-global-color-static-gray-300: #d9d9d9;
  --spectrum-global-color-static-gray-400: #b3b3b3;
  --spectrum-global-color-static-gray-500: #929292;
  --spectrum-global-color-static-gray-600: #6e6e6e;
  --spectrum-global-color-static-gray-700: #474747;
  --spectrum-global-color-static-gray-800: #222;
  --spectrum-global-color-static-gray-900: #000;
  --spectrum-global-color-static-blue-200: #82c1fb;
  --spectrum-global-color-static-blue-300: #62adf7;
  --spectrum-global-color-static-blue-400: #4297f4;
  --spectrum-global-color-static-blue-500: #1b7ff5;
  --spectrum-global-color-static-blue-600: #0469e3;
  --spectrum-global-color-static-blue-700: #0057be;
  --spectrum-global-color-static-blue-800: #004899;
  --spectrum-global-color-static-red-400: #ed4030;
  --spectrum-global-color-static-red-500: #d91c15;
  --spectrum-global-color-static-red-600: #bb0202;
  --spectrum-global-color-static-red-700: #9a0000;
  --spectrum-global-color-static-orange-400: #fa8b1a;
  --spectrum-global-color-static-orange-500: #e97500;
  --spectrum-global-color-static-orange-600: #d16100;
  --spectrum-global-color-static-orange-700: #b65000;
  --spectrum-global-color-static-green-400: #1da973;
  --spectrum-global-color-static-green-500: #009461;
  --spectrum-global-color-static-green-600: #007e50;
  --spectrum-global-color-static-green-700: #006941;
  --spectrum-global-color-static-celery-200: #7ee572;
  --spectrum-global-color-static-celery-300: #57d456;
  --spectrum-global-color-static-celery-400: #30c13d;
  --spectrum-global-color-static-celery-500: #0fac26;
  --spectrum-global-color-static-celery-600: #009614;
  --spectrum-global-color-static-celery-700: #00800f;
  --spectrum-global-color-static-chartreuse-300: #b0de1b;
  --spectrum-global-color-static-chartreuse-400: #9dcb0d;
  --spectrum-global-color-static-chartreuse-500: #8bb604;
  --spectrum-global-color-static-chartreuse-600: #7aa200;
  --spectrum-global-color-static-chartreuse-700: #6a8d00;
  --spectrum-global-color-static-yellow-200: #faed7b;
  --spectrum-global-color-static-yellow-300: #fae017;
  --spectrum-global-color-static-yellow-400: #eecd00;
  --spectrum-global-color-static-yellow-500: #ddb900;
  --spectrum-global-color-static-yellow-600: #c9a400;
  --spectrum-global-color-static-yellow-700: #b59000;
  --spectrum-global-color-static-magenta-200: #fd7faf;
  --spectrum-global-color-static-magenta-300: #f2629d;
  --spectrum-global-color-static-magenta-400: #e24487;
  --spectrum-global-color-static-magenta-500: #cd286f;
  --spectrum-global-color-static-magenta-600: #b30f59;
  --spectrum-global-color-static-magenta-700: #950048;
  --spectrum-global-color-static-fuchsia-400: #e45de6;
  --spectrum-global-color-static-fuchsia-500: #d33fd4;
  --spectrum-global-color-static-fuchsia-600: #bc27bb;
  --spectrum-global-color-static-fuchsia-700: #a30aa3;
  --spectrum-global-color-static-purple-400: #b279fa;
  --spectrum-global-color-static-purple-500: #a15df6;
  --spectrum-global-color-static-purple-600: #8e43ea;
  --spectrum-global-color-static-purple-700: #782bd8;
  --spectrum-global-color-static-purple-800: #6217be;
  --spectrum-global-color-static-indigo-200: #b2b5ff;
  --spectrum-global-color-static-indigo-300: #9b9fff;
  --spectrum-global-color-static-indigo-400: #8489fd;
  --spectrum-global-color-static-indigo-500: #6d73f6;
  --spectrum-global-color-static-indigo-600: #575de8;
  --spectrum-global-color-static-indigo-700: #444ad0;
  --spectrum-global-color-static-seafoam-200: #4bcec7;
  --spectrum-global-color-static-seafoam-300: #20bbb4;
  --spectrum-global-color-static-seafoam-400: #00a6a0;
  --spectrum-global-color-static-seafoam-500: #00918b;
  --spectrum-global-color-static-seafoam-600: #007c76;
  --spectrum-global-color-static-seafoam-700: #006763;
  --spectrum-global-color-opacity-100: 1;
  --spectrum-global-color-opacity-90: .9;
  --spectrum-global-color-opacity-80: .8;
  --spectrum-global-color-opacity-60: .6;
  --spectrum-global-color-opacity-50: .5;
  --spectrum-global-color-opacity-42: .42;
  --spectrum-global-color-opacity-40: .4;
  --spectrum-global-color-opacity-30: .3;
  --spectrum-global-color-opacity-25: .25;
  --spectrum-global-color-opacity-20: .2;
  --spectrum-global-color-opacity-15: .15;
  --spectrum-global-color-opacity-10: .1;
  --spectrum-global-color-opacity-8: .08;
  --spectrum-global-color-opacity-7: .07;
  --spectrum-global-color-opacity-6: .06;
  --spectrum-global-color-opacity-5: .05;
  --spectrum-global-color-opacity-4: .04;
  --spectrum-global-color-celery-400: var(--spectrum-celery-600);
  --spectrum-global-color-celery-500: var(--spectrum-celery-700);
  --spectrum-global-color-celery-600: var(--spectrum-celery-800);
  --spectrum-global-color-celery-700: var(--spectrum-celery-900);
  --spectrum-global-color-chartreuse-400: var(--spectrum-chartreuse-500);
  --spectrum-global-color-chartreuse-500: var(--spectrum-chartreuse-600);
  --spectrum-global-color-chartreuse-600: var(--spectrum-chartreuse-700);
  --spectrum-global-color-chartreuse-700: var(--spectrum-chartreuse-800);
  --spectrum-global-color-yellow-400: var(--spectrum-yellow-400);
  --spectrum-global-color-yellow-500: var(--spectrum-yellow-500);
  --spectrum-global-color-yellow-600: var(--spectrum-yellow-600);
  --spectrum-global-color-yellow-700: var(--spectrum-yellow-700);
  --spectrum-global-color-magenta-400: var(--spectrum-magenta-800);
  --spectrum-global-color-magenta-500: var(--spectrum-magenta-900);
  --spectrum-global-color-magenta-600: var(--spectrum-magenta-1000);
  --spectrum-global-color-magenta-700: var(--spectrum-magenta-1100);
  --spectrum-global-color-fuchsia-400: var(--spectrum-fuchsia-800);
  --spectrum-global-color-fuchsia-500: var(--spectrum-fuchsia-900);
  --spectrum-global-color-fuchsia-600: var(--spectrum-fuchsia-1000);
  --spectrum-global-color-fuchsia-700: var(--spectrum-fuchsia-1100);
  --spectrum-global-color-purple-400: var(--spectrum-purple-800);
  --spectrum-global-color-purple-500: var(--spectrum-purple-900);
  --spectrum-global-color-purple-600: var(--spectrum-purple-1000);
  --spectrum-global-color-purple-700: var(--spectrum-purple-1100);
  --spectrum-global-color-indigo-400: var(--spectrum-indigo-800);
  --spectrum-global-color-indigo-500: var(--spectrum-indigo-900);
  --spectrum-global-color-indigo-600: var(--spectrum-indigo-1000);
  --spectrum-global-color-indigo-700: var(--spectrum-indigo-1100);
  --spectrum-global-color-seafoam-400: var(--spectrum-seafoam-700);
  --spectrum-global-color-seafoam-500: var(--spectrum-seafoam-800);
  --spectrum-global-color-seafoam-600: var(--spectrum-seafoam-900);
  --spectrum-global-color-seafoam-700: var(--spectrum-seafoam-1000);
  --spectrum-global-color-red-400: var(--spectrum-red-800);
  --spectrum-global-color-red-500: var(--spectrum-red-900);
  --spectrum-global-color-red-600: var(--spectrum-red-1000);
  --spectrum-global-color-red-700: var(--spectrum-red-1100);
  --spectrum-global-color-orange-400: var(--spectrum-orange-600);
  --spectrum-global-color-orange-500: var(--spectrum-orange-700);
  --spectrum-global-color-orange-600: var(--spectrum-orange-800);
  --spectrum-global-color-orange-700: var(--spectrum-orange-900);
  --spectrum-global-color-green-400: var(--spectrum-green-800);
  --spectrum-global-color-green-500: var(--spectrum-green-900);
  --spectrum-global-color-green-600: var(--spectrum-green-1000);
  --spectrum-global-color-green-700: var(--spectrum-green-1100);
  --spectrum-global-color-blue-400: var(--spectrum-blue-800);
  --spectrum-global-color-blue-500: var(--spectrum-blue-900);
  --spectrum-global-color-blue-600: var(--spectrum-blue-1000);
  --spectrum-global-color-blue-700: var(--spectrum-blue-1100);
  --spectrum-global-color-gray-50: var(--spectrum-gray-50);
  --spectrum-global-color-gray-75: var(--spectrum-gray-75);
  --spectrum-global-color-gray-100: var(--spectrum-gray-100);
  --spectrum-global-color-gray-200: var(--spectrum-gray-200);
  --spectrum-global-color-gray-300: var(--spectrum-gray-300);
  --spectrum-global-color-gray-400: var(--spectrum-gray-400);
  --spectrum-global-color-gray-500: var(--spectrum-gray-500);
  --spectrum-global-color-gray-600: var(--spectrum-gray-600);
  --spectrum-global-color-gray-700: var(--spectrum-gray-700);
  --spectrum-global-color-gray-800: var(--spectrum-gray-800);
  --spectrum-global-color-gray-900: var(--spectrum-gray-900);
  --spectrum-accent-color-100: var(--spectrum-blue-100);
  --spectrum-accent-color-200: var(--spectrum-blue-200);
  --spectrum-accent-color-300: var(--spectrum-blue-300);
  --spectrum-accent-color-400: var(--spectrum-blue-400);
  --spectrum-accent-color-500: var(--spectrum-blue-500);
  --spectrum-accent-color-600: var(--spectrum-blue-600);
  --spectrum-accent-color-700: var(--spectrum-blue-700);
  --spectrum-accent-color-800: var(--spectrum-blue-800);
  --spectrum-accent-color-900: var(--spectrum-blue-900);
  --spectrum-accent-color-1000: var(--spectrum-blue-1000);
  --spectrum-accent-color-1100: var(--spectrum-blue-1100);
  --spectrum-accent-color-1200: var(--spectrum-blue-1200);
  --spectrum-accent-color-1300: var(--spectrum-blue-1300);
  --spectrum-accent-color-1400: var(--spectrum-blue-1400);
  --spectrum-semantic-negative-color-background: var(--spectrum-negative-background-color-default);
  --spectrum-semantic-negative-color-default: var(--spectrum-red-900);
  --spectrum-semantic-negative-color-state-hover: var(--spectrum-red-1000);
  --spectrum-semantic-negative-color-dark: var(--spectrum-red-1000);
  --spectrum-semantic-negative-color-border: var(--spectrum-red-800);
  --spectrum-semantic-negative-color-icon: var(--spectrum-negative-visual-color);
  --spectrum-semantic-negative-color-status: var(--spectrum-negative-visual-color);
  --spectrum-semantic-negative-color-text-large: var(--spectrum-red-900);
  --spectrum-semantic-negative-color-text-small: var(--spectrum-red-900);
  --spectrum-semantic-negative-color-state-down: var(--spectrum-red-1100);
  --spectrum-semantic-negative-color-state-focus: var(--spectrum-red-1000);
  --spectrum-semantic-notice-color-background: var(--spectrum-global-color-static-orange-600, #da7b11);
  --spectrum-semantic-notice-color-default: var(--spectrum-orange-700);
  --spectrum-semantic-notice-color-dark: var(--spectrum-orange-800);
  --spectrum-semantic-notice-color-border: var(--spectrum-orange-600);
  --spectrum-semantic-notice-color-icon: var(--spectrum-notice-visual-color);
  --spectrum-semantic-notice-color-status: var(--spectrum-notice-visual-color);
  --spectrum-semantic-notice-color-text-large: var(--spectrum-orange-700);
  --spectrum-semantic-notice-color-text-small: var(--spectrum-orange-800);
  --spectrum-semantic-notice-color-state-down: var(--spectrum-orange-900);
  --spectrum-semantic-notice-color-state-focus: var(--spectrum-orange-600);
  --spectrum-semantic-positive-color-background: var(--spectrum-positive-background-color-default);
  --spectrum-semantic-positive-color-default: var(--spectrum-green-900);
  --spectrum-semantic-positive-color-dark: var(--spectrum-green-1000);
  --spectrum-semantic-positive-color-border: var(--spectrum-green-800);
  --spectrum-semantic-positive-color-icon: var(--spectrum-positive-visual-color);
  --spectrum-semantic-positive-color-status: var(--spectrum-positive-visual-color);
  --spectrum-semantic-positive-color-text-large: var(--spectrum-green-900);
  --spectrum-semantic-positive-color-text-small: var(--spectrum-green-1000);
  --spectrum-semantic-positive-color-state-down: var(--spectrum-green-1100);
  --spectrum-semantic-positive-color-state-focus: var(--spectrum-green-800);
  --spectrum-semantic-informative-color-background: var(--spectrum-informative-background-color-default);
  --spectrum-semantic-informative-color-default: var(--spectrum-blue-900);
  --spectrum-semantic-informative-color-dark: var(--spectrum-blue-1000);
  --spectrum-semantic-informative-color-border: var(--spectrum-blue-800);
  --spectrum-semantic-informative-color-icon: var(--spectrum-informative-visual-color);
  --spectrum-semantic-informative-color-status: var(--spectrum-informative-visual-color);
  --spectrum-semantic-informative-color-text-large: var(--spectrum-blue-900);
  --spectrum-semantic-informative-color-text-small: var(--spectrum-blue-1000);
  --spectrum-semantic-informative-color-state-down: var(--spectrum-blue-1100);
  --spectrum-semantic-informative-color-state-focus: var(--spectrum-blue-800);
  --spectrum-semantic-cta-color-background-default: var(--spectrum-accent-background-color-default);
  --spectrum-semantic-cta-color-background-hover: var(--spectrum-accent-background-color-hover);
  --spectrum-semantic-cta-color-background-down: var(--spectrum-accent-background-color-down);
  --spectrum-semantic-cta-color-background-key-focus: var(--spectrum-accent-background-color-key-focus);
  --spectrum-semantic-background-color-key-focus: var(--spectrum-global-color-static-blue-600, #1473e6);
  --spectrum-semantic-neutral-color-background: var(--spectrum-neutral-subdued-background-color-default);
  --spectrum-semantic-presence-color-1: var(--spectrum-global-color-static-red-500, #e34850);
  --spectrum-semantic-presence-color-2: var(--spectrum-global-color-static-orange-400, #f29423);
  --spectrum-semantic-presence-color-3: var(--spectrum-global-color-static-yellow-400, #edcc00);
  --spectrum-semantic-presence-color-4: #4bcca2;
  --spectrum-semantic-presence-color-5: #00c7ff;
  --spectrum-semantic-presence-color-6: #008cb8;
  --spectrum-semantic-presence-color-7: #7e4bf3;
  --spectrum-semantic-presence-color-8: var(--spectrum-global-color-static-fuchsia-600, #b130bd);
  --spectrum-global-dimension-static-size-0: 0px;
  --spectrum-global-dimension-static-size-10: 1px;
  --spectrum-global-dimension-static-size-25: 2px;
  --spectrum-global-dimension-static-size-50: 4px;
  --spectrum-global-dimension-static-size-40: 3px;
  --spectrum-global-dimension-static-size-65: 5px;
  --spectrum-global-dimension-static-size-100: 8px;
  --spectrum-global-dimension-static-size-115: 9px;
  --spectrum-global-dimension-static-size-125: 10px;
  --spectrum-global-dimension-static-size-130: 11px;
  --spectrum-global-dimension-static-size-150: 12px;
  --spectrum-global-dimension-static-size-160: 13px;
  --spectrum-global-dimension-static-size-175: 14px;
  --spectrum-global-dimension-static-size-200: 16px;
  --spectrum-global-dimension-static-size-225: 18px;
  --spectrum-global-dimension-static-size-250: 20px;
  --spectrum-global-dimension-static-size-300: 24px;
  --spectrum-global-dimension-static-size-400: 32px;
  --spectrum-global-dimension-static-size-450: 36px;
  --spectrum-global-dimension-static-size-500: 40px;
  --spectrum-global-dimension-static-size-550: 44px;
  --spectrum-global-dimension-static-size-600: 48px;
  --spectrum-global-dimension-static-size-700: 56px;
  --spectrum-global-dimension-static-size-800: 64px;
  --spectrum-global-dimension-static-size-900: 72px;
  --spectrum-global-dimension-static-size-1000: 80px;
  --spectrum-global-dimension-static-size-1200: 96px;
  --spectrum-global-dimension-static-size-1700: 136px;
  --spectrum-global-dimension-static-size-2400: 192px;
  --spectrum-global-dimension-static-size-2600: 208px;
  --spectrum-global-dimension-static-size-3400: 272px;
  --spectrum-global-dimension-static-size-3600: 288px;
  --spectrum-global-dimension-static-size-4600: 368px;
  --spectrum-global-dimension-static-size-5000: 400px;
  --spectrum-global-dimension-static-size-6000: 480px;
  --spectrum-global-dimension-static-font-size-50: 11px;
  --spectrum-global-dimension-static-font-size-75: 12px;
  --spectrum-global-dimension-static-font-size-100: 14px;
  --spectrum-global-dimension-static-font-size-150: 15px;
  --spectrum-global-dimension-static-font-size-200: 16px;
  --spectrum-global-dimension-static-font-size-300: 18px;
  --spectrum-global-dimension-static-font-size-400: 20px;
  --spectrum-global-dimension-static-font-size-500: 22px;
  --spectrum-global-dimension-static-font-size-600: 25px;
  --spectrum-global-dimension-static-font-size-700: 28px;
  --spectrum-global-dimension-static-font-size-800: 32px;
  --spectrum-global-dimension-static-font-size-900: 36px;
  --spectrum-global-dimension-static-font-size-1000: 40px;
  --spectrum-global-dimension-static-percent-50: 50%;
  --spectrum-global-dimension-static-percent-100: 100%;
  --spectrum-global-dimension-static-breakpoint-xsmall: 304px;
  --spectrum-global-dimension-static-breakpoint-small: 768px;
  --spectrum-global-dimension-static-breakpoint-medium: 1280px;
  --spectrum-global-dimension-static-breakpoint-large: 1768px;
  --spectrum-global-dimension-static-breakpoint-xlarge: 2160px;
  --spectrum-global-dimension-static-grid-columns: 12;
  --spectrum-global-dimension-static-grid-fluid-width: 100%;
  --spectrum-global-dimension-static-grid-fixed-max-width: 1280px;
  --spectrum-global-font-family-base: adobe-clean, "Source Sans Pro", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Ubuntu, "Trebuchet MS", "Lucida Grande", sans-serif;
  --spectrum-global-font-family-serif: adobe-clean-serif, "Source Serif Pro", Georgia, serif;
  --spectrum-global-font-family-code: "Source Code Pro", Monaco, monospace;
  --spectrum-global-font-weight-thin: 100;
  --spectrum-global-font-weight-ultra-light: 200;
  --spectrum-global-font-weight-light: 300;
  --spectrum-global-font-weight-regular: 400;
  --spectrum-global-font-weight-medium: 500;
  --spectrum-global-font-weight-semi-bold: 600;
  --spectrum-global-font-weight-bold: 700;
  --spectrum-global-font-weight-extra-bold: 800;
  --spectrum-global-font-weight-black: 900;
  --spectrum-global-font-style-regular: normal;
  --spectrum-global-font-style-italic: italic;
  --spectrum-global-font-letter-spacing-none: 0;
  --spectrum-global-font-letter-spacing-small: .0125em;
  --spectrum-global-font-letter-spacing-han: .05em;
  --spectrum-global-font-letter-spacing-medium: .06em;
  --spectrum-global-font-line-height-large: 1.7;
  --spectrum-global-font-line-height-medium: 1.5;
  --spectrum-global-font-line-height-small: 1.3;
  --spectrum-global-font-multiplier-25: .25em;
  --spectrum-global-font-multiplier-75: .75em;
  --spectrum-alias-border-size-thin: var(--spectrum-global-dimension-static-size-10, 1px);
  --spectrum-alias-border-size-thick: var(--spectrum-global-dimension-static-size-25, 2px);
  --spectrum-alias-border-size-thicker: var(--spectrum-global-dimension-static-size-50, 4px);
  --spectrum-alias-border-size-thickest: var(--spectrum-global-dimension-static-size-100, 8px);
  --spectrum-alias-input-border-size: var(--spectrum-global-dimension-static-size-10, 1px);
  --spectrum-alias-border-offset-thin: var(--spectrum-global-dimension-static-size-25, 2px);
  --spectrum-alias-border-offset-thick: var(--spectrum-global-dimension-static-size-50, 4px);
  --spectrum-alias-border-offset-thicker: var(--spectrum-global-dimension-static-size-100, 8px);
  --spectrum-alias-border-offset-thickest: var(--spectrum-global-dimension-static-size-200, 16px);
  --spectrum-alias-grid-baseline: var(--spectrum-global-dimension-static-size-100, 8px);
  --spectrum-alias-grid-gutter-xsmall: var(--spectrum-global-dimension-static-size-200, 16px);
  --spectrum-alias-grid-gutter-small: var(--spectrum-global-dimension-static-size-300, 24px);
  --spectrum-alias-grid-gutter-medium: var(--spectrum-global-dimension-static-size-400, 32px);
  --spectrum-alias-grid-gutter-large: var(--spectrum-global-dimension-static-size-500, 40px);
  --spectrum-alias-grid-gutter-xlarge: var(--spectrum-global-dimension-static-size-600, 48px);
  --spectrum-alias-grid-margin-xsmall: var(--spectrum-global-dimension-static-size-200, 16px);
  --spectrum-alias-grid-margin-small: var(--spectrum-global-dimension-static-size-300, 24px);
  --spectrum-alias-grid-margin-medium: var(--spectrum-global-dimension-static-size-400, 32px);
  --spectrum-alias-grid-margin-large: var(--spectrum-global-dimension-static-size-500, 40px);
  --spectrum-alias-grid-margin-xlarge: var(--spectrum-global-dimension-static-size-600, 48px);
  --spectrum-alias-grid-layout-region-margin-bottom-xsmall: var(--spectrum-global-dimension-static-size-200, 16px);
  --spectrum-alias-grid-layout-region-margin-bottom-small: var(--spectrum-global-dimension-static-size-300, 24px);
  --spectrum-alias-grid-layout-region-margin-bottom-medium: var(--spectrum-global-dimension-static-size-400, 32px);
  --spectrum-alias-grid-layout-region-margin-bottom-large: var(--spectrum-global-dimension-static-size-500, 40px);
  --spectrum-alias-grid-layout-region-margin-bottom-xlarge: var(--spectrum-global-dimension-static-size-600, 48px);
  --spectrum-alias-radial-reaction-size-default: var(--spectrum-global-dimension-static-size-550, 44px);
  --spectrum-alias-font-family-ar: var(--spectrum-global-font-font-family-ar);
  --spectrum-alias-font-family-he: var(--spectrum-global-font-font-family-he);
  --spectrum-alias-font-family-zh: var(--spectrum-global-font-font-family-zh);
  --spectrum-alias-font-family-zhhans: var(--spectrum-global-font-font-family-zhhans);
  --spectrum-alias-font-family-ko: var(--spectrum-global-font-font-family-ko);
  --spectrum-alias-font-family-ja: var(--spectrum-global-font-font-family-ja);
  --spectrum-alias-font-family-condensed: var(--spectrum-global-font-font-family-condensed);
  --spectrum-alias-line-height-body: var(--spectrum-global-font-line-height-medium, 1.5);
  --spectrum-alias-line-height-title: var(--spectrum-global-font-line-height-small, 1.3);
  --spectrum-alias-body-han-text-line-height: var(--spectrum-global-font-line-height-large, 1.7);
  --spectrum-alias-body-text-font-family: var(--spectrum-global-font-family-base, adobe-clean, "Source Sans Pro", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Ubuntu, "Trebuchet MS", "Lucida Grande", sans-serif);
  --spectrum-alias-body-text-line-height: var(--spectrum-global-font-line-height-medium, 1.5);
  --spectrum-alias-body-text-font-weight: var(--spectrum-global-font-weight-regular, 400);
  --spectrum-alias-body-text-font-weight-strong: var(--spectrum-global-font-weight-bold, 700);
  --spectrum-alias-button-text-line-height: var(--spectrum-global-font-line-height-small, 1.3);
  --spectrum-alias-heading-han-text-line-height: var(--spectrum-global-font-line-height-medium, 1.5);
  --spectrum-alias-heading-text-line-height: var(--spectrum-global-font-line-height-small, 1.3);
  --spectrum-alias-heading-text-font-weight-regular: var(--spectrum-global-font-weight-bold, 700);
  --spectrum-alias-heading-text-font-weight-regular-strong: var(--spectrum-global-font-weight-black, 900);
  --spectrum-alias-heading-text-font-weight-quiet: var(--spectrum-global-font-weight-light, 300);
  --spectrum-alias-heading-text-font-weight-quiet-strong: var(--spectrum-global-font-weight-bold, 700);
  --spectrum-alias-heading-text-font-weight-strong: var(--spectrum-global-font-weight-black, 900);
  --spectrum-alias-heading-text-font-weight-strong-strong: var(--spectrum-global-font-weight-black, 900);
  --spectrum-alias-subheading-text-font-weight: var(--spectrum-global-font-weight-bold, 700);
  --spectrum-alias-subheading-text-font-weight-strong: var(--spectrum-global-font-weight-black, 900);
  --spectrum-alias-detail-text-font-weight: var(--spectrum-global-font-weight-bold, 700);
  --spectrum-alias-detail-text-font-weight-light: var(--spectrum-global-font-weight-regular, 400);
  --spectrum-alias-detail-text-font-weight-strong: var(--spectrum-global-font-weight-black, 900);
  --spectrum-alias-serif-text-font-family: var(--spectrum-global-font-family-serif, adobe-clean-serif, "Source Serif Pro", Georgia, serif);
  --spectrum-alias-article-text-font-family: var(--spectrum-global-font-family-serif, adobe-clean-serif, "Source Serif Pro", Georgia, serif);
  --spectrum-alias-article-body-text-font-weight: var(--spectrum-global-font-weight-regular, 400);
  --spectrum-alias-article-body-text-font-weight-strong: var(--spectrum-global-font-weight-black, 900);
  --spectrum-alias-article-heading-text-font-weight: var(--spectrum-global-font-weight-bold, 700);
  --spectrum-alias-article-heading-text-font-weight-strong: var(--spectrum-global-font-weight-black, 900);
  --spectrum-alias-article-heading-text-font-weight-quiet: var(--spectrum-global-font-weight-regular, 400);
  --spectrum-alias-article-heading-text-font-weight-quiet-strong: var(--spectrum-global-font-weight-bold, 700);
  --spectrum-alias-article-subheading-text-font-weight: var(--spectrum-global-font-weight-bold, 700);
  --spectrum-alias-article-subheading-text-font-weight-strong: var(--spectrum-global-font-weight-black, 900);
  --spectrum-alias-article-detail-text-font-weight: var(--spectrum-global-font-weight-regular, 400);
  --spectrum-alias-article-detail-text-font-weight-strong: var(--spectrum-global-font-weight-bold, 700);
  --spectrum-alias-code-text-font-family: var(--spectrum-global-font-family-code, "Source Code Pro", Monaco, monospace);
  --spectrum-alias-han-heading-text-font-weight-regular: var(--spectrum-global-font-weight-bold, 700);
  --spectrum-alias-han-heading-text-font-weight-regular-emphasis: var(--spectrum-global-font-weight-extra-bold, 800);
  --spectrum-alias-han-heading-text-font-weight-regular-strong: var(--spectrum-global-font-weight-black, 900);
  --spectrum-alias-han-heading-text-font-weight-quiet: var(--spectrum-global-font-weight-light, 300);
  --spectrum-alias-han-heading-text-font-weight-quiet-emphasis: var(--spectrum-global-font-weight-regular, 400);
  --spectrum-alias-han-heading-text-font-weight-quiet-strong: var(--spectrum-global-font-weight-bold, 700);
  --spectrum-alias-han-heading-text-font-weight-light: var(--spectrum-global-font-weight-light, 300);
  --spectrum-alias-han-heading-text-font-weight-light-emphasis: var(--spectrum-global-font-weight-regular, 400);
  --spectrum-alias-han-heading-text-font-weight-light-strong: var(--spectrum-global-font-weight-bold, 700);
  --spectrum-alias-han-heading-text-font-weight-strong: var(--spectrum-global-font-weight-black, 900);
  --spectrum-alias-han-heading-text-font-weight-strong-emphasis: var(--spectrum-global-font-weight-black, 900);
  --spectrum-alias-han-heading-text-font-weight-strong-strong: var(--spectrum-global-font-weight-black, 900);
  --spectrum-alias-han-heading-text-font-weight-heavy: var(--spectrum-global-font-weight-black, 900);
  --spectrum-alias-han-heading-text-font-weight-heavy-emphasis: var(--spectrum-global-font-weight-black, 900);
  --spectrum-alias-han-heading-text-font-weight-heavy-strong: var(--spectrum-global-font-weight-black, 900);
  --spectrum-alias-han-body-text-font-weight-regular: var(--spectrum-global-font-weight-regular, 400);
  --spectrum-alias-han-body-text-font-weight-emphasis: var(--spectrum-global-font-weight-bold, 700);
  --spectrum-alias-han-body-text-font-weight-strong: var(--spectrum-global-font-weight-black, 900);
  --spectrum-alias-han-subheading-text-font-weight-regular: var(--spectrum-global-font-weight-bold, 700);
  --spectrum-alias-han-subheading-text-font-weight-emphasis: var(--spectrum-global-font-weight-extra-bold, 800);
  --spectrum-alias-han-subheading-text-font-weight-strong: var(--spectrum-global-font-weight-black, 900);
  --spectrum-alias-han-detail-text-font-weight: var(--spectrum-global-font-weight-regular, 400);
  --spectrum-alias-han-detail-text-font-weight-emphasis: var(--spectrum-global-font-weight-bold, 700);
  --spectrum-alias-han-detail-text-font-weight-strong: var(--spectrum-global-font-weight-black, 900);
  --spectrum-alias-code-text-font-weight-regular: var(--spectrum-global-font-weight-regular, 400);
  --spectrum-alias-code-text-font-weight-strong: var(--spectrum-global-font-weight-bold, 700);
  --spectrum-alias-code-text-line-height: var(--spectrum-global-font-line-height-medium, 1.5);
  --spectrum-alias-heading-margin-bottom: var(--spectrum-global-font-multiplier-25, .25em);
  --spectrum-alias-body-margin-bottom: var(--spectrum-global-font-multiplier-75, .75em);
  --spectrum-alias-focus-ring-gap: var(--spectrum-global-dimension-static-size-25, 2px);
  --spectrum-alias-input-focusring-gap: var(--spectrum-global-dimension-static-size-0, 0px);
  --spectrum-alias-focus-ring-size: var(--spectrum-global-dimension-static-size-25, 2px);
  --spectrum-alias-input-focusring-size: var(--spectrum-global-dimension-static-size-10, 1px);
  --spectrum-alias-loupe-entry-animation-duration: var(--spectrum-global-animation-duration-300, .19s);
  --spectrum-alias-loupe-exit-animation-duration: var(--spectrum-global-animation-duration-300, .19s);
}

.zA6MfG_spectrum--medium, .zA6MfG_spectrum--large {
  --spectrum-alias-dropshadow-blur: var(--spectrum-global-dimension-size-50);
  --spectrum-alias-dropshadow-offset-y: var(--spectrum-global-dimension-size-10);
  --spectrum-alias-font-size-default: var(--spectrum-global-dimension-font-size-100);
  --spectrum-alias-line-height-small: var(--spectrum-global-dimension-size-200);
  --spectrum-alias-line-height-medium: var(--spectrum-global-dimension-size-250);
  --spectrum-alias-line-height-large: var(--spectrum-global-dimension-size-300);
  --spectrum-alias-line-height-xlarge: var(--spectrum-global-dimension-size-400);
  --spectrum-alias-line-height-xxlarge: var(--spectrum-global-dimension-size-600);
  --spectrum-alias-layout-label-gap-size: var(--spectrum-global-dimension-size-100);
  --spectrum-alias-pill-button-text-size: var(--spectrum-global-dimension-font-size-100);
  --spectrum-alias-pill-button-text-baseline: var(--spectrum-global-dimension-static-size-150, 12px);
  --spectrum-alias-border-radius-xsmall: var(--spectrum-global-dimension-size-10);
  --spectrum-alias-border-radius-small: var(--spectrum-global-dimension-size-25);
  --spectrum-alias-border-radius-regular: var(--spectrum-global-dimension-size-50);
  --spectrum-alias-border-radius-medium: var(--spectrum-global-dimension-size-100);
  --spectrum-alias-border-radius-large: var(--spectrum-global-dimension-size-200);
  --spectrum-alias-single-line-height: var(--spectrum-global-dimension-size-400);
  --spectrum-alias-single-line-width: var(--spectrum-global-dimension-size-2400);
  --spectrum-alias-workflow-icon-size: var(--spectrum-global-dimension-size-225);
  --spectrum-alias-heading-display1-text-size: var(--spectrum-global-dimension-font-size-1300);
  --spectrum-alias-heading-xxxl-text-size: var(--spectrum-global-dimension-font-size-1300);
  --spectrum-alias-heading-han-display1-text-size: var(--spectrum-global-dimension-font-size-1300);
  --spectrum-alias-heading-han-xxxl-text-size: var(--spectrum-global-dimension-font-size-1300);
  --spectrum-alias-heading-han-display1-margin-top: var(--spectrum-global-dimension-font-size-1200);
  --spectrum-alias-heading-han-xxxl-margin-top: var(--spectrum-global-dimension-font-size-1200);
  --spectrum-alias-heading-display1-margin-top: var(--spectrum-global-dimension-font-size-1200);
  --spectrum-alias-heading-xxxl-margin-top: var(--spectrum-global-dimension-font-size-1200);
  --spectrum-alias-heading-display2-text-size: var(--spectrum-global-dimension-font-size-1100);
  --spectrum-alias-heading-xxl-text-size: var(--spectrum-global-dimension-font-size-1100);
  --spectrum-alias-heading-display2-margin-top: var(--spectrum-global-dimension-font-size-900);
  --spectrum-alias-heading-xxl-margin-top: var(--spectrum-global-dimension-font-size-900);
  --spectrum-alias-heading-han-display2-text-size: var(--spectrum-global-dimension-font-size-900);
  --spectrum-alias-heading-han-xxl-text-size: var(--spectrum-global-dimension-font-size-900);
  --spectrum-alias-heading-han-display2-margin-top: var(--spectrum-global-dimension-font-size-800);
  --spectrum-alias-heading-han-xxl-margin-top: var(--spectrum-global-dimension-font-size-800);
  --spectrum-alias-heading1-text-size: var(--spectrum-global-dimension-font-size-900);
  --spectrum-alias-heading-xl-text-size: var(--spectrum-global-dimension-font-size-900);
  --spectrum-alias-heading1-margin-top: var(--spectrum-global-dimension-font-size-800);
  --spectrum-alias-heading-xl-margin-top: var(--spectrum-global-dimension-font-size-800);
  --spectrum-alias-heading1-han-text-size: var(--spectrum-global-dimension-font-size-800);
  --spectrum-alias-heading-han-xl-text-size: var(--spectrum-global-dimension-font-size-800);
  --spectrum-alias-heading1-han-margin-top: var(--spectrum-global-dimension-font-size-700);
  --spectrum-alias-heading-han-xl-margin-top: var(--spectrum-global-dimension-font-size-700);
  --spectrum-alias-heading2-text-size: var(--spectrum-global-dimension-font-size-700);
  --spectrum-alias-heading-l-text-size: var(--spectrum-global-dimension-font-size-700);
  --spectrum-alias-heading2-margin-top: var(--spectrum-global-dimension-font-size-600);
  --spectrum-alias-heading-l-margin-top: var(--spectrum-global-dimension-font-size-600);
  --spectrum-alias-heading2-han-text-size: var(--spectrum-global-dimension-font-size-600);
  --spectrum-alias-heading-han-l-text-size: var(--spectrum-global-dimension-font-size-600);
  --spectrum-alias-heading2-han-margin-top: var(--spectrum-global-dimension-font-size-500);
  --spectrum-alias-heading-han-l-margin-top: var(--spectrum-global-dimension-font-size-500);
  --spectrum-alias-heading3-text-size: var(--spectrum-global-dimension-font-size-500);
  --spectrum-alias-heading-m-text-size: var(--spectrum-global-dimension-font-size-500);
  --spectrum-alias-heading3-margin-top: var(--spectrum-global-dimension-font-size-400);
  --spectrum-alias-heading-m-margin-top: var(--spectrum-global-dimension-font-size-400);
  --spectrum-alias-heading3-han-text-size: var(--spectrum-global-dimension-font-size-400);
  --spectrum-alias-heading-han-m-text-size: var(--spectrum-global-dimension-font-size-400);
  --spectrum-alias-heading3-han-margin-top: var(--spectrum-global-dimension-font-size-300);
  --spectrum-alias-heading-han-m-margin-top: var(--spectrum-global-dimension-font-size-300);
  --spectrum-alias-heading4-text-size: var(--spectrum-global-dimension-font-size-300);
  --spectrum-alias-heading-s-text-size: var(--spectrum-global-dimension-font-size-300);
  --spectrum-alias-heading4-margin-top: var(--spectrum-global-dimension-font-size-200);
  --spectrum-alias-heading-s-margin-top: var(--spectrum-global-dimension-font-size-200);
  --spectrum-alias-heading5-text-size: var(--spectrum-global-dimension-font-size-200);
  --spectrum-alias-heading-xs-text-size: var(--spectrum-global-dimension-font-size-200);
  --spectrum-alias-heading5-margin-top: var(--spectrum-global-dimension-font-size-100);
  --spectrum-alias-heading-xs-margin-top: var(--spectrum-global-dimension-font-size-100);
  --spectrum-alias-heading6-text-size: var(--spectrum-global-dimension-font-size-100);
  --spectrum-alias-heading-xxs-text-size: var(--spectrum-global-dimension-font-size-100);
  --spectrum-alias-heading6-margin-top: var(--spectrum-global-dimension-font-size-75);
  --spectrum-alias-heading-xxs-margin-top: var(--spectrum-global-dimension-font-size-75);
  --spectrum-alias-avatar-size-50: var(--spectrum-global-dimension-size-200);
  --spectrum-alias-avatar-size-75: var(--spectrum-global-dimension-size-225);
  --spectrum-alias-avatar-size-200: var(--spectrum-global-dimension-size-275);
  --spectrum-alias-avatar-size-300: var(--spectrum-global-dimension-size-325);
  --spectrum-alias-avatar-size-500: var(--spectrum-global-dimension-size-400);
  --spectrum-alias-avatar-size-700: var(--spectrum-global-dimension-size-500);
}

.zA6MfG_spectrum--darkest, .zA6MfG_spectrum--dark, .zA6MfG_spectrum--light, .zA6MfG_spectrum--lightest {
  --spectrum-alias-background-color-default: var(--spectrum-gray-100);
  --spectrum-alias-background-color-disabled: var(--spectrum-gray-200);
  --spectrum-alias-background-color-transparent: transparent;
  --spectrum-alias-background-color-label-gray: #707070;
  --spectrum-alias-background-color-quickactions-overlay: #0003;
  --spectrum-alias-placeholder-text-color: var(--spectrum-gray-800);
  --spectrum-alias-placeholder-text-color-hover: var(--spectrum-gray-900);
  --spectrum-alias-placeholder-text-color-down: var(--spectrum-gray-900);
  --spectrum-alias-placeholder-text-color-selected: var(--spectrum-gray-800);
  --spectrum-alias-label-text-color: var(--spectrum-gray-700);
  --spectrum-alias-text-color: var(--spectrum-gray-800);
  --spectrum-alias-text-color-hover: var(--spectrum-gray-900);
  --spectrum-alias-text-color-down: var(--spectrum-gray-900);
  --spectrum-alias-text-color-key-focus: var(--spectrum-blue-1000);
  --spectrum-alias-text-color-mouse-focus: var(--spectrum-blue-1000);
  --spectrum-alias-text-color-disabled: var(--spectrum-gray-400);
  --spectrum-alias-text-color-invalid: var(--spectrum-red-900);
  --spectrum-alias-text-color-selected: var(--spectrum-blue-1000);
  --spectrum-alias-text-color-selected-neutral: var(--spectrum-gray-900);
  --spectrum-alias-title-text-color: var(--spectrum-gray-900);
  --spectrum-alias-heading-text-color: var(--spectrum-gray-900);
  --spectrum-alias-border-color: var(--spectrum-gray-500);
  --spectrum-alias-border-color-hover: var(--spectrum-gray-600);
  --spectrum-alias-border-color-down: var(--spectrum-gray-600);
  --spectrum-alias-border-color-focus: var(--spectrum-blue-800);
  --spectrum-alias-input-border-color-key-focus: var(--spectrum-alias-border-color-focus, var(--spectrum-global-color-blue-400));
  --spectrum-alias-border-color-mouse-focus: var(--spectrum-blue-900);
  --spectrum-alias-border-color-disabled: var(--spectrum-gray-200);
  --spectrum-alias-border-color-extralight: var(--spectrum-gray-100);
  --spectrum-alias-border-color-light: var(--spectrum-gray-200);
  --spectrum-alias-border-color-mid: var(--spectrum-gray-300);
  --spectrum-alias-border-color-dark: var(--spectrum-gray-400);
  --spectrum-alias-border-color-transparent: transparent;
  --spectrum-alias-border-color-translucent-dark: #0000000d;
  --spectrum-alias-border-color-translucent-darker: #0000001a;
  --spectrum-alias-focus-color: var(--spectrum-blue-800);
  --spectrum-alias-focus-ring-color: var(--spectrum-alias-focus-color, var(--spectrum-global-color-blue-400));
  --spectrum-alias-track-color-default: var(--spectrum-gray-300);
  --spectrum-alias-track-color-disabled: var(--spectrum-gray-300);
  --spectrum-alias-track-color-over-background: #fff3;
  --spectrum-alias-icon-color: var(--spectrum-gray-700);
  --spectrum-alias-icon-color-over-background: var(--spectrum-global-color-static-white, #fff);
  --spectrum-alias-icon-color-hover: var(--spectrum-gray-900);
  --spectrum-alias-icon-color-down: var(--spectrum-gray-900);
  --spectrum-alias-icon-color-focus: var(--spectrum-gray-900);
  --spectrum-alias-icon-color-disabled: var(--spectrum-gray-400);
  --spectrum-alias-icon-color-selected-neutral: var(--spectrum-gray-900);
  --spectrum-alias-icon-color-selected: var(--spectrum-blue-900);
  --spectrum-alias-icon-color-selected-hover: var(--spectrum-blue-1000);
  --spectrum-alias-icon-color-selected-down: var(--spectrum-blue-1100);
  --spectrum-alias-icon-color-selected-focus: var(--spectrum-blue-1000);
  --spectrum-alias-icon-color-error: var(--spectrum-red-800);
  --spectrum-alias-toolbar-background-color: var(--spectrum-gray-100);
  --spectrum-alias-colorhandle-outer-border-color: #0000006b;
  --spectrum-alias-categorical-color-1: var(--spectrum-global-color-static-seafoam-200, #26c0c7);
  --spectrum-alias-categorical-color-2: var(--spectrum-global-color-static-indigo-700, #5151d3);
  --spectrum-alias-categorical-color-3: var(--spectrum-global-color-static-orange-500, #e68619);
  --spectrum-alias-categorical-color-4: var(--spectrum-global-color-static-magenta-500, #d83790);
  --spectrum-alias-categorical-color-5: var(--spectrum-global-color-static-indigo-200, #9090fa);
  --spectrum-alias-categorical-color-6: var(--spectrum-global-color-static-celery-200, #58e06f);
  --spectrum-alias-categorical-color-7: var(--spectrum-global-color-static-blue-500, #2680eb);
  --spectrum-alias-categorical-color-8: var(--spectrum-global-color-static-purple-800, #6f38b1);
  --spectrum-alias-categorical-color-9: var(--spectrum-global-color-static-yellow-500, #dfbf00);
  --spectrum-alias-categorical-color-10: var(--spectrum-global-color-static-orange-700, #cb6f10);
  --spectrum-alias-categorical-color-11: var(--spectrum-global-color-static-green-600, #268e6c);
  --spectrum-alias-categorical-color-12: var(--spectrum-global-color-static-chartreuse-300, #9bec54);
  --spectrum-alias-categorical-color-13: var(--spectrum-global-color-static-blue-200, #5aa9fa);
  --spectrum-alias-categorical-color-14: var(--spectrum-global-color-static-fuchsia-500, #c038cc);
  --spectrum-alias-categorical-color-15: var(--spectrum-global-color-static-magenta-200, #f56bb7);
  --spectrum-alias-categorical-color-16: var(--spectrum-global-color-static-yellow-200, #ffe22e);
  --spectrum-alias-toggle-color-default: var(--spectrum-gray-600);
  --spectrum-alias-toggle-color-hover: var(--spectrum-gray-700);
  --spectrum-alias-toggle-color-key-focus: var(--spectrum-gray-700);
  --spectrum-alias-toggle-color-selected: var(--spectrum-gray-700);
  --spectrum-alias-toggle-color-selected-hover: var(--spectrum-gray-800);
  --spectrum-alias-toggle-color-selected-key-focus: var(--spectrum-gray-800);
  --spectrum-alias-toggle-color-selected-down: var(--spectrum-gray-900);
  --spectrum-actionbutton-background-color-disabled: transparent;
  --spectrum-actionbutton-border-color-disabled: var(--spectrum-gray-300);
  --spectrum-actionbutton-quiet-background-color-hover: var(--spectrum-gray-200);
  --spectrum-actionbutton-quiet-background-color-key-focus: var(--spectrum-gray-200);
  --spectrum-actionbutton-quiet-background-color-down: var(--spectrum-gray-300);
  --spectrum-actionbutton-static-black-border-color: #0006;
  --spectrum-actionbutton-static-black-border-color-hover: #0000008c;
  --spectrum-actionbutton-static-black-border-color-key-focus: #0000008c;
  --spectrum-actionbutton-static-black-border-color-down: #000000b3;
  --spectrum-actionbutton-static-black-border-color-disabled: #00000040;
  --spectrum-actionbutton-static-black-border-color-selected-disabled: #0000001a;
  --spectrum-actionbutton-static-black-background-color: transparent;
  --spectrum-actionbutton-static-black-background-color-disabled: transparent;
  --spectrum-actionbutton-static-white-border-color: #fff6;
  --spectrum-actionbutton-static-white-border-color-hover: #ffffff8c;
  --spectrum-actionbutton-static-white-border-color-key-focus: #ffffff8c;
  --spectrum-actionbutton-static-white-border-color-down: #ffffffb3;
  --spectrum-actionbutton-static-white-border-color-disabled: #ffffff40;
  --spectrum-actionbutton-static-white-border-color-selected-disabled: #ffffff1a;
  --spectrum-actionbutton-static-white-background-color: transparent;
  --spectrum-actionbutton-static-white-background-color-disabled: transparent;
  --spectrum-combobox-fieldbutton-inset: 0;
  --spectrum-combobox-fieldbutton-border-left-width: var(--spectrum-alias-input-border-size);
  --spectrum-combobox-fieldbutton-focus-ring-border-radius: var(--spectrum-combobox-fieldbutton-border-top-left-radius, 0);
  --spectrum-combobox-fieldbutton-start-border-radius: 0;
  --spectrum-combobox-fieldbutton-end-border-radius: var(--spectrum-alias-border-radius-regular, var(--spectrum-global-dimension-size-50));
  --spectrum-actiongroup-compact-button-gap: calc(-1 * var(--spectrum-actionbutton-border-size, var(--spectrum-alias-border-size-thin)));
  --spectrum-alias-neutral-background-color: var(--spectrum-neutral-subdued-background-color-default);
  --spectrum-breadcrumb-separator-icon-position-adjustment: var(--spectrum-global-dimension-size-10);
}

.HAZavG_spectrum--large {
  --spectrum-global-dimension-scale-factor: 1.25;
  --spectrum-global-dimension-size-0: 0px;
  --spectrum-global-dimension-size-10: 1px;
  --spectrum-global-dimension-size-25: 2px;
  --spectrum-global-dimension-size-40: 4px;
  --spectrum-global-dimension-size-50: 5px;
  --spectrum-global-dimension-size-65: 6px;
  --spectrum-global-dimension-size-75: 8px;
  --spectrum-global-dimension-size-85: 9px;
  --spectrum-global-dimension-size-100: 10px;
  --spectrum-global-dimension-size-115: 11px;
  --spectrum-global-dimension-size-125: 13px;
  --spectrum-global-dimension-size-130: 14px;
  --spectrum-global-dimension-size-150: 15px;
  --spectrum-global-dimension-size-160: 16px;
  --spectrum-global-dimension-size-175: 18px;
  --spectrum-global-dimension-size-200: 20px;
  --spectrum-global-dimension-size-225: 22px;
  --spectrum-global-dimension-size-250: 25px;
  --spectrum-global-dimension-size-275: 28px;
  --spectrum-global-dimension-size-300: 30px;
  --spectrum-global-dimension-size-325: 32px;
  --spectrum-global-dimension-size-350: 35px;
  --spectrum-global-dimension-size-400: 40px;
  --spectrum-global-dimension-size-450: 45px;
  --spectrum-global-dimension-size-500: 50px;
  --spectrum-global-dimension-size-550: 56px;
  --spectrum-global-dimension-size-600: 60px;
  --spectrum-global-dimension-size-675: 68px;
  --spectrum-global-dimension-size-700: 70px;
  --spectrum-global-dimension-size-800: 80px;
  --spectrum-global-dimension-size-900: 90px;
  --spectrum-global-dimension-size-1000: 100px;
  --spectrum-global-dimension-size-1200: 120px;
  --spectrum-global-dimension-size-1250: 125px;
  --spectrum-global-dimension-size-1600: 160px;
  --spectrum-global-dimension-size-1700: 170px;
  --spectrum-global-dimension-size-2000: 200px;
  --spectrum-global-dimension-size-2400: 240px;
  --spectrum-global-dimension-size-3000: 300px;
  --spectrum-global-dimension-size-3400: 340px;
  --spectrum-global-dimension-size-3600: 360px;
  --spectrum-global-dimension-size-4600: 460px;
  --spectrum-global-dimension-size-5000: 500px;
  --spectrum-global-dimension-size-6000: 600px;
  --spectrum-global-dimension-font-size-25: 12px;
  --spectrum-global-dimension-font-size-50: 13px;
  --spectrum-global-dimension-font-size-75: 15px;
  --spectrum-global-dimension-font-size-100: 17px;
  --spectrum-global-dimension-font-size-150: 18px;
  --spectrum-global-dimension-font-size-200: 19px;
  --spectrum-global-dimension-font-size-300: 22px;
  --spectrum-global-dimension-font-size-400: 24px;
  --spectrum-global-dimension-font-size-500: 27px;
  --spectrum-global-dimension-font-size-600: 31px;
  --spectrum-global-dimension-font-size-700: 34px;
  --spectrum-global-dimension-font-size-800: 39px;
  --spectrum-global-dimension-font-size-900: 44px;
  --spectrum-global-dimension-font-size-1000: 49px;
  --spectrum-global-dimension-font-size-1100: 55px;
  --spectrum-global-dimension-font-size-1200: 62px;
  --spectrum-global-dimension-font-size-1300: 70px;
  --spectrum-alias-avatar-size-100: 26px;
  --spectrum-alias-avatar-size-400: 36px;
  --spectrum-alias-avatar-size-600: 46px;
  --spectrum-actionbutton-touch-hit-x: var(--spectrum-global-dimension-static-size-50, 4px);
  --spectrum-actionbutton-touch-hit-y: var(--spectrum-global-dimension-static-size-50, 4px);
  --spectrum-actionbutton-emphasized-touch-hit-x: var(--spectrum-global-dimension-static-size-50, 4px);
  --spectrum-actionbutton-emphasized-touch-hit-y: var(--spectrum-global-dimension-static-size-50, 4px);
  --spectrum-actionbutton-quiet-touch-hit-x: var(--spectrum-global-dimension-static-size-50, 4px);
  --spectrum-actionbutton-quiet-touch-hit-y: var(--spectrum-global-dimension-static-size-50, 4px);
  --spectrum-actionbutton-quiet-emphasized-touch-hit-x: var(--spectrum-global-dimension-static-size-50, 4px);
  --spectrum-actionbutton-quiet-emphasized-touch-hit-y: var(--spectrum-global-dimension-static-size-50, 4px);
  --spectrum-barloader-large-border-radius: 4px;
  --spectrum-barloader-large-indeterminate-border-radius: 4px;
  --spectrum-barloader-large-over-background-border-radius: 4px;
  --spectrum-barloader-small-border-radius: 3px;
  --spectrum-barloader-small-indeterminate-border-radius: 3px;
  --spectrum-barloader-small-over-background-border-radius: 3px;
  --spectrum-breadcrumb-compact-item-touch-hit-y: var(--spectrum-global-dimension-static-size-50, 4px);
  --spectrum-breadcrumb-compact-button-touch-hit-y: var(--spectrum-global-dimension-static-size-50, 4px);
  --spectrum-breadcrumb-item-touch-hit-y: var(--spectrum-global-dimension-static-size-50, 4px);
  --spectrum-breadcrumb-button-touch-hit-y: var(--spectrum-global-dimension-static-size-50, 4px);
  --spectrum-breadcrumb-multiline-item-touch-hit-y: var(--spectrum-global-dimension-static-size-50, 4px);
  --spectrum-breadcrumb-multiline-button-touch-hit-y: var(--spectrum-global-dimension-static-size-50, 4px);
  --spectrum-button-cta-text-padding-bottom: var(--spectrum-global-dimension-size-100);
  --spectrum-button-cta-min-width: 90px;
  --spectrum-button-cta-touch-hit-x: var(--spectrum-global-dimension-static-size-50, 4px);
  --spectrum-button-cta-touch-hit-y: var(--spectrum-global-dimension-static-size-50, 4px);
  --spectrum-button-over-background-text-padding-bottom: var(--spectrum-global-dimension-size-100);
  --spectrum-button-over-background-min-width: 90px;
  --spectrum-button-over-background-touch-hit-x: var(--spectrum-global-dimension-static-size-50, 4px);
  --spectrum-button-over-background-touch-hit-y: var(--spectrum-global-dimension-static-size-50, 4px);
  --spectrum-button-primary-text-padding-bottom: var(--spectrum-global-dimension-size-100);
  --spectrum-button-primary-min-width: 90px;
  --spectrum-button-primary-touch-hit-x: var(--spectrum-global-dimension-static-size-50, 4px);
  --spectrum-button-primary-touch-hit-y: var(--spectrum-global-dimension-static-size-50, 4px);
  --spectrum-button-quiet-over-background-text-padding-bottom: var(--spectrum-global-dimension-size-100);
  --spectrum-button-quiet-over-background-min-width: 90px;
  --spectrum-button-quiet-over-background-touch-hit-x: var(--spectrum-global-dimension-static-size-250, 20px);
  --spectrum-button-quiet-over-background-touch-hit-y: var(--spectrum-global-dimension-static-size-50, 4px);
  --spectrum-button-quiet-over-background-cursor-hit-x: var(--spectrum-global-dimension-static-size-250, 20px);
  --spectrum-button-quiet-primary-text-padding-bottom: var(--spectrum-global-dimension-size-100);
  --spectrum-button-quiet-primary-min-width: 90px;
  --spectrum-button-quiet-primary-touch-hit-x: var(--spectrum-global-dimension-static-size-250, 20px);
  --spectrum-button-quiet-primary-touch-hit-y: var(--spectrum-global-dimension-static-size-50, 4px);
  --spectrum-button-quiet-primary-cursor-hit-x: var(--spectrum-global-dimension-static-size-250, 20px);
  --spectrum-button-quiet-secondary-text-padding-bottom: var(--spectrum-global-dimension-size-100);
  --spectrum-button-quiet-secondary-min-width: 90px;
  --spectrum-button-quiet-secondary-touch-hit-x: var(--spectrum-global-dimension-static-size-250, 20px);
  --spectrum-button-quiet-secondary-touch-hit-y: var(--spectrum-global-dimension-static-size-50, 4px);
  --spectrum-button-quiet-secondary-cursor-hit-x: var(--spectrum-global-dimension-static-size-250, 20px);
  --spectrum-button-quiet-warning-text-padding-bottom: var(--spectrum-global-dimension-size-100);
  --spectrum-button-quiet-warning-min-width: 90px;
  --spectrum-button-quiet-warning-touch-hit-x: var(--spectrum-global-dimension-static-size-250, 20px);
  --spectrum-button-quiet-warning-touch-hit-y: var(--spectrum-global-dimension-static-size-50, 4px);
  --spectrum-button-quiet-warning-cursor-hit-x: var(--spectrum-global-dimension-static-size-250, 20px);
  --spectrum-button-secondary-text-padding-bottom: var(--spectrum-global-dimension-size-100);
  --spectrum-button-secondary-min-width: 90px;
  --spectrum-button-secondary-touch-hit-x: var(--spectrum-global-dimension-static-size-50, 4px);
  --spectrum-button-secondary-touch-hit-y: var(--spectrum-global-dimension-static-size-50, 4px);
  --spectrum-button-warning-text-padding-bottom: var(--spectrum-global-dimension-size-100);
  --spectrum-button-warning-min-width: 90px;
  --spectrum-button-warning-touch-hit-x: var(--spectrum-global-dimension-static-size-50, 4px);
  --spectrum-button-warning-touch-hit-y: var(--spectrum-global-dimension-static-size-50, 4px);
  --spectrum-checkbox-text-gap-key-focus: var(--spectrum-global-dimension-static-size-150, 12px);
  --spectrum-checkbox-text-gap-selected-key-focus: var(--spectrum-global-dimension-static-size-150, 12px);
  --spectrum-checkbox-text-gap-indeterminate-key-focus: var(--spectrum-global-dimension-static-size-150, 12px);
  --spectrum-checkbox-text-gap-error-key-focus: var(--spectrum-global-dimension-static-size-150, 12px);
  --spectrum-checkbox-text-gap-error-selected-key-focus: var(--spectrum-global-dimension-static-size-150, 12px);
  --spectrum-checkbox-text-gap-error-indeterminate-key-focus: var(--spectrum-global-dimension-static-size-150, 12px);
  --spectrum-checkbox-touch-hit-y: var(--spectrum-global-dimension-static-size-50, 4px);
  --spectrum-checkbox-emphasized-text-gap-key-focus: var(--spectrum-global-dimension-static-size-150, 12px);
  --spectrum-checkbox-emphasized-text-gap-selected-key-focus: var(--spectrum-global-dimension-static-size-150, 12px);
  --spectrum-checkbox-emphasized-text-gap-indeterminate-key-focus: var(--spectrum-global-dimension-static-size-150, 12px);
  --spectrum-checkbox-emphasized-text-gap-error-key-focus: var(--spectrum-global-dimension-static-size-150, 12px);
  --spectrum-checkbox-emphasized-text-gap-error-selected-key-focus: var(--spectrum-global-dimension-static-size-150, 12px);
  --spectrum-checkbox-emphasized-text-gap-error-indeterminate-key-focus: var(--spectrum-global-dimension-static-size-150, 12px);
  --spectrum-checkbox-emphasized-touch-hit-y: var(--spectrum-global-dimension-static-size-50, 4px);
  --spectrum-checkbox-quiet-text-gap-key-focus: var(--spectrum-global-dimension-static-size-150, 12px);
  --spectrum-checkbox-quiet-text-gap-selected-key-focus: var(--spectrum-global-dimension-static-size-150, 12px);
  --spectrum-checkbox-quiet-text-gap-indeterminate-key-focus: var(--spectrum-global-dimension-static-size-150, 12px);
  --spectrum-checkbox-quiet-text-gap-error-key-focus: var(--spectrum-global-dimension-static-size-150, 12px);
  --spectrum-checkbox-quiet-text-gap-error-selected-key-focus: var(--spectrum-global-dimension-static-size-150, 12px);
  --spectrum-checkbox-quiet-text-gap-error-indeterminate-key-focus: var(--spectrum-global-dimension-static-size-150, 12px);
  --spectrum-checkbox-quiet-touch-hit-y: var(--spectrum-global-dimension-static-size-50, 4px);
  --spectrum-circleloader-medium-border-size: var(--spectrum-global-dimension-static-size-50, 4px);
  --spectrum-circleloader-medium-over-background-border-size: var(--spectrum-global-dimension-static-size-50, 4px);
  --spectrum-circleloader-small-border-size: 3px;
  --spectrum-circleloader-small-over-background-border-size: 3px;
  --spectrum-colorhandle-loupe-margin: var(--spectrum-global-dimension-static-size-100, 8px);
  --spectrum-colorloupe-colorhandle-gap: var(--spectrum-global-dimension-static-size-100, 8px);
  --spectrum-colorslider-touch-hit-y: var(--spectrum-global-dimension-size-85);
  --spectrum-colorslider-vertical-touch-hit-x: var(--spectrum-global-dimension-size-85);
  --spectrum-colorwheel-min-size: var(--spectrum-global-dimension-static-size-2600, 208px);
  --spectrum-colorwheel-touch-hit-outer: var(--spectrum-global-dimension-size-85);
  --spectrum-colorwheel-touch-hit-inner: var(--spectrum-global-dimension-size-85);
  --spectrum-cyclebutton-touch-hit-x: var(--spectrum-global-dimension-static-size-50, 4px);
  --spectrum-cyclebutton-touch-hit-y: var(--spectrum-global-dimension-static-size-50, 4px);
  --spectrum-dialog-confirm-max-width: var(--spectrum-global-dimension-static-size-5000, 400px);
  --spectrum-dialog-confirm-title-text-size: var(--spectrum-global-dimension-font-size-200);
  --spectrum-dialog-confirm-description-text-size: var(--spectrum-global-dimension-font-size-75);
  --spectrum-dialog-confirm-padding: var(--spectrum-global-dimension-static-size-300, 24px);
  --spectrum-dialog-confirm-description-margin-bottom: var(--spectrum-global-dimension-static-size-500, 40px);
  --spectrum-dialog-max-width: var(--spectrum-global-dimension-static-size-5000, 400px);
  --spectrum-dialog-title-text-size: var(--spectrum-global-dimension-font-size-200);
  --spectrum-dialog-content-text-size: var(--spectrum-global-dimension-font-size-75);
  --spectrum-dialog-padding: var(--spectrum-global-dimension-static-size-300, 24px);
  --spectrum-dialog-content-margin-bottom: var(--spectrum-global-dimension-static-size-500, 40px);
  --spectrum-dialog-destructive-max-width: var(--spectrum-global-dimension-static-size-5000, 400px);
  --spectrum-dialog-destructive-title-text-size: var(--spectrum-global-dimension-font-size-200);
  --spectrum-dialog-destructive-description-text-size: var(--spectrum-global-dimension-font-size-75);
  --spectrum-dialog-destructive-padding: var(--spectrum-global-dimension-static-size-300, 24px);
  --spectrum-dialog-destructive-description-margin-bottom: var(--spectrum-global-dimension-static-size-500, 40px);
  --spectrum-dialog-error-max-width: var(--spectrum-global-dimension-static-size-5000, 400px);
  --spectrum-dialog-error-title-text-size: var(--spectrum-global-dimension-font-size-200);
  --spectrum-dialog-error-description-text-size: var(--spectrum-global-dimension-font-size-75);
  --spectrum-dialog-error-padding: var(--spectrum-global-dimension-static-size-300, 24px);
  --spectrum-dialog-error-description-margin-bottom: var(--spectrum-global-dimension-static-size-500, 40px);
  --spectrum-dialog-info-max-width: var(--spectrum-global-dimension-static-size-5000, 400px);
  --spectrum-dialog-info-title-text-size: var(--spectrum-global-dimension-font-size-200);
  --spectrum-dialog-info-description-text-size: var(--spectrum-global-dimension-font-size-75);
  --spectrum-dialog-info-padding: var(--spectrum-global-dimension-static-size-300, 24px);
  --spectrum-dialog-info-description-margin-bottom: var(--spectrum-global-dimension-static-size-500, 40px);
  --spectrum-dropdown-touch-hit-x: var(--spectrum-global-dimension-static-size-50, 4px);
  --spectrum-dropdown-touch-hit-y: var(--spectrum-global-dimension-static-size-50, 4px);
  --spectrum-dropdown-quiet-touch-hit-y: var(--spectrum-global-dimension-static-size-50, 4px);
  --spectrum-dropdown-thumbnail-small-touch-hit-x: var(--spectrum-global-dimension-static-size-50, 4px);
  --spectrum-dropdown-thumbnail-small-touch-hit-y: var(--spectrum-global-dimension-static-size-50, 4px);
  --spectrum-fieldbutton-quiet-min-width: var(--spectrum-global-dimension-size-200);
  --spectrum-icon-arrow-down-small-height: 12px;
  --spectrum-icon-arrow-left-medium-height: 12px;
  --spectrum-icon-checkmark-medium-width: 16px;
  --spectrum-icon-checkmark-medium-height: 16px;
  --spectrum-icon-checkmark-small-width: 12px;
  --spectrum-icon-checkmark-small-height: 12px;
  --spectrum-icon-chevron-down-medium-width: 12px;
  --spectrum-icon-chevron-left-large-width: 16px;
  --spectrum-icon-chevron-left-medium-height: 12px;
  --spectrum-icon-chevron-right-large-width: 16px;
  --spectrum-icon-chevron-right-medium-height: 12px;
  --spectrum-icon-cross-large-width: 16px;
  --spectrum-icon-cross-large-height: 16px;
  --spectrum-icon-dash-small-width: 12px;
  --spectrum-icon-dash-small-height: 12px;
  --spectrum-icon-skip-left-width: 10px;
  --spectrum-icon-skip-left-height: 12px;
  --spectrum-icon-skip-right-width: 10px;
  --spectrum-icon-skip-right-height: 12px;
  --spectrum-icon-triplegripper-width: 12px;
  --spectrum-listitem-option-icon-size: var(--spectrum-global-dimension-static-size-200, 16px);
  --spectrum-listitem-option-icon-margin-top: var(--spectrum-global-dimension-static-size-50, 4px);
  --spectrum-listitem-option-height: var(--spectrum-global-dimension-static-size-600, 48px);
  --spectrum-listitem-option-icon-padding-y: var(--spectrum-global-dimension-static-size-200, 16px);
  --spectrum-listitem-thumbnail-option-icon-margin-top: var(--spectrum-global-dimension-static-size-50, 4px);
  --spectrum-listitem-thumbnail-option-icon-padding-y: var(--spectrum-global-dimension-static-size-200, 16px);
  --spectrum-selectlist-thumbnail-small-option-icon-margin-top: var(--spectrum-global-dimension-static-size-50, 4px);
  --spectrum-selectlist-thumbnail-small-option-icon-padding-y: var(--spectrum-global-dimension-static-size-200, 16px);
  --spectrum-selectlist-option-icon-size: var(--spectrum-global-dimension-static-size-200, 16px);
  --spectrum-selectlist-option-icon-padding-y: var(--spectrum-global-dimension-static-size-200, 16px);
  --spectrum-selectlist-option-icon-margin-top: var(--spectrum-global-dimension-static-size-50, 4px);
  --spectrum-selectlist-option-height: var(--spectrum-global-dimension-static-size-600, 48px);
  --spectrum-selectlist-option-padding-height: var(--spectrum-global-dimension-static-size-160, 13px);
  --spectrum-selectlist-thumbnail-option-icon-padding-y: var(--spectrum-global-dimension-static-size-200, 16px);
  --spectrum-loader-bar-large-border-radius: 4px;
  --spectrum-loader-bar-large-over-background-border-radius: 4px;
  --spectrum-loader-bar-small-border-radius: 3px;
  --spectrum-loader-bar-small-over-background-border-radius: 3px;
  --spectrum-loader-circle-medium-border-size: var(--spectrum-global-dimension-static-size-50, 4px);
  --spectrum-loader-circle-medium-over-background-border-size: var(--spectrum-global-dimension-static-size-50, 4px);
  --spectrum-loader-circle-small-border-size: 3px;
  --spectrum-loader-circle-small-over-background-border-size: 3px;
  --spectrum-meter-large-border-radius: 4px;
  --spectrum-meter-small-border-radius: 3px;
  --spectrum-pagination-page-button-line-height: 32px;
  --spectrum-overlay-positive-transform-distance: 8px;
  --spectrum-overlay-negative-transform-distance: -8px;
  --spectrum-picker-touch-hit-x: var(--spectrum-global-dimension-static-size-50, 4px);
  --spectrum-picker-touch-hit-y: var(--spectrum-global-dimension-static-size-50, 4px);
  --spectrum-picker-quiet-min-width: var(--spectrum-global-dimension-size-200);
  --spectrum-picker-quiet-touch-hit-y: var(--spectrum-global-dimension-static-size-50, 4px);
  --spectrum-picker-thumbnail-small-touch-hit-x: var(--spectrum-global-dimension-static-size-50, 4px);
  --spectrum-picker-thumbnail-small-touch-hit-y: var(--spectrum-global-dimension-static-size-50, 4px);
  --spectrum-progressbar-large-border-radius: 4px;
  --spectrum-progressbar-large-indeterminate-border-radius: 4px;
  --spectrum-progressbar-large-over-background-border-radius: 4px;
  --spectrum-progressbar-small-border-radius: 3px;
  --spectrum-progressbar-small-indeterminate-border-radius: 3px;
  --spectrum-progressbar-small-over-background-border-radius: 3px;
  --spectrum-progresscircle-medium-border-size: var(--spectrum-global-dimension-static-size-50, 4px);
  --spectrum-progresscircle-medium-over-background-border-size: var(--spectrum-global-dimension-static-size-50, 4px);
  --spectrum-progresscircle-small-border-size: 3px;
  --spectrum-progresscircle-small-indeterminate-border-size: 3px;
  --spectrum-progresscircle-small-over-background-border-size: 3px;
  --spectrum-progresscircle-medium-indeterminate-border-size: var(--spectrum-global-dimension-static-size-50, 4px);
  --spectrum-radio-text-gap-key-focus: var(--spectrum-global-dimension-static-size-150, 12px);
  --spectrum-radio-text-gap-selected-key-focus: var(--spectrum-global-dimension-static-size-150, 12px);
  --spectrum-radio-text-gap-error-key-focus: var(--spectrum-global-dimension-static-size-150, 12px);
  --spectrum-radio-text-gap-error-selected-key-focus: var(--spectrum-global-dimension-static-size-150, 12px);
  --spectrum-radio-margin-bottom: var(--spectrum-global-dimension-static-size-100, 8px);
  --spectrum-radio-touch-hit-y: var(--spectrum-global-dimension-static-size-50, 4px);
  --spectrum-radio-emphasized-text-gap-key-focus: var(--spectrum-global-dimension-static-size-150, 12px);
  --spectrum-radio-emphasized-text-gap-selected-key-focus: var(--spectrum-global-dimension-static-size-150, 12px);
  --spectrum-radio-emphasized-text-gap-error-key-focus: var(--spectrum-global-dimension-static-size-150, 12px);
  --spectrum-radio-emphasized-text-gap-error-selected-key-focus: var(--spectrum-global-dimension-static-size-150, 12px);
  --spectrum-radio-emphasized-margin-bottom: var(--spectrum-global-dimension-static-size-100, 8px);
  --spectrum-radio-emphasized-touch-hit-y: var(--spectrum-global-dimension-static-size-50, 4px);
  --spectrum-radio-quiet-text-gap-key-focus: var(--spectrum-global-dimension-static-size-150, 12px);
  --spectrum-radio-quiet-text-gap-selected-key-focus: var(--spectrum-global-dimension-static-size-150, 12px);
  --spectrum-radio-quiet-text-gap-error-key-focus: var(--spectrum-global-dimension-static-size-150, 12px);
  --spectrum-radio-quiet-text-gap-error-selected-key-focus: var(--spectrum-global-dimension-static-size-150, 12px);
  --spectrum-radio-quiet-margin-bottom: var(--spectrum-global-dimension-static-size-100, 8px);
  --spectrum-radio-quiet-touch-hit-y: var(--spectrum-global-dimension-static-size-50, 4px);
  --spectrum-rating-icon-width: 30px;
  --spectrum-rating-indicator-width: 20px;
  --spectrum-rating-touch-hit-y: var(--spectrum-global-dimension-static-size-50, 4px);
  --spectrum-rating-emphasized-icon-width: 30px;
  --spectrum-rating-emphasized-indicator-width: 20px;
  --spectrum-rating-emphasized-touch-hit-y: var(--spectrum-global-dimension-static-size-50, 4px);
  --spectrum-rating-quiet-icon-width: 30px;
  --spectrum-rating-quiet-indicator-width: 20px;
  --spectrum-rating-quiet-touch-hit-y: var(--spectrum-global-dimension-static-size-50, 4px);
  --spectrum-search-touch-hit-x: var(--spectrum-global-dimension-static-size-50, 4px);
  --spectrum-search-touch-hit-y: var(--spectrum-global-dimension-static-size-50, 4px);
  --spectrum-search-icon-frame: var(--spectrum-global-dimension-static-size-500, 40px);
  --spectrum-search-quiet-touch-hit-x: var(--spectrum-global-dimension-static-size-50, 4px);
  --spectrum-search-quiet-touch-hit-y: var(--spectrum-global-dimension-static-size-50, 4px);
  --spectrum-search-quiet-icon-frame: var(--spectrum-global-dimension-static-size-500, 40px);
  --spectrum-sidenav-item-touch-hit-bottom: 3px;
  --spectrum-sidenav-multilevel-item-touch-hit-bottom: 3px;
  --spectrum-slider-track-touch-hit-y: var(--spectrum-global-dimension-static-size-50, 4px);
  --spectrum-slider-handle-touch-hit-x: var(--spectrum-global-dimension-static-size-175, 14px);
  --spectrum-slider-handle-touch-hit-y: var(--spectrum-global-dimension-static-size-175, 14px);
  --spectrum-slider-editable-track-touch-hit-y: var(--spectrum-global-dimension-static-size-50, 4px);
  --spectrum-slider-editable-handle-touch-hit-x: var(--spectrum-global-dimension-static-size-175, 14px);
  --spectrum-slider-editable-handle-touch-hit-y: var(--spectrum-global-dimension-static-size-175, 14px);
  --spectrum-slider-fill-track-touch-hit-y: var(--spectrum-global-dimension-static-size-50, 4px);
  --spectrum-slider-fill-handle-touch-hit-x: var(--spectrum-global-dimension-static-size-175, 14px);
  --spectrum-slider-fill-handle-touch-hit-y: var(--spectrum-global-dimension-static-size-175, 14px);
  --spectrum-switch-focus-ring-border-radius-key-focus: var(--spectrum-global-dimension-static-size-160, 13px);
  --spectrum-switch-text-gap-key-focus: var(--spectrum-global-dimension-static-size-150, 12px);
  --spectrum-switch-text-gap-selected-key-focus: var(--spectrum-global-dimension-static-size-150, 12px);
  --spectrum-switch-text-gap-error-key-focus: var(--spectrum-global-dimension-static-size-150, 12px);
  --spectrum-switch-text-gap-error-selected-key-focus: var(--spectrum-global-dimension-static-size-150, 12px);
  --spectrum-switch-track-width: var(--spectrum-global-dimension-static-size-450, 36px);
  --spectrum-switch-handle-border-radius: 9px;
  --spectrum-switch-handle-transform-x-ltr: 18px;
  --spectrum-switch-handle-transform-x-rtl: -18px;
  --spectrum-switch-touch-hit-y: var(--spectrum-global-dimension-static-size-50, 4px);
  --spectrum-switch-emphasized-focus-ring-border-radius-key-focus: var(--spectrum-global-dimension-static-size-160, 13px);
  --spectrum-switch-emphasized-text-gap-key-focus: var(--spectrum-global-dimension-static-size-150, 12px);
  --spectrum-switch-emphasized-text-gap-selected-key-focus: var(--spectrum-global-dimension-static-size-150, 12px);
  --spectrum-switch-emphasized-text-gap-error-key-focus: var(--spectrum-global-dimension-static-size-150, 12px);
  --spectrum-switch-emphasized-text-gap-error-selected-key-focus: var(--spectrum-global-dimension-static-size-150, 12px);
  --spectrum-switch-emphasized-track-width: var(--spectrum-global-dimension-static-size-450, 36px);
  --spectrum-switch-emphasized-handle-border-radius: 9px;
  --spectrum-switch-emphasized-touch-hit-y: var(--spectrum-global-dimension-static-size-50, 4px);
  --spectrum-switch-quiet-focus-ring-border-radius-key-focus: var(--spectrum-global-dimension-static-size-160, 13px);
  --spectrum-switch-quiet-text-gap-key-focus: var(--spectrum-global-dimension-static-size-150, 12px);
  --spectrum-switch-quiet-text-gap-selected-key-focus: var(--spectrum-global-dimension-static-size-150, 12px);
  --spectrum-switch-quiet-text-gap-error-key-focus: var(--spectrum-global-dimension-static-size-150, 12px);
  --spectrum-switch-quiet-text-gap-error-selected-key-focus: var(--spectrum-global-dimension-static-size-150, 12px);
  --spectrum-switch-quiet-track-width: var(--spectrum-global-dimension-static-size-450, 36px);
  --spectrum-switch-quiet-handle-border-radius: 9px;
  --spectrum-switch-quiet-touch-hit-y: var(--spectrum-global-dimension-static-size-50, 4px);
  --spectrum-tabs-compact-focus-ring-border-radius: 6px;
  --spectrum-tabs-compact-margin-left: -11px;
  --spectrum-tabs-compact-margin-right: -11px;
  --spectrum-tabs-compact-vertical-focus-ring-border-radius: 6px;
  --spectrum-tabs-compact-vertical-emphasized-focus-ring-border-radius: 6px;
  --spectrum-tabs-baseline: var(--spectrum-global-dimension-size-250);
  --spectrum-tabs-focus-ring-border-radius: 6px;
  --spectrum-tabs-margin-left: -11px;
  --spectrum-tabs-margin-right: -11px;
  --spectrum-tabs-emphasized-baseline: var(--spectrum-global-dimension-size-250);
  --spectrum-tabs-emphasized-focus-ring-border-radius: 6px;
  --spectrum-tabs-emphasized-margin-left: -11px;
  --spectrum-tabs-emphasized-margin-right: -11px;
  --spectrum-tabs-quiet-baseline: var(--spectrum-global-dimension-size-250);
  --spectrum-tabs-quiet-focus-ring-border-radius: 6px;
  --spectrum-tabs-quiet-margin-left: -11px;
  --spectrum-tabs-quiet-margin-right: -11px;
  --spectrum-tabs-quiet-compact-focus-ring-border-radius: 6px;
  --spectrum-tabs-quiet-compact-margin-left: -11px;
  --spectrum-tabs-quiet-compact-margin-right: -11px;
  --spectrum-tabs-quiet-compact-emphasized-focus-ring-border-radius: 6px;
  --spectrum-tabs-quiet-compact-emphasized-margin-left: -11px;
  --spectrum-tabs-quiet-compact-emphasized-margin-right: -11px;
  --spectrum-tabs-quiet-compact-vertical-focus-ring-border-radius: 6px;
  --spectrum-tabs-quiet-compact-vertical-emphasized-focus-ring-border-radius: 6px;
  --spectrum-tabs-quiet-emphasized-baseline: var(--spectrum-global-dimension-size-250);
  --spectrum-tabs-quiet-emphasized-focus-ring-border-radius: 6px;
  --spectrum-tabs-quiet-emphasized-margin-left: -11px;
  --spectrum-tabs-quiet-emphasized-margin-right: -11px;
  --spectrum-tabs-quiet-vertical-baseline: var(--spectrum-global-dimension-size-250);
  --spectrum-tabs-quiet-vertical-focus-ring-border-radius: 6px;
  --spectrum-tabs-quiet-vertical-emphasized-baseline: var(--spectrum-global-dimension-size-250);
  --spectrum-tabs-quiet-vertical-emphasized-focus-ring-border-radius: 6px;
  --spectrum-tabs-vertical-baseline: var(--spectrum-global-dimension-size-250);
  --spectrum-tabs-vertical-focus-ring-border-radius: 6px;
  --spectrum-textarea-touch-hit-x: var(--spectrum-global-dimension-static-size-50, 4px);
  --spectrum-textarea-touch-hit-y: var(--spectrum-global-dimension-static-size-50, 4px);
  --spectrum-textarea-icon-frame: var(--spectrum-global-dimension-static-size-500, 40px);
  --spectrum-textarea-quiet-touch-hit-x: var(--spectrum-global-dimension-static-size-50, 4px);
  --spectrum-textarea-quiet-touch-hit-y: var(--spectrum-global-dimension-static-size-50, 4px);
  --spectrum-textarea-quiet-icon-frame: var(--spectrum-global-dimension-static-size-500, 40px);
  --spectrum-textfield-touch-hit-x: var(--spectrum-global-dimension-static-size-50, 4px);
  --spectrum-textfield-touch-hit-y: var(--spectrum-global-dimension-static-size-50, 4px);
  --spectrum-textfield-icon-frame: var(--spectrum-global-dimension-static-size-500, 40px);
  --spectrum-textfield-quiet-touch-hit-x: var(--spectrum-global-dimension-static-size-50, 4px);
  --spectrum-textfield-quiet-touch-hit-y: var(--spectrum-global-dimension-static-size-50, 4px);
  --spectrum-textfield-quiet-icon-frame: var(--spectrum-global-dimension-static-size-500, 40px);
  --spectrum-tool-touch-hit-x: var(--spectrum-global-dimension-static-size-50, 4px);
  --spectrum-tool-touch-hit-y: var(--spectrum-global-dimension-static-size-50, 4px);
  --spectrum-tool-high-emphasis-touch-hit-x: var(--spectrum-global-dimension-static-size-50, 4px);
  --spectrum-tool-high-emphasis-touch-hit-y: var(--spectrum-global-dimension-static-size-50, 4px);
  --spectrum-tooltip-padding-bottom: 6px;
  --spectrum-tooltip-content-max-width: var(--spectrum-global-dimension-size-2000);
  --spectrum-tooltip-info-padding-bottom: 6px;
  --spectrum-tooltip-info-content-max-width: 126px;
  --spectrum-tooltip-negative-padding-bottom: 6px;
  --spectrum-tooltip-negative-content-max-width: 126px;
  --spectrum-tooltip-positive-padding-bottom: 6px;
  --spectrum-tooltip-positive-content-max-width: 126px;
}

.PFjRbG_spectrum--light {
  --spectrum-global-color-status: Verified;
  --spectrum-global-color-version: 5.1;
  --spectrum-gray-50: #fff;
  --spectrum-gray-75: #fdfdfd;
  --spectrum-gray-100: #f8f8f8;
  --spectrum-gray-200: #e6e6e6;
  --spectrum-gray-300: #d5d5d5;
  --spectrum-gray-400: #b1b1b1;
  --spectrum-gray-500: #909090;
  --spectrum-gray-600: #6d6d6d;
  --spectrum-gray-700: #464646;
  --spectrum-gray-800: #222;
  --spectrum-gray-900: #000;
  --spectrum-red-100: #ffebe7;
  --spectrum-red-200: #ffddd6;
  --spectrum-red-300: #ffcdc3;
  --spectrum-red-400: #ffb7a9;
  --spectrum-red-500: #ff9b88;
  --spectrum-red-600: #ff7c65;
  --spectrum-red-700: #f75c46;
  --spectrum-red-800: #ea3829;
  --spectrum-red-900: #d31510;
  --spectrum-red-1000: #b40000;
  --spectrum-red-1100: #930000;
  --spectrum-red-1200: #740000;
  --spectrum-red-1300: #590000;
  --spectrum-red-1400: #430000;
  --spectrum-orange-100: #ffeccc;
  --spectrum-orange-200: #ffdfad;
  --spectrum-orange-300: #fdd291;
  --spectrum-orange-400: #ffbb63;
  --spectrum-orange-500: #ffa037;
  --spectrum-orange-600: #f68511;
  --spectrum-orange-700: #e46f00;
  --spectrum-orange-800: #cb5d00;
  --spectrum-orange-900: #b14c00;
  --spectrum-orange-1000: #953d00;
  --spectrum-orange-1100: #7a2f00;
  --spectrum-orange-1200: #612300;
  --spectrum-orange-1300: #491901;
  --spectrum-orange-1400: #351201;
  --spectrum-yellow-100: #fbf198;
  --spectrum-yellow-200: #f8e750;
  --spectrum-yellow-300: #f8d904;
  --spectrum-yellow-400: #e8c600;
  --spectrum-yellow-500: #d7b300;
  --spectrum-yellow-600: #c49f00;
  --spectrum-yellow-700: #b08c00;
  --spectrum-yellow-800: #9b7800;
  --spectrum-yellow-900: #856600;
  --spectrum-yellow-1000: #705300;
  --spectrum-yellow-1100: #5b4300;
  --spectrum-yellow-1200: #483300;
  --spectrum-yellow-1300: #362500;
  --spectrum-yellow-1400: #281a00;
  --spectrum-chartreuse-100: #dbfc6e;
  --spectrum-chartreuse-200: #cbf443;
  --spectrum-chartreuse-300: #bce92a;
  --spectrum-chartreuse-400: #aad816;
  --spectrum-chartreuse-500: #98c50a;
  --spectrum-chartreuse-600: #87b103;
  --spectrum-chartreuse-700: #769c00;
  --spectrum-chartreuse-800: #678800;
  --spectrum-chartreuse-900: #577400;
  --spectrum-chartreuse-1000: #486000;
  --spectrum-chartreuse-1100: #3a4d00;
  --spectrum-chartreuse-1200: #2c3b00;
  --spectrum-chartreuse-1300: #212c00;
  --spectrum-chartreuse-1400: #181f00;
  --spectrum-celery-100: #cdfcbf;
  --spectrum-celery-200: #aef69d;
  --spectrum-celery-300: #96ee85;
  --spectrum-celery-400: #72e06a;
  --spectrum-celery-500: #4ecf50;
  --spectrum-celery-600: #27bb36;
  --spectrum-celery-700: #07a721;
  --spectrum-celery-800: #009112;
  --spectrum-celery-900: #007c0f;
  --spectrum-celery-1000: #00670f;
  --spectrum-celery-1100: #00530d;
  --spectrum-celery-1200: #00400a;
  --spectrum-celery-1300: #003007;
  --spectrum-celery-1400: #002205;
  --spectrum-green-100: #cef8e0;
  --spectrum-green-200: #adf4ce;
  --spectrum-green-300: #89ecbc;
  --spectrum-green-400: #67dea8;
  --spectrum-green-500: #49cc93;
  --spectrum-green-600: #2fb880;
  --spectrum-green-700: #15a46e;
  --spectrum-green-800: #008f5d;
  --spectrum-green-900: #007a4d;
  --spectrum-green-1000: #00653e;
  --spectrum-green-1100: #005132;
  --spectrum-green-1200: #053f27;
  --spectrum-green-1300: #0a2e1d;
  --spectrum-green-1400: #0a2015;
  --spectrum-seafoam-100: #cef7f3;
  --spectrum-seafoam-200: #aaf1ea;
  --spectrum-seafoam-300: #8ce9e2;
  --spectrum-seafoam-400: #65dad2;
  --spectrum-seafoam-500: #3fc9c1;
  --spectrum-seafoam-600: #0fb5ae;
  --spectrum-seafoam-700: #00a19a;
  --spectrum-seafoam-800: #008c87;
  --spectrum-seafoam-900: #007772;
  --spectrum-seafoam-1000: #00635f;
  --spectrum-seafoam-1100: #0c4f4c;
  --spectrum-seafoam-1200: #123c3a;
  --spectrum-seafoam-1300: #122c2b;
  --spectrum-seafoam-1400: #0f1f1e;
  --spectrum-cyan-100: #c5f8ff;
  --spectrum-cyan-200: #a4f0ff;
  --spectrum-cyan-300: #88e7fa;
  --spectrum-cyan-400: #60d8f3;
  --spectrum-cyan-500: #33c5e8;
  --spectrum-cyan-600: #12b0da;
  --spectrum-cyan-700: #019cc8;
  --spectrum-cyan-800: #0086b4;
  --spectrum-cyan-900: #00719f;
  --spectrum-cyan-1000: #005d89;
  --spectrum-cyan-1100: #004a73;
  --spectrum-cyan-1200: #00395d;
  --spectrum-cyan-1300: #002a46;
  --spectrum-cyan-1400: #001e33;
  --spectrum-blue-100: #e0f2ff;
  --spectrum-blue-200: #cae8ff;
  --spectrum-blue-300: #b5deff;
  --spectrum-blue-400: #96cefd;
  --spectrum-blue-500: #78bbfa;
  --spectrum-blue-600: #59a7f6;
  --spectrum-blue-700: #3892f3;
  --spectrum-blue-800: #147af3;
  --spectrum-blue-900: #0265dc;
  --spectrum-blue-1000: #0054b6;
  --spectrum-blue-1100: #004491;
  --spectrum-blue-1200: #003571;
  --spectrum-blue-1300: #002754;
  --spectrum-blue-1400: #001c3c;
  --spectrum-indigo-100: #edeeff;
  --spectrum-indigo-200: #e0e2ff;
  --spectrum-indigo-300: #d3d5ff;
  --spectrum-indigo-400: #c1c4ff;
  --spectrum-indigo-500: #acafff;
  --spectrum-indigo-600: #9599ff;
  --spectrum-indigo-700: #7e84fc;
  --spectrum-indigo-800: #686df4;
  --spectrum-indigo-900: #5258e4;
  --spectrum-indigo-1000: #4046ca;
  --spectrum-indigo-1100: #3236a8;
  --spectrum-indigo-1200: #262986;
  --spectrum-indigo-1300: #1b1e64;
  --spectrum-indigo-1400: #141648;
  --spectrum-purple-100: #f6ebff;
  --spectrum-purple-200: #edf;
  --spectrum-purple-300: #e6d0ff;
  --spectrum-purple-400: #dbbbfe;
  --spectrum-purple-500: #cca4fd;
  --spectrum-purple-600: #bd8bfc;
  --spectrum-purple-700: #ae72f9;
  --spectrum-purple-800: #9d57f4;
  --spectrum-purple-900: #893de7;
  --spectrum-purple-1000: #7326d3;
  --spectrum-purple-1100: #5d13b7;
  --spectrum-purple-1200: #470c94;
  --spectrum-purple-1300: #33106a;
  --spectrum-purple-1400: #230f49;
  --spectrum-fuchsia-100: #ffe9fc;
  --spectrum-fuchsia-200: #ffdafa;
  --spectrum-fuchsia-300: #fec7f8;
  --spectrum-fuchsia-400: #fbaef6;
  --spectrum-fuchsia-500: #f592f3;
  --spectrum-fuchsia-600: #ed74ed;
  --spectrum-fuchsia-700: #e055e2;
  --spectrum-fuchsia-800: #cd3ace;
  --spectrum-fuchsia-900: #b622b7;
  --spectrum-fuchsia-1000: #9d039e;
  --spectrum-fuchsia-1100: #800081;
  --spectrum-fuchsia-1200: #640664;
  --spectrum-fuchsia-1300: #470e46;
  --spectrum-fuchsia-1400: #320d31;
  --spectrum-magenta-100: #ffeaf1;
  --spectrum-magenta-200: #ffdce8;
  --spectrum-magenta-300: #ffcadd;
  --spectrum-magenta-400: #ffb2ce;
  --spectrum-magenta-500: #ff95bd;
  --spectrum-magenta-600: #fa77aa;
  --spectrum-magenta-700: #ef5a98;
  --spectrum-magenta-800: #de3d82;
  --spectrum-magenta-900: #c82269;
  --spectrum-magenta-1000: #ad0955;
  --spectrum-magenta-1100: #8e0045;
  --spectrum-magenta-1200: #700037;
  --spectrum-magenta-1300: #54032a;
  --spectrum-magenta-1400: #3c061d;
  --spectrum-alias-background-color-modal-overlay: #0006;
  --spectrum-alias-dropshadow-color: #00000026;
  --spectrum-alias-background-color-hover-overlay: #0000000a;
  --spectrum-alias-highlight-hover: #0000000f;
  --spectrum-alias-highlight-active: #2c2c2c1a;
  --spectrum-alias-highlight-selected: #0265dc1a;
  --spectrum-alias-highlight-selected-hover: #0265dc33;
  --spectrum-alias-highlight-invalid: #d3151026;
  --spectrum-alias-text-highlight-color: #0265dc33;
  --spectrum-alias-background-color-quickactions: #f8f8f8e6;
  --spectrum-alias-radial-reaction-color-default: #2229;
  --spectrum-alias-pasteboard-background-color: var(--spectrum-global-color-gray-300);
  --spectrum-alias-appframe-border-color: var(--spectrum-global-color-gray-300);
  --spectrum-alias-appframe-separator-color: var(--spectrum-global-color-gray-300);
  --spectrum-colorarea-border-color: #2c2c2c1a;
  --spectrum-colorarea-border-color-hover: #2c2c2c1a;
  --spectrum-colorarea-border-color-down: #2c2c2c1a;
  --spectrum-colorarea-border-color-key-focus: #2c2c2c1a;
  --spectrum-colorslider-border-color: #2c2c2c1a;
  --spectrum-colorslider-border-color-hover: #2c2c2c1a;
  --spectrum-colorslider-border-color-down: #2c2c2c1a;
  --spectrum-colorslider-border-color-key-focus: #2c2c2c1a;
  --spectrum-colorslider-vertical-border-color: #2c2c2c1a;
  --spectrum-colorslider-vertical-border-color-hover: #2c2c2c1a;
  --spectrum-colorslider-vertical-border-color-down: #2c2c2c1a;
  --spectrum-colorslider-vertical-border-color-key-focus: #2c2c2c1a;
  --spectrum-colorwheel-border-color: #2c2c2c1a;
  --spectrum-colorwheel-border-color-hover: #2c2c2c1a;
  --spectrum-colorwheel-border-color-down: #2c2c2c1a;
  --spectrum-colorwheel-border-color-key-focus: #2c2c2c1a;
  --spectrum-miller-column-item-background-color-selected: #1473e61a;
  --spectrum-miller-column-item-background-color-selected-hover: #1473e633;
  --spectrum-tabs-compact-selection-indicator-color: var(--spectrum-global-color-blue-500);
  --spectrum-tabs-compact-vertical-rule-color: var(--spectrum-global-color-gray-200);
  --spectrum-tabs-compact-vertical-emphasized-selection-indicator-color: var(--spectrum-global-color-blue-500);
  --spectrum-tabs-compact-vertical-emphasized-rule-color: var(--spectrum-global-color-gray-200);
  --spectrum-tabs-emphasized-selection-indicator-color: var(--spectrum-global-color-blue-500);
  --spectrum-tabs-quiet-compact-emphasized-selection-indicator-color: var(--spectrum-global-color-blue-500);
  --spectrum-tabs-quiet-compact-vertical-emphasized-selection-indicator-color: var(--spectrum-global-color-blue-500);
  --spectrum-tabs-quiet-emphasized-selection-indicator-color: var(--spectrum-global-color-blue-500);
  --spectrum-tabs-quiet-vertical-emphasized-selection-indicator-color: var(--spectrum-global-color-blue-500);
  --spectrum-well-background-color: #22222205;
  --spectrum-well-border-color: #0000000d;
  --spectrum-tray-background-color: var(--spectrum-global-color-gray-50);
  --react-spectrum-datepicker-placeholder-color: #767676;
  --spectrum-accent-background-color-default: var(--spectrum-accent-color-900);
  --spectrum-accent-background-color-hover: var(--spectrum-accent-color-1000);
  --spectrum-accent-background-color-down: var(--spectrum-accent-color-1100);
  --spectrum-accent-background-color-key-focus: var(--spectrum-accent-color-1000);
  --spectrum-neutral-background-color-default: var(--spectrum-gray-800);
  --spectrum-neutral-background-color-hover: var(--spectrum-gray-900);
  --spectrum-neutral-background-color-down: var(--spectrum-gray-900);
  --spectrum-neutral-background-color-key-focus: var(--spectrum-gray-900);
  --spectrum-neutral-subdued-background-color-default: var(--spectrum-gray-600);
  --spectrum-neutral-subdued-background-color-hover: var(--spectrum-gray-700);
  --spectrum-neutral-subdued-background-color-down: var(--spectrum-gray-800);
  --spectrum-neutral-subdued-background-color-key-focus: var(--spectrum-gray-700);
  --spectrum-negative-background-color-default: var(--spectrum-red-900);
  --spectrum-negative-background-color-hover: var(--spectrum-red-1000);
  --spectrum-negative-background-color-down: var(--spectrum-red-1100);
  --spectrum-negative-background-color-key-focus: var(--spectrum-red-1000);
  --spectrum-positive-background-color-default: var(--spectrum-green-900);
  --spectrum-positive-background-color-hover: var(--spectrum-green-1000);
  --spectrum-positive-background-color-down: var(--spectrum-green-1100);
  --spectrum-positive-background-color-key-focus: var(--spectrum-green-1000);
  --spectrum-informative-background-color-default: var(--spectrum-blue-900);
  --spectrum-informative-background-color-hover: var(--spectrum-blue-1000);
  --spectrum-informative-background-color-down: var(--spectrum-blue-1100);
  --spectrum-informative-background-color-key-focus: var(--spectrum-blue-1000);
  --spectrum-gray-background-color-default: var(--spectrum-gray-700);
  --spectrum-red-background-color-default: var(--spectrum-red-600);
  --spectrum-orange-background-color-default: var(--spectrum-orange-600);
  --spectrum-yellow-background-color-default: var(--spectrum-yellow-400);
  --spectrum-chartreuse-background-color-default: var(--spectrum-chartreuse-500);
  --spectrum-celery-background-color-default: var(--spectrum-celery-600);
  --spectrum-green-background-color-default: var(--spectrum-green-900);
  --spectrum-seafoam-background-color-default: var(--spectrum-seafoam-900);
  --spectrum-cyan-background-color-default: var(--spectrum-cyan-900);
  --spectrum-blue-background-color-default: var(--spectrum-blue-900);
  --spectrum-indigo-background-color-default: var(--spectrum-indigo-900);
  --spectrum-purple-background-color-default: var(--spectrum-purple-900);
  --spectrum-fuchsia-background-color-default: var(--spectrum-fuchsia-900);
  --spectrum-magenta-background-color-default: var(--spectrum-magenta-900);
  --spectrum-negative-visual-color: var(--spectrum-red-800);
  --spectrum-positive-visual-color: var(--spectrum-green-700);
  --spectrum-notice-visual-color: var(--spectrum-orange-700);
  --spectrum-informative-visual-color: var(--spectrum-blue-800);
  --spectrum-gray-visual-color: var(--spectrum-gray-500);
  --spectrum-red-visual-color: var(--spectrum-red-800);
  --spectrum-orange-visual-color: var(--spectrum-orange-700);
  --spectrum-yellow-visual-color: var(--spectrum-yellow-600);
  --spectrum-chartreuse-visual-color: var(--spectrum-chartreuse-600);
  --spectrum-celery-visual-color: var(--spectrum-celery-700);
  --spectrum-green-visual-color: var(--spectrum-green-700);
  --spectrum-seafoam-visual-color: var(--spectrum-seafoam-700);
  --spectrum-cyan-visual-color: var(--spectrum-cyan-600);
  --spectrum-blue-visual-color: var(--spectrum-blue-800);
  --spectrum-indigo-visual-color: var(--spectrum-indigo-800);
  --spectrum-purple-visual-color: var(--spectrum-purple-800);
  --spectrum-fuchsia-visual-color: var(--spectrum-fuchsia-800);
  --spectrum-magenta-visual-color: var(--spectrum-magenta-800);
}

.xSyFOq_spectrum--medium {
  --spectrum-global-dimension-scale-factor: 1;
  --spectrum-global-dimension-size-0: 0px;
  --spectrum-global-dimension-size-10: 1px;
  --spectrum-global-dimension-size-25: 2px;
  --spectrum-global-dimension-size-40: 3px;
  --spectrum-global-dimension-size-50: 4px;
  --spectrum-global-dimension-size-65: 5px;
  --spectrum-global-dimension-size-75: 6px;
  --spectrum-global-dimension-size-85: 7px;
  --spectrum-global-dimension-size-100: 8px;
  --spectrum-global-dimension-size-115: 9px;
  --spectrum-global-dimension-size-125: 10px;
  --spectrum-global-dimension-size-130: 11px;
  --spectrum-global-dimension-size-150: 12px;
  --spectrum-global-dimension-size-160: 13px;
  --spectrum-global-dimension-size-175: 14px;
  --spectrum-global-dimension-size-200: 16px;
  --spectrum-global-dimension-size-225: 18px;
  --spectrum-global-dimension-size-250: 20px;
  --spectrum-global-dimension-size-275: 22px;
  --spectrum-global-dimension-size-300: 24px;
  --spectrum-global-dimension-size-325: 26px;
  --spectrum-global-dimension-size-350: 28px;
  --spectrum-global-dimension-size-400: 32px;
  --spectrum-global-dimension-size-450: 36px;
  --spectrum-global-dimension-size-500: 40px;
  --spectrum-global-dimension-size-550: 44px;
  --spectrum-global-dimension-size-600: 48px;
  --spectrum-global-dimension-size-675: 54px;
  --spectrum-global-dimension-size-700: 56px;
  --spectrum-global-dimension-size-800: 64px;
  --spectrum-global-dimension-size-900: 72px;
  --spectrum-global-dimension-size-1000: 80px;
  --spectrum-global-dimension-size-1200: 96px;
  --spectrum-global-dimension-size-1250: 100px;
  --spectrum-global-dimension-size-1600: 128px;
  --spectrum-global-dimension-size-1700: 136px;
  --spectrum-global-dimension-size-2000: 160px;
  --spectrum-global-dimension-size-2400: 192px;
  --spectrum-global-dimension-size-3000: 240px;
  --spectrum-global-dimension-size-3400: 272px;
  --spectrum-global-dimension-size-3600: 288px;
  --spectrum-global-dimension-size-4600: 368px;
  --spectrum-global-dimension-size-5000: 400px;
  --spectrum-global-dimension-size-6000: 480px;
  --spectrum-global-dimension-font-size-25: 10px;
  --spectrum-global-dimension-font-size-50: 11px;
  --spectrum-global-dimension-font-size-75: 12px;
  --spectrum-global-dimension-font-size-100: 14px;
  --spectrum-global-dimension-font-size-150: 15px;
  --spectrum-global-dimension-font-size-200: 16px;
  --spectrum-global-dimension-font-size-300: 18px;
  --spectrum-global-dimension-font-size-400: 20px;
  --spectrum-global-dimension-font-size-500: 22px;
  --spectrum-global-dimension-font-size-600: 25px;
  --spectrum-global-dimension-font-size-700: 28px;
  --spectrum-global-dimension-font-size-800: 32px;
  --spectrum-global-dimension-font-size-900: 36px;
  --spectrum-global-dimension-font-size-1000: 40px;
  --spectrum-global-dimension-font-size-1100: 45px;
  --spectrum-global-dimension-font-size-1200: 50px;
  --spectrum-global-dimension-font-size-1300: 60px;
  --spectrum-alias-avatar-size-100: var(--spectrum-global-dimension-size-250);
  --spectrum-alias-avatar-size-400: var(--spectrum-global-dimension-size-350);
  --spectrum-alias-avatar-size-600: var(--spectrum-global-dimension-size-450);
  --spectrum-actionbutton-touch-hit-x: var(--spectrum-global-dimension-static-size-100, 8px);
  --spectrum-actionbutton-touch-hit-y: var(--spectrum-global-dimension-static-size-100, 8px);
  --spectrum-actionbutton-emphasized-touch-hit-x: var(--spectrum-global-dimension-static-size-100, 8px);
  --spectrum-actionbutton-emphasized-touch-hit-y: var(--spectrum-global-dimension-static-size-100, 8px);
  --spectrum-actionbutton-quiet-touch-hit-x: var(--spectrum-global-dimension-static-size-100, 8px);
  --spectrum-actionbutton-quiet-touch-hit-y: var(--spectrum-global-dimension-static-size-100, 8px);
  --spectrum-actionbutton-quiet-emphasized-touch-hit-x: var(--spectrum-global-dimension-static-size-100, 8px);
  --spectrum-actionbutton-quiet-emphasized-touch-hit-y: var(--spectrum-global-dimension-static-size-100, 8px);
  --spectrum-barloader-large-border-radius: 3px;
  --spectrum-barloader-large-indeterminate-border-radius: 3px;
  --spectrum-barloader-large-over-background-border-radius: 3px;
  --spectrum-barloader-small-border-radius: var(--spectrum-global-dimension-static-size-25, 2px);
  --spectrum-barloader-small-indeterminate-border-radius: var(--spectrum-global-dimension-static-size-25, 2px);
  --spectrum-barloader-small-over-background-border-radius: var(--spectrum-global-dimension-static-size-25, 2px);
  --spectrum-breadcrumb-compact-item-touch-hit-y: var(--spectrum-global-dimension-static-size-100, 8px);
  --spectrum-breadcrumb-compact-button-touch-hit-y: var(--spectrum-global-dimension-static-size-100, 8px);
  --spectrum-breadcrumb-item-touch-hit-y: var(--spectrum-global-dimension-static-size-100, 8px);
  --spectrum-breadcrumb-button-touch-hit-y: var(--spectrum-global-dimension-static-size-100, 8px);
  --spectrum-breadcrumb-multiline-item-touch-hit-y: var(--spectrum-global-dimension-static-size-100, 8px);
  --spectrum-breadcrumb-multiline-button-touch-hit-y: var(--spectrum-global-dimension-static-size-100, 8px);
  --spectrum-button-cta-text-padding-bottom: var(--spectrum-global-dimension-size-85);
  --spectrum-button-cta-min-width: var(--spectrum-global-dimension-size-900);
  --spectrum-button-cta-touch-hit-x: var(--spectrum-global-dimension-static-size-100, 8px);
  --spectrum-button-cta-touch-hit-y: var(--spectrum-global-dimension-static-size-100, 8px);
  --spectrum-button-over-background-text-padding-bottom: var(--spectrum-global-dimension-size-85);
  --spectrum-button-over-background-min-width: var(--spectrum-global-dimension-size-900);
  --spectrum-button-over-background-touch-hit-x: var(--spectrum-global-dimension-static-size-100, 8px);
  --spectrum-button-over-background-touch-hit-y: var(--spectrum-global-dimension-static-size-100, 8px);
  --spectrum-button-primary-text-padding-bottom: var(--spectrum-global-dimension-size-85);
  --spectrum-button-primary-min-width: var(--spectrum-global-dimension-size-900);
  --spectrum-button-primary-touch-hit-x: var(--spectrum-global-dimension-static-size-100, 8px);
  --spectrum-button-primary-touch-hit-y: var(--spectrum-global-dimension-static-size-100, 8px);
  --spectrum-button-quiet-over-background-text-padding-bottom: var(--spectrum-global-dimension-size-85);
  --spectrum-button-quiet-over-background-min-width: var(--spectrum-global-dimension-size-900);
  --spectrum-button-quiet-over-background-touch-hit-x: var(--spectrum-global-dimension-static-size-200, 16px);
  --spectrum-button-quiet-over-background-touch-hit-y: var(--spectrum-global-dimension-static-size-100, 8px);
  --spectrum-button-quiet-over-background-cursor-hit-x: var(--spectrum-global-dimension-static-size-200, 16px);
  --spectrum-button-quiet-primary-text-padding-bottom: var(--spectrum-global-dimension-size-85);
  --spectrum-button-quiet-primary-min-width: var(--spectrum-global-dimension-size-900);
  --spectrum-button-quiet-primary-touch-hit-x: var(--spectrum-global-dimension-static-size-200, 16px);
  --spectrum-button-quiet-primary-touch-hit-y: var(--spectrum-global-dimension-static-size-100, 8px);
  --spectrum-button-quiet-primary-cursor-hit-x: var(--spectrum-global-dimension-static-size-200, 16px);
  --spectrum-button-quiet-secondary-text-padding-bottom: var(--spectrum-global-dimension-size-85);
  --spectrum-button-quiet-secondary-min-width: var(--spectrum-global-dimension-size-900);
  --spectrum-button-quiet-secondary-touch-hit-x: var(--spectrum-global-dimension-static-size-200, 16px);
  --spectrum-button-quiet-secondary-touch-hit-y: var(--spectrum-global-dimension-static-size-100, 8px);
  --spectrum-button-quiet-secondary-cursor-hit-x: var(--spectrum-global-dimension-static-size-200, 16px);
  --spectrum-button-quiet-warning-text-padding-bottom: var(--spectrum-global-dimension-size-85);
  --spectrum-button-quiet-warning-min-width: var(--spectrum-global-dimension-size-900);
  --spectrum-button-quiet-warning-touch-hit-x: var(--spectrum-global-dimension-static-size-200, 16px);
  --spectrum-button-quiet-warning-touch-hit-y: var(--spectrum-global-dimension-static-size-100, 8px);
  --spectrum-button-quiet-warning-cursor-hit-x: var(--spectrum-global-dimension-static-size-200, 16px);
  --spectrum-button-secondary-text-padding-bottom: var(--spectrum-global-dimension-size-85);
  --spectrum-button-secondary-min-width: var(--spectrum-global-dimension-size-900);
  --spectrum-button-secondary-touch-hit-x: var(--spectrum-global-dimension-static-size-100, 8px);
  --spectrum-button-secondary-touch-hit-y: var(--spectrum-global-dimension-static-size-100, 8px);
  --spectrum-button-warning-text-padding-bottom: var(--spectrum-global-dimension-size-85);
  --spectrum-button-warning-min-width: var(--spectrum-global-dimension-size-900);
  --spectrum-button-warning-touch-hit-x: var(--spectrum-global-dimension-static-size-100, 8px);
  --spectrum-button-warning-touch-hit-y: var(--spectrum-global-dimension-static-size-100, 8px);
  --spectrum-checkbox-text-gap-key-focus: var(--spectrum-global-dimension-static-size-115, 9px);
  --spectrum-checkbox-text-gap-selected-key-focus: var(--spectrum-global-dimension-static-size-115, 9px);
  --spectrum-checkbox-text-gap-indeterminate-key-focus: var(--spectrum-global-dimension-static-size-115, 9px);
  --spectrum-checkbox-text-gap-error-key-focus: var(--spectrum-global-dimension-static-size-115, 9px);
  --spectrum-checkbox-text-gap-error-selected-key-focus: var(--spectrum-global-dimension-static-size-115, 9px);
  --spectrum-checkbox-text-gap-error-indeterminate-key-focus: var(--spectrum-global-dimension-static-size-115, 9px);
  --spectrum-checkbox-touch-hit-y: var(--spectrum-global-dimension-static-size-100, 8px);
  --spectrum-checkbox-emphasized-text-gap-key-focus: var(--spectrum-global-dimension-static-size-115, 9px);
  --spectrum-checkbox-emphasized-text-gap-selected-key-focus: var(--spectrum-global-dimension-static-size-115, 9px);
  --spectrum-checkbox-emphasized-text-gap-indeterminate-key-focus: var(--spectrum-global-dimension-static-size-115, 9px);
  --spectrum-checkbox-emphasized-text-gap-error-key-focus: var(--spectrum-global-dimension-static-size-115, 9px);
  --spectrum-checkbox-emphasized-text-gap-error-selected-key-focus: var(--spectrum-global-dimension-static-size-115, 9px);
  --spectrum-checkbox-emphasized-text-gap-error-indeterminate-key-focus: var(--spectrum-global-dimension-static-size-115, 9px);
  --spectrum-checkbox-emphasized-touch-hit-y: var(--spectrum-global-dimension-static-size-100, 8px);
  --spectrum-checkbox-quiet-text-gap-key-focus: var(--spectrum-global-dimension-static-size-115, 9px);
  --spectrum-checkbox-quiet-text-gap-selected-key-focus: var(--spectrum-global-dimension-static-size-115, 9px);
  --spectrum-checkbox-quiet-text-gap-indeterminate-key-focus: var(--spectrum-global-dimension-static-size-115, 9px);
  --spectrum-checkbox-quiet-text-gap-error-key-focus: var(--spectrum-global-dimension-static-size-115, 9px);
  --spectrum-checkbox-quiet-text-gap-error-selected-key-focus: var(--spectrum-global-dimension-static-size-115, 9px);
  --spectrum-checkbox-quiet-text-gap-error-indeterminate-key-focus: var(--spectrum-global-dimension-static-size-115, 9px);
  --spectrum-checkbox-quiet-touch-hit-y: var(--spectrum-global-dimension-static-size-100, 8px);
  --spectrum-circleloader-medium-border-size: 3px;
  --spectrum-circleloader-medium-over-background-border-size: 3px;
  --spectrum-circleloader-small-border-size: var(--spectrum-global-dimension-static-size-25, 2px);
  --spectrum-circleloader-small-over-background-border-size: var(--spectrum-global-dimension-static-size-25, 2px);
  --spectrum-colorhandle-loupe-margin: var(--spectrum-global-dimension-static-size-125, 10px);
  --spectrum-colorloupe-colorhandle-gap: var(--spectrum-global-dimension-static-size-125, 10px);
  --spectrum-colorslider-touch-hit-y: var(--spectrum-global-dimension-size-150);
  --spectrum-colorslider-vertical-touch-hit-x: var(--spectrum-global-dimension-size-150);
  --spectrum-colorwheel-min-size: var(--spectrum-global-dimension-size-2400);
  --spectrum-colorwheel-touch-hit-outer: var(--spectrum-global-dimension-size-150);
  --spectrum-colorwheel-touch-hit-inner: var(--spectrum-global-dimension-size-150);
  --spectrum-cyclebutton-touch-hit-x: var(--spectrum-global-dimension-static-size-100, 8px);
  --spectrum-cyclebutton-touch-hit-y: var(--spectrum-global-dimension-static-size-100, 8px);
  --spectrum-dialog-confirm-max-width: var(--spectrum-global-dimension-static-size-6000, 480px);
  --spectrum-dialog-confirm-title-text-size: var(--spectrum-global-dimension-font-size-300);
  --spectrum-dialog-confirm-description-text-size: var(--spectrum-global-dimension-font-size-100);
  --spectrum-dialog-confirm-padding: var(--spectrum-global-dimension-static-size-500, 40px);
  --spectrum-dialog-confirm-description-margin-bottom: var(--spectrum-global-dimension-static-size-600, 48px);
  --spectrum-dialog-max-width: var(--spectrum-global-dimension-static-size-6000, 480px);
  --spectrum-dialog-title-text-size: var(--spectrum-global-dimension-font-size-300);
  --spectrum-dialog-content-text-size: var(--spectrum-global-dimension-font-size-100);
  --spectrum-dialog-padding: var(--spectrum-global-dimension-static-size-500, 40px);
  --spectrum-dialog-content-margin-bottom: var(--spectrum-global-dimension-static-size-600, 48px);
  --spectrum-dialog-destructive-max-width: var(--spectrum-global-dimension-static-size-6000, 480px);
  --spectrum-dialog-destructive-title-text-size: var(--spectrum-global-dimension-font-size-300);
  --spectrum-dialog-destructive-description-text-size: var(--spectrum-global-dimension-font-size-100);
  --spectrum-dialog-destructive-padding: var(--spectrum-global-dimension-static-size-500, 40px);
  --spectrum-dialog-destructive-description-margin-bottom: var(--spectrum-global-dimension-static-size-600, 48px);
  --spectrum-dialog-error-max-width: var(--spectrum-global-dimension-static-size-6000, 480px);
  --spectrum-dialog-error-title-text-size: var(--spectrum-global-dimension-font-size-300);
  --spectrum-dialog-error-description-text-size: var(--spectrum-global-dimension-font-size-100);
  --spectrum-dialog-error-padding: var(--spectrum-global-dimension-static-size-500, 40px);
  --spectrum-dialog-error-description-margin-bottom: var(--spectrum-global-dimension-static-size-600, 48px);
  --spectrum-dialog-info-max-width: var(--spectrum-global-dimension-static-size-6000, 480px);
  --spectrum-dialog-info-title-text-size: var(--spectrum-global-dimension-font-size-300);
  --spectrum-dialog-info-description-text-size: var(--spectrum-global-dimension-font-size-100);
  --spectrum-dialog-info-padding: var(--spectrum-global-dimension-static-size-500, 40px);
  --spectrum-dialog-info-description-margin-bottom: var(--spectrum-global-dimension-static-size-600, 48px);
  --spectrum-dropdown-touch-hit-x: var(--spectrum-global-dimension-static-size-100, 8px);
  --spectrum-dropdown-touch-hit-y: var(--spectrum-global-dimension-static-size-100, 8px);
  --spectrum-dropdown-quiet-touch-hit-y: var(--spectrum-global-dimension-static-size-400, 32px);
  --spectrum-dropdown-thumbnail-small-touch-hit-x: var(--spectrum-global-dimension-static-size-100, 8px);
  --spectrum-dropdown-thumbnail-small-touch-hit-y: var(--spectrum-global-dimension-static-size-100, 8px);
  --spectrum-fieldbutton-quiet-min-width: var(--spectrum-global-dimension-size-225);
  --spectrum-icon-arrow-down-small-height: var(--spectrum-global-dimension-size-125);
  --spectrum-icon-arrow-left-medium-height: var(--spectrum-global-dimension-size-125);
  --spectrum-icon-checkmark-medium-width: var(--spectrum-global-dimension-size-150);
  --spectrum-icon-checkmark-medium-height: var(--spectrum-global-dimension-size-150);
  --spectrum-icon-checkmark-small-width: var(--spectrum-global-dimension-size-125);
  --spectrum-icon-checkmark-small-height: var(--spectrum-global-dimension-size-125);
  --spectrum-icon-chevron-down-medium-width: var(--spectrum-global-dimension-size-125);
  --spectrum-icon-chevron-left-large-width: var(--spectrum-global-dimension-size-150);
  --spectrum-icon-chevron-left-medium-height: var(--spectrum-global-dimension-size-125);
  --spectrum-icon-chevron-right-large-width: var(--spectrum-global-dimension-size-150);
  --spectrum-icon-chevron-right-medium-height: var(--spectrum-global-dimension-size-125);
  --spectrum-icon-cross-large-width: var(--spectrum-global-dimension-size-150);
  --spectrum-icon-cross-large-height: var(--spectrum-global-dimension-size-150);
  --spectrum-icon-dash-small-width: var(--spectrum-global-dimension-size-125);
  --spectrum-icon-dash-small-height: var(--spectrum-global-dimension-size-125);
  --spectrum-icon-skip-left-width: 9px;
  --spectrum-icon-skip-left-height: var(--spectrum-global-dimension-size-125);
  --spectrum-icon-skip-right-width: 9px;
  --spectrum-icon-skip-right-height: var(--spectrum-global-dimension-size-125);
  --spectrum-icon-triplegripper-width: var(--spectrum-global-dimension-size-125);
  --spectrum-listitem-option-icon-size: var(--spectrum-global-dimension-static-size-150, 12px);
  --spectrum-listitem-option-icon-margin-top: var(--spectrum-global-dimension-static-size-65, 5px);
  --spectrum-listitem-option-height: var(--spectrum-global-dimension-static-size-400, 32px);
  --spectrum-listitem-option-icon-padding-y: var(--spectrum-global-dimension-static-size-125, 10px);
  --spectrum-listitem-thumbnail-option-icon-margin-top: var(--spectrum-global-dimension-static-size-65, 5px);
  --spectrum-listitem-thumbnail-option-icon-padding-y: var(--spectrum-global-dimension-static-size-125, 10px);
  --spectrum-selectlist-thumbnail-small-option-icon-margin-top: var(--spectrum-global-dimension-static-size-65, 5px);
  --spectrum-selectlist-thumbnail-small-option-icon-padding-y: var(--spectrum-global-dimension-static-size-125, 10px);
  --spectrum-selectlist-option-icon-size: var(--spectrum-global-dimension-static-size-150, 12px);
  --spectrum-selectlist-option-icon-padding-y: var(--spectrum-global-dimension-static-size-125, 10px);
  --spectrum-selectlist-option-icon-margin-top: var(--spectrum-global-dimension-static-size-65, 5px);
  --spectrum-selectlist-option-height: var(--spectrum-global-dimension-static-size-400, 32px);
  --spectrum-selectlist-option-padding-height: 7px;
  --spectrum-selectlist-thumbnail-option-icon-padding-y: var(--spectrum-global-dimension-static-size-125, 10px);
  --spectrum-loader-bar-large-border-radius: 3px;
  --spectrum-loader-bar-large-over-background-border-radius: 3px;
  --spectrum-loader-bar-small-border-radius: var(--spectrum-global-dimension-static-size-25, 2px);
  --spectrum-loader-bar-small-over-background-border-radius: var(--spectrum-global-dimension-static-size-25, 2px);
  --spectrum-loader-circle-medium-border-size: 3px;
  --spectrum-loader-circle-medium-over-background-border-size: 3px;
  --spectrum-loader-circle-small-border-size: var(--spectrum-global-dimension-static-size-25, 2px);
  --spectrum-loader-circle-small-over-background-border-size: var(--spectrum-global-dimension-static-size-25, 2px);
  --spectrum-meter-large-border-radius: 3px;
  --spectrum-meter-small-border-radius: var(--spectrum-global-dimension-static-size-25, 2px);
  --spectrum-pagination-page-button-line-height: 26px;
  --spectrum-overlay-positive-transform-distance: 6px;
  --spectrum-overlay-negative-transform-distance: -6px;
  --spectrum-picker-touch-hit-x: var(--spectrum-global-dimension-static-size-100, 8px);
  --spectrum-picker-touch-hit-y: var(--spectrum-global-dimension-static-size-100, 8px);
  --spectrum-picker-quiet-min-width: var(--spectrum-global-dimension-size-225);
  --spectrum-picker-quiet-touch-hit-y: var(--spectrum-global-dimension-static-size-400, 32px);
  --spectrum-picker-thumbnail-small-touch-hit-x: var(--spectrum-global-dimension-static-size-100, 8px);
  --spectrum-picker-thumbnail-small-touch-hit-y: var(--spectrum-global-dimension-static-size-100, 8px);
  --spectrum-progressbar-large-border-radius: 3px;
  --spectrum-progressbar-large-indeterminate-border-radius: 3px;
  --spectrum-progressbar-large-over-background-border-radius: 3px;
  --spectrum-progressbar-small-border-radius: var(--spectrum-global-dimension-static-size-25, 2px);
  --spectrum-progressbar-small-indeterminate-border-radius: var(--spectrum-global-dimension-static-size-25, 2px);
  --spectrum-progressbar-small-over-background-border-radius: var(--spectrum-global-dimension-static-size-25, 2px);
  --spectrum-progresscircle-medium-border-size: 3px;
  --spectrum-progresscircle-medium-over-background-border-size: 3px;
  --spectrum-progresscircle-small-border-size: var(--spectrum-global-dimension-static-size-25, 2px);
  --spectrum-progresscircle-small-indeterminate-border-size: var(--spectrum-global-dimension-static-size-25, 2px);
  --spectrum-progresscircle-small-over-background-border-size: var(--spectrum-global-dimension-static-size-25, 2px);
  --spectrum-progresscircle-medium-indeterminate-border-size: 3px;
  --spectrum-radio-text-gap-key-focus: var(--spectrum-global-dimension-static-size-115, 9px);
  --spectrum-radio-text-gap-selected-key-focus: var(--spectrum-global-dimension-static-size-115, 9px);
  --spectrum-radio-text-gap-error-key-focus: var(--spectrum-global-dimension-static-size-115, 9px);
  --spectrum-radio-text-gap-error-selected-key-focus: var(--spectrum-global-dimension-static-size-115, 9px);
  --spectrum-radio-margin-bottom: 0px;
  --spectrum-radio-touch-hit-y: var(--spectrum-global-dimension-static-size-100, 8px);
  --spectrum-radio-emphasized-text-gap-key-focus: var(--spectrum-global-dimension-static-size-115, 9px);
  --spectrum-radio-emphasized-text-gap-selected-key-focus: var(--spectrum-global-dimension-static-size-115, 9px);
  --spectrum-radio-emphasized-text-gap-error-key-focus: var(--spectrum-global-dimension-static-size-115, 9px);
  --spectrum-radio-emphasized-text-gap-error-selected-key-focus: var(--spectrum-global-dimension-static-size-115, 9px);
  --spectrum-radio-emphasized-margin-bottom: 0px;
  --spectrum-radio-emphasized-touch-hit-y: var(--spectrum-global-dimension-static-size-100, 8px);
  --spectrum-radio-quiet-text-gap-key-focus: var(--spectrum-global-dimension-static-size-115, 9px);
  --spectrum-radio-quiet-text-gap-selected-key-focus: var(--spectrum-global-dimension-static-size-115, 9px);
  --spectrum-radio-quiet-text-gap-error-key-focus: var(--spectrum-global-dimension-static-size-115, 9px);
  --spectrum-radio-quiet-text-gap-error-selected-key-focus: var(--spectrum-global-dimension-static-size-115, 9px);
  --spectrum-radio-quiet-margin-bottom: 0px;
  --spectrum-radio-quiet-touch-hit-y: var(--spectrum-global-dimension-static-size-100, 8px);
  --spectrum-rating-icon-width: 24px;
  --spectrum-rating-indicator-width: 16px;
  --spectrum-rating-touch-hit-y: var(--spectrum-global-dimension-static-size-100, 8px);
  --spectrum-rating-emphasized-icon-width: 24px;
  --spectrum-rating-emphasized-indicator-width: 16px;
  --spectrum-rating-emphasized-touch-hit-y: var(--spectrum-global-dimension-static-size-100, 8px);
  --spectrum-rating-quiet-icon-width: 24px;
  --spectrum-rating-quiet-indicator-width: 16px;
  --spectrum-rating-quiet-touch-hit-y: var(--spectrum-global-dimension-static-size-100, 8px);
  --spectrum-search-touch-hit-x: var(--spectrum-global-dimension-static-size-100, 8px);
  --spectrum-search-touch-hit-y: var(--spectrum-global-dimension-static-size-100, 8px);
  --spectrum-search-icon-frame: var(--spectrum-global-dimension-static-size-400, 32px);
  --spectrum-search-quiet-touch-hit-x: var(--spectrum-global-dimension-static-size-100, 8px);
  --spectrum-search-quiet-touch-hit-y: var(--spectrum-global-dimension-static-size-100, 8px);
  --spectrum-search-quiet-icon-frame: var(--spectrum-global-dimension-static-size-400, 32px);
  --spectrum-sidenav-item-touch-hit-bottom: var(--spectrum-global-dimension-static-size-25, 2px);
  --spectrum-sidenav-multilevel-item-touch-hit-bottom: var(--spectrum-global-dimension-static-size-25, 2px);
  --spectrum-slider-track-touch-hit-y: var(--spectrum-global-dimension-static-size-100, 8px);
  --spectrum-slider-handle-touch-hit-x: var(--spectrum-global-dimension-static-size-200, 16px);
  --spectrum-slider-handle-touch-hit-y: var(--spectrum-global-dimension-static-size-200, 16px);
  --spectrum-slider-editable-track-touch-hit-y: var(--spectrum-global-dimension-static-size-100, 8px);
  --spectrum-slider-editable-handle-touch-hit-x: var(--spectrum-global-dimension-static-size-200, 16px);
  --spectrum-slider-editable-handle-touch-hit-y: var(--spectrum-global-dimension-static-size-200, 16px);
  --spectrum-slider-fill-track-touch-hit-y: var(--spectrum-global-dimension-static-size-100, 8px);
  --spectrum-slider-fill-handle-touch-hit-x: var(--spectrum-global-dimension-static-size-200, 16px);
  --spectrum-slider-fill-handle-touch-hit-y: var(--spectrum-global-dimension-static-size-200, 16px);
  --spectrum-switch-focus-ring-border-radius-key-focus: var(--spectrum-global-dimension-static-size-130, 11px);
  --spectrum-switch-text-gap-key-focus: var(--spectrum-global-dimension-static-size-115, 9px);
  --spectrum-switch-text-gap-selected-key-focus: var(--spectrum-global-dimension-static-size-115, 9px);
  --spectrum-switch-text-gap-error-key-focus: var(--spectrum-global-dimension-static-size-115, 9px);
  --spectrum-switch-text-gap-error-selected-key-focus: var(--spectrum-global-dimension-static-size-115, 9px);
  --spectrum-switch-track-width: 26px;
  --spectrum-switch-handle-border-radius: 7px;
  --spectrum-switch-handle-transform-x-ltr: 12px;
  --spectrum-switch-handle-transform-x-rtl: -12px;
  --spectrum-switch-touch-hit-y: var(--spectrum-global-dimension-static-size-100, 8px);
  --spectrum-switch-emphasized-focus-ring-border-radius-key-focus: var(--spectrum-global-dimension-static-size-130, 11px);
  --spectrum-switch-emphasized-text-gap-key-focus: var(--spectrum-global-dimension-static-size-115, 9px);
  --spectrum-switch-emphasized-text-gap-selected-key-focus: var(--spectrum-global-dimension-static-size-115, 9px);
  --spectrum-switch-emphasized-text-gap-error-key-focus: var(--spectrum-global-dimension-static-size-115, 9px);
  --spectrum-switch-emphasized-text-gap-error-selected-key-focus: var(--spectrum-global-dimension-static-size-115, 9px);
  --spectrum-switch-emphasized-track-width: 26px;
  --spectrum-switch-emphasized-handle-border-radius: 7px;
  --spectrum-switch-emphasized-touch-hit-y: var(--spectrum-global-dimension-static-size-100, 8px);
  --spectrum-switch-quiet-focus-ring-border-radius-key-focus: var(--spectrum-global-dimension-static-size-130, 11px);
  --spectrum-switch-quiet-text-gap-key-focus: var(--spectrum-global-dimension-static-size-115, 9px);
  --spectrum-switch-quiet-text-gap-selected-key-focus: var(--spectrum-global-dimension-static-size-115, 9px);
  --spectrum-switch-quiet-text-gap-error-key-focus: var(--spectrum-global-dimension-static-size-115, 9px);
  --spectrum-switch-quiet-text-gap-error-selected-key-focus: var(--spectrum-global-dimension-static-size-115, 9px);
  --spectrum-switch-quiet-track-width: 26px;
  --spectrum-switch-quiet-handle-border-radius: 7px;
  --spectrum-switch-quiet-touch-hit-y: var(--spectrum-global-dimension-static-size-100, 8px);
  --spectrum-tabs-compact-focus-ring-border-radius: 5px;
  --spectrum-tabs-compact-margin-left: -8px;
  --spectrum-tabs-compact-margin-right: -8px;
  --spectrum-tabs-compact-vertical-focus-ring-border-radius: 5px;
  --spectrum-tabs-compact-vertical-emphasized-focus-ring-border-radius: 5px;
  --spectrum-tabs-baseline: var(--spectrum-global-dimension-size-225);
  --spectrum-tabs-focus-ring-border-radius: 5px;
  --spectrum-tabs-margin-left: -8px;
  --spectrum-tabs-margin-right: -8px;
  --spectrum-tabs-emphasized-baseline: var(--spectrum-global-dimension-size-225);
  --spectrum-tabs-emphasized-focus-ring-border-radius: 5px;
  --spectrum-tabs-emphasized-margin-left: -8px;
  --spectrum-tabs-emphasized-margin-right: -8px;
  --spectrum-tabs-quiet-baseline: var(--spectrum-global-dimension-size-225);
  --spectrum-tabs-quiet-focus-ring-border-radius: 5px;
  --spectrum-tabs-quiet-margin-left: -8px;
  --spectrum-tabs-quiet-margin-right: -8px;
  --spectrum-tabs-quiet-compact-focus-ring-border-radius: 5px;
  --spectrum-tabs-quiet-compact-margin-left: -8px;
  --spectrum-tabs-quiet-compact-margin-right: -8px;
  --spectrum-tabs-quiet-compact-emphasized-focus-ring-border-radius: 5px;
  --spectrum-tabs-quiet-compact-emphasized-margin-left: -8px;
  --spectrum-tabs-quiet-compact-emphasized-margin-right: -8px;
  --spectrum-tabs-quiet-compact-vertical-focus-ring-border-radius: 5px;
  --spectrum-tabs-quiet-compact-vertical-emphasized-focus-ring-border-radius: 5px;
  --spectrum-tabs-quiet-emphasized-baseline: var(--spectrum-global-dimension-size-225);
  --spectrum-tabs-quiet-emphasized-focus-ring-border-radius: 5px;
  --spectrum-tabs-quiet-emphasized-margin-left: -8px;
  --spectrum-tabs-quiet-emphasized-margin-right: -8px;
  --spectrum-tabs-quiet-vertical-baseline: var(--spectrum-global-dimension-size-225);
  --spectrum-tabs-quiet-vertical-focus-ring-border-radius: 5px;
  --spectrum-tabs-quiet-vertical-emphasized-baseline: var(--spectrum-global-dimension-size-225);
  --spectrum-tabs-quiet-vertical-emphasized-focus-ring-border-radius: 5px;
  --spectrum-tabs-vertical-baseline: var(--spectrum-global-dimension-size-225);
  --spectrum-tabs-vertical-focus-ring-border-radius: 5px;
  --spectrum-textarea-touch-hit-x: var(--spectrum-global-dimension-static-size-100, 8px);
  --spectrum-textarea-touch-hit-y: var(--spectrum-global-dimension-static-size-100, 8px);
  --spectrum-textarea-icon-frame: var(--spectrum-global-dimension-static-size-400, 32px);
  --spectrum-textarea-quiet-touch-hit-x: var(--spectrum-global-dimension-static-size-100, 8px);
  --spectrum-textarea-quiet-touch-hit-y: var(--spectrum-global-dimension-static-size-100, 8px);
  --spectrum-textarea-quiet-icon-frame: var(--spectrum-global-dimension-static-size-400, 32px);
  --spectrum-textfield-touch-hit-x: var(--spectrum-global-dimension-static-size-100, 8px);
  --spectrum-textfield-touch-hit-y: var(--spectrum-global-dimension-static-size-100, 8px);
  --spectrum-textfield-icon-frame: var(--spectrum-global-dimension-static-size-400, 32px);
  --spectrum-textfield-quiet-touch-hit-x: var(--spectrum-global-dimension-static-size-100, 8px);
  --spectrum-textfield-quiet-touch-hit-y: var(--spectrum-global-dimension-static-size-100, 8px);
  --spectrum-textfield-quiet-icon-frame: var(--spectrum-global-dimension-static-size-400, 32px);
  --spectrum-tool-touch-hit-x: var(--spectrum-global-dimension-static-size-100, 8px);
  --spectrum-tool-touch-hit-y: var(--spectrum-global-dimension-static-size-100, 8px);
  --spectrum-tool-high-emphasis-touch-hit-x: var(--spectrum-global-dimension-static-size-100, 8px);
  --spectrum-tool-high-emphasis-touch-hit-y: var(--spectrum-global-dimension-static-size-100, 8px);
  --spectrum-tooltip-padding-bottom: 5px;
  --spectrum-tooltip-content-max-width: var(--spectrum-global-dimension-size-2000);
  --spectrum-tooltip-info-padding-bottom: 5px;
  --spectrum-tooltip-info-content-max-width: 101px;
  --spectrum-tooltip-negative-padding-bottom: 5px;
  --spectrum-tooltip-negative-content-max-width: 101px;
  --spectrum-tooltip-positive-padding-bottom: 5px;
  --spectrum-tooltip-positive-content-max-width: 101px;
}

/*# sourceMappingURL=index.edd7023f.css.map */
