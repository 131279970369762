html,
body {
  margin : 0rem;
  padding: 0rem;
  width  : 100%;
  height : 100%;
}

#app {
  width : 100%;
  height: 100%;
}
